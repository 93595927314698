import { gql } from '@apollo/client';
import { DacClient } from './index';

export interface SearchDac {
    id: string;
    membersCount: number;
    createBlock: number;
    createTimestamp: number;
    name: string;
    introduction: string;
    photo: string;
}

export default function searchDac({
    name = '',
}: {
    name?: string;
}): Promise<SearchDac[]> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        DacClient.query<{
            searchDACs: SearchDac[];
        }>({
            variables: {
                first: 1000,
                name,
            },
            query: gql`
                query searchDACs($first: Int!, $name: String!) {
                    searchDACs(first: $first, name: $name) {
                        id
                        membersCount
                        createBlock
                        createTimestamp
                        name
                        introduction
                        photo
                    }
                }
            `,
        })
            .then((res) => {
                const { data } = res;
                resolve(data.searchDACs);
            })
            .catch((e: any) => {
                console.error(e);
                resolve([]);
            });
    });
}
