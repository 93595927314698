import React, { useCallback, useState, useEffect } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { Formik, Form, Field } from 'formik'; // FormikHelpers Field,
import { TextField } from 'formik-material-ui'; // SimpleFileUpload
import { useAppSelector } from '@/state/hooks';

import Modal from '@/components/Modal';
import Row from '@/components/Row';
// import Text, { Title } from '@/components/Text';
import Button from '@/components/Button';
import UploadImage from '@/components/UploadImage';
import UploadScreenshots from '@/components/UploadScreenshots';
import Loading from '@/components/Loading';

import { PHOTO_TIPS, REPORT_BUG_SUCCESS_MSG } from '@/constants/tips';
// import { REACT_APP_EMAIL } from '@/constants/index';

import style from './index.module.scss';
import useUserAvatar from '@/state/user/use-user-avatar';
import UseReportBug from '@/utils/use-report-bug';
import AlertFunc from '@/utils/components/alert';
import { POLIS_HOST } from '@/constants';

export interface IProps {
    isOpen: boolean;
    onClose(): void;
}

interface IReportBugFormValue {
    subject: string;
    reproduce: string;
    screenshots: string;
}

export default function SettingsModal({ isOpen, onClose }: IProps) {
    const user = useAppSelector((state) => state.user);
    const { setEmail } = UseReportBug();
    const { updateUserAvatar } = useUserAvatar();
    const metisMiddlewareClient = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );
    const { client: { accessToken } = {} } = metisMiddlewareClient;
    const [isUserByMM, setIsUserByMM] = useState(false);
    const [isReportBug, setIsReportBug] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isUploadScreenshots, setUploadScreenshots] = useState(false);
    const [reportBugFormValue, setReportBugFormValue] = useState<
        IReportBugFormValue
    >({
        subject: '',
        reproduce: '',
        screenshots: '',
    });

    const closeSettingsModal = useCallback(() => {
        onClose();
        setIsReportBug(false);
    }, [onClose]);

    const goPolis = () => {
        window.open(POLIS_HOST as string, '_blank');
    };

    const openReportBugModal = () => {
        setIsReportBug(true);
    };

    const closeReportBugModal = () => {
        setIsReportBug(false);
        handleInputChange('subject', '');
        handleInputChange('reproduce', '');
        handleInputChange('screenshots', '');
    };

    const uploadPhoto = (url: string) => {
        updateUserAvatar(url);
    };

    const uploadScreenshotFile = (file: any) => {
        handleInputChange('screenshots', file);
        setUploadScreenshots(false);
    };

    const submitReportBug = async () => {
        setLoading(true);
        const res = await setEmail({
            ...reportBugFormValue,
        });
        setLoading(false);

        if (res) {
            AlertFunc({
                type: 'success',
                text: REPORT_BUG_SUCCESS_MSG,
            });
            onClose();
            setIsReportBug(false);
            handleInputChange('subject', '');
            handleInputChange('reproduce', '');
            handleInputChange('screenshots', '');
        }
    };

    const handleInputChange = (label: string, value: string) => {
        setReportBugFormValue((prevProps) => ({
            ...prevProps,
            [label]: value,
        }));
    };

    useEffect(() => {
        let userName = user ? user.name : undefined;
        userName &&
            userName.length >= 20 &&
            userName.substr(0, 2) === '0x' &&
            setIsUserByMM(true);
    }, [user]);

    return (
        <Modal isOpen={isOpen} onClose={closeSettingsModal}>
            {!isReportBug && (
                <div className={style.wrapper}>
                    <Formik
                        initialValues={{
                            photo: '',
                            name: '',
                            email: '',
                        }}
                        validate={(values) => {
                            const errors: Partial<any> = {};

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            // setCategory([]);
                        }}
                    >
                        {({ submitForm }) => (
                            <Form className={style.profileForm}>
                                <FormControl
                                    fullWidth
                                    className={style.photoBar}
                                >
                                    <UploadImage
                                        className={style.photo}
                                        onChange={uploadPhoto}
                                        accessToken={accessToken}
                                        file={user.avatar}
                                    />
                                    <div className={style.photoTips}>
                                        Upload Photo
                                        <Tooltip
                                            title={PHOTO_TIPS}
                                            arrow
                                            placement="bottom"
                                            className={style.photoHelper}
                                        >
                                            <span aria-label="help">
                                                <img
                                                    src="/static/images/ic_question.png"
                                                    alt=""
                                                />
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <FormHelperText
                                        className={style.profileInfo}
                                    >
                                        Your personal profile information is
                                        pulled directly from Polis. Please enter
                                        Polis to view detail information
                                    </FormHelperText>
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    className={style.formControl}
                                >
                                    <InputLabel
                                        className={
                                            isUserByMM ? style.mmInputLable : ''
                                        }
                                        htmlFor="subject"
                                        shrink
                                    >
                                        Display Name
                                    </InputLabel>
                                    <Field
                                        component={TextField}
                                        disabled
                                        name="subject"
                                        placeholder={
                                            isUserByMM ? '' : 'Add text'
                                        }
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={isUserByMM ? '' : user.name}
                                    />
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    className={style.formControl}
                                >
                                    <InputLabel
                                        className={
                                            isUserByMM ? style.mmInputLable : ''
                                        }
                                        htmlFor="subject"
                                        shrink
                                    >
                                        Email
                                    </InputLabel>
                                    <Field
                                        component={TextField}
                                        disabled
                                        name="email"
                                        placeholder={
                                            isUserByMM ? '' : 'Add email'
                                        }
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        key={isUserByMM ? '' : user.email}
                                        value={isUserByMM ? '' : user.email}
                                    />
                                </FormControl>
                                <Row
                                    align="center"
                                    customClass={style.modalActionBar}
                                >
                                    <Button
                                        onClick={() => {
                                            goPolis();
                                        }}
                                        size="middle"
                                        type="transparent"
                                        className={style.connectBtn}
                                    >
                                        Take me to Polis
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            openReportBugModal();
                                        }}
                                        size="middle"
                                        className={style.connectBtn}
                                    >
                                        Report a Bug
                                    </Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
            {isReportBug && (
                <div className={style.wrapper}>
                    {loading && (
                        <div className={style.loadingContainer}>
                            <Loading size={42} />
                        </div>
                    )}
                    <Formik
                        initialValues={{
                            subject: reportBugFormValue.subject,
                            reproduce: reportBugFormValue.reproduce,
                            screenshots: reportBugFormValue.screenshots,
                        }}
                        validate={(values) => {
                            const errors: Partial<IReportBugFormValue> = {};
                            if (!values.subject) {
                                errors.subject = 'Please enter subject.';
                            } else {
                                handleInputChange('subject', values.subject);
                            }

                            if (!values.reproduce) {
                                errors.reproduce = 'Please enter reproduce.';
                            } else {
                                handleInputChange(
                                    'reproduce',
                                    values.reproduce,
                                );
                            }

                            // if (!reportBugFormValue.screenshots) {
                            //     errors.screenshots =
                            //         'Please upload screenshots.';
                            //     setUploadScreenshots(true);
                            // }

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setUploadScreenshots(false);
                            submitReportBug();
                            // setSubmitting(false);
                        }}
                    >
                        {({ submitForm }) => (
                            <Form className={style.reportBugForm}>
                                <FormControl
                                    fullWidth
                                    className={style.formControl}
                                >
                                    <InputLabel htmlFor="subject" shrink>
                                        Subject
                                    </InputLabel>
                                    <Field
                                        component={TextField}
                                        name="subject"
                                        autoFocus
                                        placeholder="Add text"
                                    />
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    className={style.formControl}
                                >
                                    <InputLabel htmlFor="reproduce" shrink>
                                        Steps to Reproduce
                                    </InputLabel>
                                    <Field
                                        component={TextField}
                                        name="reproduce"
                                        multiline
                                        rows={4}
                                        placeholder="Add text"
                                    />
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    className={style.formControl}
                                >
                                    <InputLabel htmlFor="screenshots" shrink>
                                        Upload Screenshots
                                    </InputLabel>
                                    <UploadScreenshots
                                        className={style.screenshots}
                                        onChange={uploadScreenshotFile}
                                        accessToken={accessToken}
                                        file={reportBugFormValue.screenshots}
                                    />
                                    {isUploadScreenshots && (
                                        <p
                                            className={
                                                style.dacFormPhoto +
                                                ' MuiFormHelperText-root Mui-error'
                                            }
                                        >
                                            Please upload Screenshots.
                                        </p>
                                    )}
                                    <FormHelperText
                                        className={style.fileHelper}
                                    >
                                        Support file type: JPG, JPEG, PNG. File
                                        size: No more than 1GB
                                    </FormHelperText>
                                </FormControl>
                                <Row
                                    align="center"
                                    customClass={style.modalActionBar}
                                >
                                    <Button
                                        onClick={closeReportBugModal}
                                        size="middle"
                                        type="transparent"
                                        className={style.connectBtn}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        size="middle"
                                        className={style.connectBtn}
                                    >
                                        Submit
                                    </Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </Modal>
    );
}
