import { useMemo } from 'react';
import axios from 'axios';
import { useAppSelector } from '@/state/hooks';
import AlertFunc from '@/utils/components/alert';

import { REQUEST_STATUS_SUCCESS } from '@/constants/request';
import { REPORT_BUG_FAIL_MSG } from '@/constants/tips';
interface Params {
    subject: string;
    reproduce: string;
    screenshots: string;
}

const host = process.env.REACT_APP_APP_SERVER_PREFIX;

export default function UseReportBug() {
    const { client } = useAppSelector((state) => state.metisMiddlewareClient);
    const accessToken = client ? client.accessToken : undefined;

    async function setEmail(params: Params) {
        if (!accessToken) return false;

        try {
            const { data: { code } = {} } = await axios({
                method: 'POST',
                url: `${host}/api/mail/smtp_send`, // smtp_send gmail_send
                headers: {
                    'access-token': accessToken,
                },
                data: params,
            });

            if (parseInt(code) === REQUEST_STATUS_SUCCESS) {
                return true;
            } else {
                AlertFunc({
                    type: 'error',
                    text: REPORT_BUG_FAIL_MSG,
                });
                return false;
            }
        } catch (error) {
            console.error('setEmail error');
            console.error(error);
            return false;
        }
    }

    const clientReady = useMemo(() => {
        return !!client;
    }, [client]);

    return {
        clientReady,
        setEmail,
    };
}
