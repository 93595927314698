import { ChainId } from '@/constants';
import { AUTH_PARAMS } from '@/constants/storage';
import { PolisClient } from '@metis.io/middleware-client';

export async function getPolisClient(chainId: ChainId, isLogin?: boolean) {
    const { REACT_APP_POLIS_APP_ID } = process.env;
    const polisClient = new PolisClient({
        appId: REACT_APP_POLIS_APP_ID as string,
        chainId: chainId,
        apiHost: 'https://me.nuvosphere.io',
    });

    if (isLogin && localStorage && localStorage[AUTH_PARAMS])
        await polisClient.connect(JSON.parse(localStorage[AUTH_PARAMS]));
    return polisClient;
}

export function getUrlVars() {
    let vars: any = {};
    let hash: string[] = [];
    let hashes = window.location.href
        .slice(window.location.href.indexOf('?') + 1)
        .split('&');

    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars[hash[0]] = hash[1];
    }

    return vars;
}
