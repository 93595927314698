import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import Button from '@/components/Button';
import {
    InputLabel,
    Input,
    Tooltip,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import CustomAlert from '@/components/Alert';
import Loading from '@/components/Loading';

import searchDac, { SearchDac } from '@/graphql/search-dac';
import RouterConfig from '@/constants/router';
import { isImage } from '@/utils/validate';
import { STATIC_LOGO, LIMIT } from '@/constants';

import style from './index.module.scss';
import getDacPlusInfo from '@/graphql/dac-plusinfo';

interface SearchParams {
    name?: string;
}

export default function Search() {
    const history = useHistory();

    const [data, setData] = useState<SearchDac[]>([]);
    const [pageData, setPageData] = useState<SearchDac[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchVal, setSearchVal] = useState<string>();

    const [open, setOpen] = useState(false);
    // const [currentDac, setCurrentDac] = useState<SearchDac>();
    const [total, setTotal] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [startNum, setStartNum] = React.useState(1);
    const [endNum, setEndNum] = React.useState(5);

    const searchInput = useRef<HTMLInputElement>();

    const handleBack = () => {
        history.push('/');
    };

    const goDacHome = async (id: number) => {
        // setCurrentDac(item);
        if (!id) {
            return;
        }
        const res = await getDacPlusInfo({
            id,
        });

        try {
            history.push(RouterConfig.getDacHome(id, res ? res.linkPath : ''));
        } catch (error) {
            // ignore
            console.error(error);
        }
    };

    const closeAlert = () => {
        setOpen(false);
    };

    const filterDacData = (list: SearchDac[], page: number, total: number) => {
        const star = LIMIT * (page - 1);
        const end = LIMIT * page < total ? LIMIT * page : total;
        const currentPageData = list.slice(star, end);
        setStartNum(star + 1);
        setEndNum(end);
        setPageData(currentPageData);
    };

    const renderDacData = (res: SearchDac[]) => {
        const count = res.length;
        const pageCount = Math.ceil(count / LIMIT);
        setData(res);
        setTotal(count);
        setCount(pageCount);
        filterDacData(res, 1, count);
    };

    async function searchResult(params: SearchParams) {
        setLoading(true);
        setData([]);
        const res = await searchDac(params);
        setLoading(false);
        renderDacData(res);
    }

    const filterDac = () => {
        const params = {
            name: searchVal,
        };
        searchResult(params);
    };

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        filterDacData(data, value, total);
    };

    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value || '';
        setSearchVal(value);
    };

    useEffect(() => {
        searchResult({ name: '' });
    }, []);

    return (
        <>
            <section className={style.searchContainer}>
                <div className={style.actionBar}>
                    <Button
                        className={style.btnBack}
                        onClick={handleBack}
                        type="transparent"
                    >
                        &lt; Back
                    </Button>
                </div>

                <form
                    className={style.searchForm}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <InputLabel
                        className={style.searchLabel}
                        htmlFor="name"
                        shrink
                    >
                        Filter DAC Name
                    </InputLabel>
                    <Input
                        id="name"
                        className={style.searchInput}
                        autoFocus
                        ref={searchInput}
                        onChange={handleInput}
                        aria-describedby="name-text"
                    />
                    <Button className={style.searchButton} onClick={filterDac}>
                        Filter
                    </Button>
                </form>
                <div className={style.searchResult}>
                    {pageData.length > 0 && (
                        <List className={style.searchDacItems}>
                            {pageData.map((item, idx) => {
                                return (
                                    <ListItem
                                        key={idx}
                                        className={style.searchDacItem}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                src={
                                                    isImage(item.photo)
                                                        ? item.photo
                                                        : STATIC_LOGO
                                                }
                                                className={style.searchDacPhoto}
                                                alt="dacPhoto"
                                            ></Avatar>
                                        </ListItemAvatar>
                                        <div className={style.searchDacText}>
                                            <Tooltip
                                                title={item.name}
                                                placement="top"
                                            >
                                                <h3
                                                    className={
                                                        style.searchDacTitle
                                                    }
                                                >
                                                    {item.name}
                                                </h3>
                                            </Tooltip>
                                            <Tooltip
                                                title={item.introduction}
                                                placement="top"
                                            >
                                                <div
                                                    className={
                                                        style.searchDacIntroduction
                                                    }
                                                >
                                                    {item.introduction}
                                                </div>
                                            </Tooltip>
                                        </div>

                                        <Button
                                            onClick={async () => {
                                                await goDacHome(
                                                    Number(item.id),
                                                );
                                            }}
                                            hideLoading={false}
                                            className={style.btnSubmit}
                                        >
                                            Enter
                                        </Button>
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                    {loading && (
                        <div className={style.loadingContainer}>
                            <Loading size={36} />
                        </div>
                    )}
                </div>
                {!!count && (
                    <div className={style.dacPaginationCon}>
                        <Pagination
                            className={style.dacPagination}
                            page={page}
                            count={count}
                            onChange={handleChange}
                            siblingCount={0}
                            showFirstButton
                            showLastButton
                        />
                        <div className={style.dacPaginationText}>
                            Showing {startNum} - {endNum} of {total}
                        </div>
                    </div>
                )}
            </section>
            <CustomAlert
                open={open}
                type={'error'}
                onClose={closeAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                text="Please choose DAC"
            />
        </>
    );
}
