import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import copy from 'copy-to-clipboard';
import Row from '@/components/Row';
import Text from '@/components/Text';
import style from './index.module.scss';
import Alert from '@/utils/components/alert';
interface Props {
    label?: string;
    code: string;
    message?: string;
}

export default function Copy(props: Props) {
    const {
        label,
        code = '',
        message = 'You have copied your code successfully',
    } = props;
    const [isTipVisible, setIsTipVisible] = React.useState(false);

    const copyAddress = () => {
        let timer: any;
        copy(code);
        setIsTipVisible(true);

        timer = setTimeout(() => {
            closeTooltip();
            clearTimeout(timer);
        }, 1500);
    };

    const closeTooltip = () => {
        setIsTipVisible(false);
    };

    return (
        <div className={style.copy}>
            <Row customClass={style.copyContainer}>
                {!!label && (
                    <Text
                        customClass={style.copyLabel}
                        color={'rgba(255,255,255,0.5)'}
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={'20px'}
                    >
                        {label}
                    </Text>
                )}
                <Text
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={'20px'}
                    customClass={style.code}
                >
                    <span className={style.codeValue}>{code}</span>
                </Text>
            </Row>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={closeTooltip}
                arrow
                open={isTipVisible}
                placement="top"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                interactive
                title={message}
            >
                <span
                    className={style.copyMenu}
                    onClick={() => {
                        copyAddress();
                        Alert({
                            type: 'success',
                            text: 'Copied',
                        });
                    }}
                >
                    <img src="/static/images/ic_copy.png" alt="copy menu" />
                </span>
            </Tooltip>
        </div>
    );
}
