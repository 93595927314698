import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import styled from 'styled-components';
import ConnectAccountModal from '@/components/ConnectAccountModal';
import Row from '@/components/Row';
import Text, { Title } from '@/components/Text';

import { useAppSelector } from '@/state/hooks';
import { ROLE } from '@/constants';
import RouterConfig from '@/constants/router';
import { useDispatch } from 'react-redux';
import { setDac, setDacLink, setDacRole, setShareLinkCode } from '@/state/dac';
import getDacOverview from '@/graphql/get-dacoverview';
import Loading from '@/components/Loading';
import styles from './dacAction.module.scss';

const CardDacRole = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 116px;
    height: 36px;
    background: #d8d8d8
        ${(props) =>
            props.theme === ROLE.MEMBER
                ? 'linear-gradient(90deg, #fca700 0%, #d23169 100%)'
                : 'linear-gradient(90deg, #96237F 0%, #D23169 100%)'};
    border-radius: 0px 4px 0px 16px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    line-height: 36px;
`;

export default function DacList() {
    const user = useAppSelector((state) => state.user);
    const history = useHistory();
    const dispatch = useDispatch();
    const { chainId } = useAppSelector((state) => state.metisMiddlewareClient);
    const [totalDacCount, setTotalDacCount] = useState(0);
    const [totalUserCount, setTotalUserCount] = useState(0);
    async function getDacOverviewFunc() {
        const res = await getDacOverview();
        setTotalDacCount(res.activeDacCount);
        setTotalUserCount(res.userCount);
    }

    useEffect(() => {
        if (chainId) {
            getDacOverviewFunc();
        }
    }, [chainId]);

    const [isConnectAccount, setIsConnectAccount] = useState(false);

    const handleCreateDac = async () => {
        if (!user.connected) {
            setIsConnectAccount(true);
            return;
        }

        history.push(RouterConfig.create);
    };

    const jumpToDacPage = () => {
        if (user.dac && user.dac.id) {
            history.push(
                RouterConfig.getDacHome(user.dac.id.toString(), user.dacLink),
            );
            dispatch(setDac(user.dac));
            dispatch(setDacLink(user.dacLink));
            dispatch(setShareLinkCode(user.shareLinkCode));
            dispatch(setDacRole(user.dacUserRole));
        }
    };

    const handleOpenExistingDac = () => {
        history.push(RouterConfig.search);
    };

    const handleCloseModal = () => {
        setIsConnectAccount(false);
    };

    const CreateDacCard = () => {
        if (user.loadingDac) {
            return (
                <div className={`${styles.card} ${styles.loading}`}>
                    <Loading />
                </div>
            );
        }
        if (user.dac) {
            return (
                <div
                    onClick={() => {
                        jumpToDacPage();
                    }}
                    className={`${styles.card} ${styles.createDac}`}
                >
                    <div
                        className={`${styles.dacRole} ${
                            user.dacUserRole === ROLE.MEMBER
                                ? styles.member
                                : styles.creator
                        }`}
                    >
                        DAC{' '}
                        {user.dacUserRole === ROLE.MEMBER
                            ? 'Member'
                            : 'Creator'}
                    </div>
                    <div className={styles.cardContent}>
                        <span className={styles.cardIcon}>
                            <img
                                src={
                                    user.dac.photo ||
                                    '/static/images/ic_create-dac.png'
                                }
                                alt="icon create dac"
                            />
                        </span>
                        <Row customClass={styles.cardTitle} align="center">
                            <Title fontSize={20}>Enter My DAC</Title>
                        </Row>
                        <Row
                            customClass={styles.cardDescription}
                            align="center"
                        >
                            <Text fontSize={14} fontWeight={400}>
                                {user.dac ? user.dac.name : 'Dac name'}
                            </Text>
                        </Row>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    onClick={() => {
                        handleCreateDac();
                    }}
                    className={`${styles.card} ${styles.createDac}`}
                >
                    <div className={styles.cardContent}>
                        <span className={styles.cardIcon}>
                            <img
                                src="/static/images/ic_create-dac.png"
                                alt="create dac icon"
                            />
                        </span>
                        <Row customClass={styles.cardTitle} align="center">
                            <Title fontSize={20}>Create a DAC</Title>
                        </Row>
                        <Row
                            customClass={styles.cardDescription}
                            align="center"
                        >
                            <Text fontSize={14} fontWeight={400}>
                                Start your company with Metis
                            </Text>
                        </Row>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <div className={styles.actionContainer}>
                {CreateDacCard()}
                <div
                    onClick={() => {
                        handleOpenExistingDac();
                    }}
                    className={`${styles.card} ${styles.openExistingDac}`}
                >
                    <div className={styles.cardContent}>
                        <span className={styles.cardIcon}>
                            <img
                                src="/static/images/ic_open-dac.png"
                                alt="open dac icon"
                            />
                        </span>
                        <Row customClass={styles.cardTitle} align="center">
                            <Title fontSize={20}>Browse existing DACs</Title>
                        </Row>
                        <Row
                            customClass={styles.cardDescription}
                            align="center"
                        >
                            <Text
                                fontSize={14}
                                fontWeight={400}
                                color="#89DBCD"
                            >
                                There are a total of {totalDacCount} DAC and{' '}
                                {totalUserCount} Members.
                            </Text>
                        </Row>
                    </div>
                </div>
            </div>

            <ConnectAccountModal
                isOpen={isConnectAccount}
                onClose={handleCloseModal}
            />
        </>
    );
}
