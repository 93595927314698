import React, { useEffect, useMemo, useState } from 'react'; // useEffect,
import Modal from '@/components/Modal';
import style from './index.module.scss';
import Row from '@/components/Row';
import Text, { SecondTitle, Warning, Error } from '@/components/Text'; // , ThirdTitle, Warning
import cx from 'classnames';
import Button from '@/components/Button';
import RefreshImg from '@/assets/image/refresh.png';
import SuccessImg from '@/assets/image/success.png';
import IdeaImg from '@/assets/image/idea.png';
import { useCallback } from 'react';
import useMiddlewareClient from '@/hooks/use-middleware-client';
import { DAC_STATUS, DEPOSIT_MAX, DEPOSIT_MIN, ROLE } from '@/constants';
import { updateMetisBalance } from '@/state/user/hooks';

import BigNumber from 'bignumber.js';
import RouterConfig from '@/constants/router';
import { useHistory } from 'react-router-dom';

export interface Props {
    isOpen: boolean;
    onClose(): void;
    dacData?: {
        id: string | number;
        name: string;
        creator?: string;
        status: DAC_STATUS;
    };
    type?: 'miningClaim' | 'mining' | 'vault';
    userType?: ROLE;
    totalAmount?: BigNumber;
    onSuccess?(): void;
    onMiningWithdrawAllTvl?(): void;
    needClaimFirst?: boolean;
}

export default function WithdrawModal({
    isOpen,
    onClose,
    dacData = {
        id: '',
        name: 'Unknow',
        creator: '',
        status: DAC_STATUS.active,
    },
    type = 'mining',
    userType = ROLE.CREATOR,
    totalAmount = new BigNumber(0),
    onSuccess,
    onMiningWithdrawAllTvl,
    needClaimFirst,
}: Props) {
    const history = useHistory();
    const { withdrawValueShare, miningWithdraw } = useMiddlewareClient();
    const updateMetisBalanceFunc = updateMetisBalance();
    const [inputAmount, setInputAmount] = useState(new BigNumber(0));
    const [step, setStep] = useState('init');

    const isTransPage = useMemo(() => {
        return step === 'trans';
    }, [step]);

    const handleModalClose = useCallback(() => {
        setStep('init');
        onClose();
        if (type === 'miningClaim' && dacData.status === DAC_STATUS.dismiss) {
            history.push(RouterConfig.getVault());
        }
    }, [onClose, type, history, dacData]);

    // useEffect(() => {
    //     setInputAmount(totalAmount);
    // }, [totalAmount]);
    const isDacDismiss = dacData.status === DAC_STATUS.dismiss;

    const isCreatorNeedToDisResolveFirst = useMemo(() => {
        // Creator不能提取0-10的数量，如果要提取，先去dac profile 解散
        return (
            !isDacDismiss &&
            type === 'mining' &&
            userType === ROLE.CREATOR &&
            inputAmount.plus(DEPOSIT_MIN).isGreaterThan(totalAmount)
        );
    }, [inputAmount, totalAmount, userType, isDacDismiss, type]);

    async function handelClaimFirst() {
        let success = false;
        success = await miningWithdraw(
            userType,
            '0',
            dacData.id,
            dacData.creator || '',
            false,
        );

        if (success) {
            setStep('detail');
        }
    }

    async function handleWithdraw() {
        let success = false;
        if (type === 'vault') {
            success = await withdrawValueShare();
        } else {
            let amountParams = '0';
            if (type !== 'miningClaim') {
                amountParams = isDacDismiss
                    ? totalAmount.toFixed()
                    : inputAmount.toFixed();
            }
            success = await miningWithdraw(
                userType,
                amountParams,
                dacData.id,
                dacData.creator || '',
                type !== 'miningClaim',
            );
        }

        if (success) {
            if (
                type === 'mining' &&
                onMiningWithdrawAllTvl &&
                (isDacDismiss ||
                    (totalAmount.toNumber() &&
                        totalAmount.isEqualTo(inputAmount)))
            ) {
                onMiningWithdrawAllTvl();
            }
            setStep('success');
            if (onSuccess) {
                onSuccess();
            }
            updateMetisBalanceFunc();
        }
    }

    const inputErrorMessage = useMemo(() => {
        if (type !== 'mining' || isDacDismiss) return '';

        const tvl = totalAmount.toNumber();
        if (inputAmount.toNumber()) {
            if (inputAmount.isGreaterThan(totalAmount)) {
                return 'Insufficient balance';
            }

            if (userType === ROLE.CREATOR) {
                if (totalAmount.isLessThanOrEqualTo(DEPOSIT_MIN)) {
                    return `In order to keep the DAC functioning, you are required to lock at least ${DEPOSIT_MIN} Metis Token.If you want to withdraw all, please  dissolve DAC.`;
                } else if (totalAmount.isGreaterThan(DEPOSIT_MIN)) {
                    const leftMetis = totalAmount.minus(inputAmount).toNumber();
                    if (leftMetis < DEPOSIT_MIN) {
                        return `In order to keep the DAC functioning, you are required to lock at least ${DEPOSIT_MIN} Metis Token, in this case, you may withdraw up to ${
                            tvl - DEPOSIT_MIN
                        } Metis tokens.`;
                    }
                }
            } else {
                if (totalAmount.isLessThanOrEqualTo(DEPOSIT_MIN)) {
                    return `In order to participate in mining, you are required to lock at least ${DEPOSIT_MIN} Metis Token. Please ensure you keep the minimum TVL to continue to participate in mining.`;
                } else if (
                    totalAmount.isGreaterThan(DEPOSIT_MIN) &&
                    !inputAmount.isEqualTo(totalAmount)
                ) {
                    const leftMetis = totalAmount.minus(inputAmount).toNumber();
                    if (leftMetis < DEPOSIT_MIN) {
                        return `You can withdraw ${
                            tvl - DEPOSIT_MIN
                        } Metis, In order to ensure the existence of DAC, need to leave at least ${DEPOSIT_MIN} Metis Token.`;
                    }
                }
            }
        }

        return '';
    }, [totalAmount, inputAmount, userType, type, isDacDismiss]);

    // MEMBER 提取全部则会导致停止挖矿
    const warningMessage = useMemo(() => {
        // if (userType === ROLE.MEMBER && type === 'mining') {
        //     if (inputAmount.isEqualTo(totalAmount)) {
        //         return `The mining activity will stop as soon as TVL < ${DEPOSIT_MIN} Metis token. `;
        //     }
        // }
        if (type === 'mining') {
            if (inputAmount.isEqualTo(totalAmount)) {
                return userType === ROLE.MEMBER
                    ? `The mining activity will stop as soon as TVL < ${DEPOSIT_MIN} Metis token. `
                    : `In order to keep the DAC functioning, you are required to lock at least ${DEPOSIT_MIN} Metis Token.If you want to withdraw all, you will dissolve DAC.`;
            }
        }
    }, [userType, totalAmount, inputAmount]);

    const inputEnable = useMemo(() => {
        // return !isDacDismiss && type === 'mining';
        return false;
    }, [type, isDacDismiss]);

    const withdrawDisabled = useMemo(() => {
        // if (type === 'mining' && !isDacDismiss && !inputAmount.toNumber()) {
        //     return true;
        // }

        // return false;
        return false;
    }, [type, inputAmount, isDacDismiss]);

    // if (userType === ROLE.VIEWER) return null;

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleModalClose}
            hideClose={isTransPage}
            disableBackgroundClick={isTransPage}
        >
            <div className={style.wrapper}>
                {(type === 'mining' || type === 'vault') && step === 'init' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                lineHeight={1.4}
                                fontWeight={700}
                            >
                                {type === 'mining'
                                    ? 'Regarding withdrawing Total Value Locked (TVL)'
                                    : 'Withdraw your reward'}
                            </SecondTitle>
                        </Row>
                        <Row align="center">
                            <img
                                src={IdeaImg}
                                className={style.infoImg}
                                alt=""
                            />
                        </Row>
                        <Row align="center">
                            <Text
                                color="#fff"
                                fontSize={14}
                                lineHeight={1.6}
                                align="center"
                            >
                                {type === 'mining'
                                    ? `This action will withdraw your staked Metis to your wallet address without estimated rewards and has no influence to DAC state. The rewards wouldn't be claimed to the vault in real-time. The actual arrive time and amount of rewards shall prevail.`
                                    : // ? needClaimFirst
                                      //     ? `Before you withdraw all TVL, you need to claim your reward`
                                      //     : dacData.status === DAC_STATUS.active
                                      //     ? `If you withdraw all TVL,you will stop participating in DAC mining and you will not get rewards.`
                                      //     : 'The system will automatically depsoit your reward to Vault when you withdraw your TVL. Please visit Vault to withdraw your reward.'
                                      `When any DAC owner/members claimed the reward from the Vault, 10% of the claimed amount will be deducted and kept at the Token Vault which will be shared equally among all members who have not claimed the reward.`}
                            </Text>
                        </Row>
                        <div className={style.footer}>
                            <Row align="center">
                                <Button
                                    className={style.button}
                                    onClick={() => {
                                        if (needClaimFirst) {
                                            handelClaimFirst();
                                        } else {
                                            setStep('detail');
                                        }
                                    }}
                                >
                                    Acknowledge
                                </Button>
                            </Row>
                            <Row align="center">
                                <Button
                                    className={style.button}
                                    type="transparent"
                                    onClick={handleModalClose}
                                >
                                    Cancel
                                </Button>
                            </Row>
                        </div>
                    </>
                )}
                {type === 'miningClaim' && step === 'init' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                lineHeight={1.4}
                                fontWeight={700}
                            >
                                Regarding your Metis DAC Mining Rewards.
                            </SecondTitle>
                        </Row>
                        <Row align="center">
                            <img
                                src={IdeaImg}
                                className={style.infoImg}
                                alt=""
                            />
                        </Row>
                        <Row align="center">
                            <Text color="#fff" fontSize={16} lineHeight={1.6}>
                                The reward will be stored in the Mining vault
                                where you can withdraw from.
                            </Text>
                        </Row>
                        <div className={style.footer}>
                            <Row align="center">
                                <Button
                                    className={style.button}
                                    hideLoading={false}
                                    onClick={handleWithdraw}
                                >
                                    Acknowledge
                                </Button>
                            </Row>
                            <Row align="center">
                                <Button
                                    className={style.button}
                                    type="transparent"
                                    onClick={handleModalClose}
                                >
                                    Cancel
                                </Button>
                            </Row>
                        </div>
                    </>
                )}
                {step === 'detail' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                {type === 'mining'
                                    ? 'Withdrawing Total Value Locked (TVL)'
                                    : 'Withdraw your reward '}
                            </SecondTitle>
                        </Row>
                        <div className={style.content}>
                            <Row>
                                <Text fontSize={16} color="main">
                                    {type === 'vault'
                                        ? 'Value(Metis)'
                                        : 'Withdraw'}
                                </Text>
                            </Row>
                            <div className={style.inputWrapper}>
                                <div
                                    className={cx(
                                        style.block,
                                        style.input,
                                        inputEnable ? style.lightbg : '',
                                    )}
                                >
                                    <img
                                        src="/metis.svg"
                                        className={style.logo}
                                        alt="Metis"
                                    />
                                    <input
                                        placeholder={'Please enter amount'}
                                        value={
                                            inputEnable
                                                ? inputAmount.toNumber() ||
                                                  undefined
                                                : totalAmount.toNumber()
                                        }
                                        disabled={!inputEnable}
                                        type="number"
                                        autoFocus
                                        onChange={(e) => {
                                            setInputAmount(
                                                new BigNumber(
                                                    e.currentTarget.value || 0,
                                                ),
                                            );
                                        }}
                                    />
                                    {inputEnable && (
                                        <div className={style.balance}>
                                            <Text color="#ccc">TVL: </Text>
                                            <Text>
                                                {totalAmount.toFixed(3)}
                                            </Text>
                                        </div>
                                    )}
                                </div>
                                {inputEnable && (
                                    <Button
                                        className={style.max}
                                        onClick={() => {
                                            setInputAmount(totalAmount);
                                        }}
                                    >
                                        MAX
                                    </Button>
                                )}
                            </div>
                            {inputErrorMessage && (
                                <Row align="between">
                                    {<Error>{inputErrorMessage}</Error>}
                                    {/* <Warning>
                                        *The range of staked tokens
                                        paryicipating in mining is 100~2000.
                                    </Warning> */}
                                </Row>
                            )}
                            {/* {warningMessage && ( */}
                            {/* <Row align="between"> */}
                            {/* {<Warning>{warningMessage}</Warning>} */}
                            {/* <Warning>
                                        *The range of staked tokens
                                        paryicipating in mining is 100~2000.
                                    </Warning> */}
                            {/* </Row> */}
                            {/* )} */}
                            {type === 'vault' && (
                                <>
                                    <Row>
                                        <Text fontSize={16} color="main">
                                            You will receive(Metis)
                                        </Text>
                                    </Row>
                                    <div className={style.inputWrapper}>
                                        <div
                                            className={cx(
                                                style.block,
                                                style.input,
                                            )}
                                        >
                                            <img
                                                src="/metis.svg"
                                                className={style.logo}
                                                alt="Metis"
                                            />
                                            <input
                                                value={totalAmount
                                                    .multipliedBy(0.9)
                                                    .toFixed()}
                                                disabled={true}
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            {type !== 'vault' && (
                                <>
                                    <div
                                        className={cx(
                                            style.block,
                                            style.detail,
                                        )}
                                    >
                                        <Row align="between" lineHeight="none">
                                            <Text>DAC: </Text>
                                            <Text>{dacData.name}</Text>
                                        </Row>
                                        {/* <Row align="between">
                                    <Text>Basic Mining Power：</Text>
                                    <Text>{dacData.power}</Text>
                                </Row> */}
                                    </div>

                                    {/* <Row>
                                        <Text color="#ccc" lineHeight="18px">
                                            {type === 'mining' &&
                                            userType === ROLE.CREATOR
                                                ? `In order to keep the DAC functioning, you are required to lock at least ${DEPOSIT_MIN} Metis Token.If you want to withdraw all, you will dissolve DAC.`
                                                : `The system will automatically
                                            deposit your reward to the Vault
                                            when you withdraw.`
                                            }
                                        </Text>
                                    </Row> */}
                                </>
                            )}
                            <div className={style.footer}>
                                {isCreatorNeedToDisResolveFirst ? (
                                    <>
                                        <Row align="center">
                                            <Button
                                                className={style.button}
                                                onClick={handleModalClose}
                                            >
                                                Acknowledge
                                            </Button>
                                        </Row>
                                        {/* <Row align="center">
                                            <Button
                                                className={style.button}
                                                type="transparent"
                                                onClick={() => {
                                                    history.push(
                                                        RouterConfig.getDacManagement(
                                                            dacData.id,
                                                        ),
                                                    );
                                                }}
                                            >
                                                View Management Page
                                            </Button>
                                        </Row> */}
                                    </>
                                ) : (
                                    <>
                                        <Row align="center">
                                            <Button
                                                className={style.button}
                                                disabled={withdrawDisabled}
                                                hideLoading={false}
                                                onClick={handleWithdraw}
                                            >
                                                {type === 'mining' &&
                                                userType === ROLE.CREATOR
                                                    ? 'Acknowledge'
                                                    : 'Withdraw'}
                                            </Button>
                                        </Row>
                                        {type !== 'mining' && (
                                            <Row align="center">
                                                <Button
                                                    className={style.button}
                                                    type="transparent"
                                                    onClick={handleModalClose}
                                                >
                                                    Cancel
                                                </Button>
                                            </Row>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {step === 'trans' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                Transaction
                            </SecondTitle>
                        </Row>
                        <Row align="center">
                            <img
                                src={RefreshImg}
                                className={style.transLoading}
                                alt=""
                            />
                        </Row>
                        <Row align="center">
                            <SecondTitle color="main">Transacting…</SecondTitle>
                        </Row>
                    </>
                )}
                {step === 'success' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                Succeed!
                            </SecondTitle>
                        </Row>
                        <Row align="center">
                            <img
                                src={
                                    type === 'miningClaim'
                                        ? require('@/assets/image/claim-vault.png')
                                        : SuccessImg
                                }
                                className={style.successIcon}
                                alt=""
                            />
                        </Row>

                        <Row align="center">
                            <Text color="#fff" fontSize={16} align="center">
                                {type === 'miningClaim'
                                    ? `You have claimed the reward which has been deposited into the vault.`
                                    : type === 'mining'
                                    ? `You have withdrawn your staked Metis Tokens successfully. `
                                    : 'You have withdrawn the Total Value Locked (TVL) reward.'}
                            </Text>
                        </Row>
                        {/* <Row align="center">
                            <Text color="#fff" fontSize={16} align="center">
                                <a>Check my wallet</a>
                            </Text>
                        </Row> */}

                        <div className={style.footer}>
                            {type === 'miningClaim' && (
                                <Row align="center">
                                    <Button
                                        className={style.button}
                                        type="primary"
                                        onClick={() => {
                                            history.push(
                                                RouterConfig.getVault(),
                                            );
                                        }}
                                    >
                                        <img
                                            src={require('@/assets/image/vault-white.png')}
                                            alt=""
                                        />
                                        Check Vault
                                    </Button>
                                </Row>
                            )}
                            <Row align="center">
                                <Button
                                    className={style.button}
                                    type="transparent"
                                    onClick={handleModalClose}
                                >
                                    Close
                                </Button>
                            </Row>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
}
