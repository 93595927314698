import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector } from '@/state/hooks';
import { CHAIN_ENV, NETWORK_NAME } from '@/constants';

import style from './index.module.scss';

export default function MetisNetworkSelection() {
    const { chainId } = useAppSelector((state) => state.metisMiddlewareClient);

    const [isOpen, setIsOpen] = useState(false);

    const openSelect = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = () => {
        setIsOpen(false);
    };

    const elSelect = useRef<any>();

    useEffect(() => {
        elSelect.current.handleClick = (event: { target: any }) => {
            if (!elSelect.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', elSelect.current.handleClick);
        return () =>
            document.removeEventListener('click', elSelect.current.handleClick);
    }, []);

    return (
        <>
            <div className={style.metisSelect}>
                <div className={style.metisSelectContainer}>
                    <div
                        ref={elSelect}
                        className={style.selectMenu}
                        // onClick={(event: any) => {
                        //     openSelect();
                        // }}
                    >
                        <span className={style.selectMenuText}>
                            {NETWORK_NAME[CHAIN_ENV[chainId]]}
                        </span>
                        {/* <img
                            src="/static/images/ic_drop-down.png"
                            alt="drop down"
                        /> */}
                    </div>
                    {isOpen && (
                        <ul className={style.selectMenuList}>
                            <li
                                style={{
                                    textAlign: 'left',
                                }}
                                onClick={() => {
                                    handleSelect();
                                }}
                            >
                                Metis Stardust - Closed
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
}
