import React from 'react';
import ReactDOM from 'react-dom';
import Modal, { CustomProps } from '@/components/Modal/CustomModal';

type OriginProps = Omit<CustomProps, 'isOpen' | 'onClose'>;

interface Props extends OriginProps {
    onClose?(): void;
}

const ModalFunc = (props: Props) => {
    let targetRef: any;
    // const propsRef: React.MutableRefObject<Props> = useRef(props);

    const handleDestroy = () => {
        if (!targetRef) {
            return;
        }

        const unmountResult = ReactDOM.unmountComponentAtNode(targetRef);
        if (unmountResult && targetRef && targetRef.parentNode) {
            targetRef.parentNode.removeChild(targetRef);
        }

        targetRef = undefined;

        if (props.onClose) {
            props.onClose();
        }
    };

    const render = function () {
        if (!targetRef) {
            targetRef = document.createElement('div');
            document.body.appendChild(targetRef);
        }

        const component = React.createElement(
            Modal as any,
            {
                ...props,
                isOpen: true,
                onClose: handleDestroy,
            },
            null,
        );

        ReactDOM.render(component, targetRef);
    };

    render();
};

export default ModalFunc;
