import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        background-image: url('/static/images/notfound-bg.png');
    }
`;
const NotFoundContainer = styled.div`
    width: 100%;
    padding-top: 100px;
`;
const NotFoundTop = styled.div`
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    text-align: center;
`;
const NotFoundTip = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: #ff7e00;
    line-height: 33px;
    padding-top: 378px;
    background: url('/static/images/notfound.png') no-repeat center;
`;
const NotFoundBottom = styled.div`
    font-size: 20px;
    color: #fdffff;
    line-height: 28px;
    text-align: center;
`;
export default function NotFound() {
    return (
        <NotFoundContainer>
            <GlobalStyle />
            <NotFoundTop>
                <NotFoundTip>
                    SORRY
                    <br />
                    WE ARE WORKING ON THIS PAGE
                </NotFoundTip>
            </NotFoundTop>
            <NotFoundBottom>IT WILL BE BACK SOON！</NotFoundBottom>
        </NotFoundContainer>
    );
}
