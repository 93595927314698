import React from 'react';
import style from './index.module.scss';
import DacAside from '@/components/DacAside';
import PageHeader from '@/components/PageHeader';
import guidePower from '@/assets/image//guide_power.png';
import guideTime from '@/assets/image/guide_time.png';

export default function MiningGuide() {
    return (
        <>
            <PageHeader pageTitle="" />
            <div className={style.wrapper}>
                <h2 className={style.title}>
                    Metis DAC Staking and Mining Fact Sheet
                </h2>
                <section className={style.guideWrapper}>
                    <h3 className={style.subTitle}>
                        What do you need to prepare before the DAC staking and
                        mining event starts?
                    </h3>
                    <ul>
                        <li>
                            1、 Internet connection to either a laptop or
                            desktop. Currently mobile phones are not supported.
                        </li>
                        <li>
                            2、 Register your Polis account via polis.metis.io.
                            You can now register either by Email, or connect
                            directly to MetaMask to get access to a Polis
                            account.
                        </li>
                        <li>
                            3、 Make sure your MetaMask holds a little more than
                            10 $METIS tokens (staking minimum is 10, plus you’ll
                            need a bit more to pay for gas fees within the
                            Metis’ Layer 2 mainnet, Andromeda), as well as bit
                            of $ETH (to pay for the gas fee for when you bridge
                            from MetaMask to Andromeda).
                        </li>
                        <li>
                            4、 Bridge your Metis Tokens to Andromeda mainnet
                            via bridge.metis.io.
                        </li>
                        <li>5、 Come up with an awesome name for your DAC.</li>
                        <li>
                            6、 If you like, tell your friends and encourage
                            them to join your DAC once it’s created (or have
                            them create their own!).
                        </li>
                        <li>
                            7、 For Tribe NFT holders, you can start to stake
                            your NFT on Nov 25 at tribe.metis.io. Staking your
                            Metis NFT will increase your staking power.
                        </li>
                    </ul>
                </section>
                <section className={style.guideWrapper}>
                    <h3 className={style.subTitle}>
                        What do you need to prepare before the DAC staking and
                        mining event starts?
                    </h3>
                    <dl>
                        <dt>
                            1、 DACs open for registration on Friday Nov 26, 3
                            pm UTC
                        </dt>
                        <dd>
                            - Use the bridge to transfer your Metis Tokens from
                            Ethereum mainnet/your ERC20 wallet to Metis
                            Andromeda, if you haven’t done so already.
                        </dd>
                        <dd>
                            - Follow the on-screen instructions to create your
                            DAC.
                        </dd>
                        <dd>
                            - Stake (between 10 and 2,000 Metis Tokens) and
                            launch your DAC.
                        </dd>
                        <dd>
                            - Share the link to your DAC with friends, and
                            invite people to join.
                        </dd>
                        <dt>
                            2、 Mining pool opens on Friday Nov 26, 7 pm UTC
                            (this is the starting point for calculating APY)
                        </dt>
                        <dd>
                            - Mining pool starts to count rewards, allocating
                            them to every DAC creator and member.
                        </dd>
                        <dd>- Enjoy your rewards!</dd>
                    </dl>
                </section>

                <section className={style.guideWrapper}>
                    <h3 className={style.subTitle}>
                        NFT Mining Power Conversion (for Tribe NFT holders)
                    </h3>
                    <p>
                        The NFT that you have from our previous Tribe NFT
                        minting event will be used to boost your DAC mining
                        power. The conversion table is as follows.
                    </p>
                    <img src={guidePower} alt="" />
                    <dl style={{ marginTop: '24px' }}>
                        <dt>* Important Notice</dt>
                        <dd>- Please stake your NFT on tribe.metis.io.</dd>
                        <dd>- One wallet can only stake one NFT.</dd>
                        <dd>
                            - After staking your NFT, please wait and check the
                            status to see if the “Congratulations” popup shows
                            up; you will then need to use the same wallet
                            address to create your DAC on portal.metis.io.
                            Please do NOT create your DAC before the status
                            change, otherwise you will not be able to use the
                            mining power boost.
                        </dd>
                        <dd>
                            - The mining power boost can only work for creating
                            a DAC, not for joining a DAC.
                        </dd>
                    </dl>
                </section>

                <section className={style.guideWrapper}>
                    <h3 className={style.subTitle}>
                        Rewards Releasing Schedule
                    </h3>
                    <img src={guideTime} alt="" />
                </section>

                <section className={style.guideWrapper}>
                    <h3 className={style.subTitle}>The Initial APY</h3>
                    <dl>
                        <dt>
                            The initial APY is calculated based on the
                            assumption of
                        </dt>
                        <dd>
                            - The 1st second of staking rewards output is 0.0185
                            Metis Token, and it will keep the same speed of
                            output for the rest of the 365 days,
                        </dd>
                        <dd>
                            - In the 1st second, a DAC member stakes 2,000 Metis
                            Tokens into the pool.
                        </dd>
                    </dl>
                    <p>So the initial APY=0.0185*60*24*365/2000=29,171%</p>
                    <p>
                        The APY is calculated for the entire pool. As more
                        members join and stake, the APY will be diluted
                        accordingly.
                    </p>
                </section>

                <section className={style.guideWrapper}>
                    <h3 className={style.subTitle}>
                        Estimated Rewards Calculation
                    </h3>
                    <dl>
                        <dt>
                            Your staking rewards are related to the number of
                            Metis Tokens you stake, the mining power you have,
                            and the percentage of your weighted mining power
                            compared to the overall weighted mining power.
                        </dt>
                        <dd>
                            - DAC staking reward = Percentage of Total Weighted
                            Mining Power * Daily Output Rewards
                        </dd>
                        <dd>
                            - Percentage of Total Weighted Mining Power = (The
                            Amount Your Staked * Your Mining power)/∑(The Amount
                            of Member N Staked* Mining Power of Member N)
                        </dd>
                    </dl>
                </section>

                <section className={style.guideWrapper}>
                    <h3 className={style.subTitle}>Inviting your friends</h3>
                    <dl>
                        <dt>
                            Inviting your friends to join your DAC will boost
                            your mining power, the more you invite, the higher
                            mining power you can get.{' '}
                        </dt>
                        <dd>
                            - Starting Mining Power for DAC Creator: Power(P)=80
                        </dd>
                        <dd>- When DAC members increase to 2: Power(P)=90</dd>
                        <dd>- When DAC members increase to 3: Power(P)=95</dd>
                        <dd>
                            - Then when new members join the DAC, the mining
                            power will increase 5 for each new member, and the
                            maximum mining power will be 500.
                        </dd>
                        <dd>
                            - DAC members will have a fix mining power:
                            Power(P)=80
                        </dd>
                    </dl>
                </section>

                <section className={style.guideWrapper}>
                    <p style={{ marginTop: '172px', marginBottom: '24px' }}>
                        *** Remember to keep claiming your rewards as they
                        accumulate. Only the token rewards that you’ve
                        claimed(which is in the Vault) qualify to benefit from
                        the 10% deduction sharing pool.{' '}
                    </p>
                    <h3 className={style.subTitle}>Ranking and Lucky Draw</h3>
                    <p>
                        The ranking of a DAC is calculated based on the number
                        of DAC members. The higher ranking a DAC has, the higher
                        possibility it will have to win the lucky draw, which
                        will be granted every week until the pool is closed. The
                        winning DAC in the weekly lucky draw will receive 50
                        Metis Tokens, with the rewards to be distributed to the
                        wallets of every member of the winning DAC.
                    </p>
                </section>

                <section className={style.guideWrapper}>
                    <h3 className={style.subTitle}>Dissolve DAC</h3>
                    <p>
                        You may dissolve your DAC directly if you have fewer
                        than 20 members. If you do, the Metis Tokens you staked
                        will be returned to your wallet and your staking rewards
                        will be deposited to the Vault, which you may withdraw
                        manually.
                    </p>
                </section>
                <section className={style.guideWrapper}>
                    <h3 className={style.subTitle}>Important notice</h3>
                    <ul>
                        <li>
                            1、 When you bridge Metis from Ethereum mainnet/your
                            ERC20 wallet to Andromeda mainnet, you will need to
                            pay ETH as the gas cost. When you bridge your $METIS
                            tokens from Andromeda mainnet to Ethereum
                            mainnet/your ERC20 wallet, you will pay the gas cost
                            entirely in $METIS tokens. The cost of that
                            withdrawal is around 2.5 $METIS for now, a necessary
                            amount to ensure the security of withdrawals from
                            Layer 2 to Layer 1. (Withdrawal costs are subject to
                            change based on various factors, including price of
                            $METIS tokens.)
                        </li>
                        <li>
                            2、 Since we just released our Andromeda mainnet, we
                            are taking extra precautions to ensure the security
                            of your assets. As a result, we have adjusted and
                            prolonged the deposit time from L1 (Ethereum
                            mainnet) to L2 (Andromeda mainnet) to keep assets
                            more secure. In the future, deposit times will be
                            considerably smaller.
                        </li>
                        <li>
                            3、 It will take one week for you to withdraw your
                            tokens from Andromeda to Ethereum mainnet. This is
                            because transactions on Optimstic Rollup require one
                            week to go through the challenging period and get
                            validated. (In the future, Metis’ system of
                            Verifiers and Rangers will reduce transaction time
                            considerably, to hours and eventually to minutes.)
                        </li>
                        <li>
                            4、 The Metis DAC staking program will last at least
                            56 days. After that point, Metis will decide whether
                            or not to keep the DAC staking program and what
                            staking reward levels will be, based on market
                            conditions and other factors.
                        </li>
                        <li>
                            5、 Metis is continuously in a building stage. We
                            have tried our best to achieve the security goal,
                            but there might be some bugs or issues that we
                            haven’t attended to. Users may participate in the
                            DAC staking program, at their own risk.
                        </li>
                    </ul>
                </section>
            </div>
            <DacAside />
        </>
    );
}
