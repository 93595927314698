import React, { useMemo, useState } from 'react';

import Popover from '@material-ui/core/Popover';

import styled from 'styled-components';
// import JoinDacModal from '@/components/JoinDacModal';
import DismissModal from '@/components/DisbandDacModal';
import IconDot from '@/components/IconDot';
import Text from '@/components/Text';
import MetisNetworkSelection from '@/components/MetisNetworkSelection';

import Modal from '@/utils/components/modal';

import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { getUpdateDacByIdHooks } from '@/state/dac/hooks';

import { DAC_MEMBERS_MIN, DAC_STATUS, ROLE } from '@/constants';

import { userExitDacHooks } from '@/state/user/hooks';
import Button from '@/components/Button';
import style from './index.module.scss';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from 'react-router-dom';
import RouterConfig from '@/constants/router';
import { setDac, setDacLink, setDacRole, setShareLinkCode } from '@/state/dac';
import JoinDacButton from '@/components/JoinDacButton';
import ModalFunc from '@/utils/components/modal';
const PageHeaderContainer = styled.div`
    min-height: 64px;
    margin-bottom: 16px;

    &::after {
        content: '';
        display: table;
        clear: both;
    }
`;

const PageHeaderMain = styled.div`
    width: 900px;
    float: right;

    &::after {
        content: '';
        display: table;
        clear: both;
    }
`;

const PageHeaderTitle = styled.h3`
    font-size: 24px;
    font-weight: 600;
    color: $white;
    line-height: 40px;
    float: left;
`;

const PageHeaderActions = styled.div`
    font-size: 0;
    float: right;
`;

const DacOwner = styled.div`
    margin-right: 16px;
    padding-top: 6px;
    display: inline-block;
    width: 100px;
    height: 64px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: $white;
    line-height: 17px;
    text-align: center;
    cursor: pointer;
    vertical-align: top;
`;

const DacOwnerPhoto = styled.img`
    margin: 0 auto 6px;
    display: block;
    width: 32px;
    height: 32px;
`;

const AnyActionButton = styled.button`
    margin-left: 16px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    cursor: pointer;
    vertical-align: top;
`;

const JoinIcon = styled.img`
    margin-right: 13px;
    width: 26px;
    height: 23px;
    vertical-align: -5px;
`;

interface PropsValue {
    pageTitle?: string;
    dacTitle?: string;
    joinSuccess?(): void;
    showBackDacButton?: boolean;
}

export default function PageHeader({
    pageTitle,
    joinSuccess,
    showBackDacButton = true,
}: PropsValue) {
    const history = useHistory();
    const user = useAppSelector((state) => state.user);
    const { dac, dacLink, userRole, loadingDac } = useAppSelector(
        (state) => state.dac,
    );
    const [isOpenDismissModal, setIsOpenDismissModal] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { updateDacById } = getUpdateDacByIdHooks();
    const exitDac = userExitDacHooks();
    const dispatch = useAppDispatch();

    function handleJoinSuccess() {
        if (dac) {
            updateDacById(dac.id);
        }
        if (joinSuccess) {
            joinSuccess();
        }
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const closePopover = () => {
        setAnchorEl(null);
    };

    const openPopover = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const hasCreateLink = useMemo(() => {
        return !!dacLink;
    }, [dacLink]);

    function handleCretorDismiss() {
        if (userRole !== ROLE.CREATOR || !dac) return;

        if (dac.membersCount >= DAC_MEMBERS_MIN) {
            Modal({
                iconType: 'warning',
                text: `DAC members need to be less than ${DAC_MEMBERS_MIN} to be dissolved.`,
                confirmButtonProps: {
                    text: 'Acknowledge',
                    onClick: () => {},
                },
            });
            return;
        }

        setIsOpenDismissModal(true);
    }

    function handleDismissSuccess() {
        if (dac) {
            exitDac(dac.id);
        }
    }

    function handleMemberClickUnLike() {
        if (dac && dac.id) {
            ModalFunc({
                iconType: 'warning',
                text:
                    'If you wish to leave the DAC, you need to withdraw all staking before doing so, please visit "Pool" to withdraw.',
                confirmButtonProps: {
                    text: 'Take me to Pool',
                    onClick() {
                        history.push(
                            RouterConfig.getDacPool(Number(dac.id), dacLink),
                            {
                                iswithdraw: true,
                                isopen: true,
                            },
                        );
                    },
                },
                cancelButtonProps: {
                    text: 'Cancel',
                },
            });
        }
    }

    return (
        <>
            <PageHeaderContainer>
                <PageHeaderMain>
                    {dac && (
                        <PageHeaderActions>
                            {userRole === ROLE.CREATOR && (
                                <DacOwner>
                                    <DacOwnerPhoto src="/static/images/ic_owner.png" />
                                    DAC Owner
                                </DacOwner>
                            )}
                            {userRole === ROLE.MEMBER && (
                                <DacOwner>
                                    <DacOwnerPhoto src="/static/images/ic_member.png" />
                                    DAC Member
                                </DacOwner>
                            )}
                            {dac.status === DAC_STATUS.active && hasCreateLink && (
                                <>
                                    {userRole === ROLE.MEMBER && (
                                        <Button
                                            className={style.joinButton}
                                            type="transparent"
                                            onClick={handleMemberClickUnLike}
                                        >
                                            <span>
                                                <JoinIcon src="/static/images/ic_like_s_pro.png" />
                                                DAC {dac.name} Joined
                                            </span>
                                        </Button>
                                    )}
                                    {userRole === ROLE.VIEWER && (
                                        <JoinDacButton
                                            dacName={dac.name}
                                            dacId={dac.id}
                                            // dacInvitationCode={dac.invitationCode}
                                            dacCreator={dac.creator}
                                            onSuccess={handleJoinSuccess}
                                            buttonClassName={style.joinButton}
                                            buttonType="transparent"
                                            // dacLink={dacLink}
                                        >
                                            <span>
                                                <JoinIcon src="/static/images/ic_like_s.png" />
                                                Join {dac.name}
                                            </span>
                                        </JoinDacButton>
                                    )}
                                </>
                            )}
                            {/* {userRole === ROLE.CREATOR && (
                                <AnyActionButton
                                    onClick={(event) => {
                                        openPopover(event);
                                    }}
                                >
                                    <IconDot />
                                </AnyActionButton>
                            )}
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={closePopover}
                                className="dacActionPopover"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <div
                                    className="dissolveMenu"
                                    onClick={handleCretorDismiss}
                                >
                                    <Text
                                        fontSize={18}
                                        lineHeight={'25px'}
                                        color="#fff"
                                    >
                                        Dissolve DAC
                                    </Text>
                                </div>
                            </Popover> */}
                        </PageHeaderActions>
                    )}
                    {pageTitle && (
                        <PageHeaderTitle>{pageTitle}</PageHeaderTitle>
                    )}
                    {showBackDacButton &&
                        user.dac &&
                        !loadingDac &&
                        (!dac || dac.id !== user.dac.id) && (
                            <Button
                                className={style.backBtn}
                                type="transparent"
                                onClick={() => {
                                    if (user.dac) {
                                        dispatch(setDac(user.dac));
                                        dispatch(setDacLink(user.dacLink));
                                        dispatch(
                                            setShareLinkCode(
                                                user.shareLinkCode,
                                            ),
                                        );
                                        dispatch(setDacRole(user.dacUserRole));
                                        history.push(
                                            RouterConfig.getDacHome(
                                                user.dac.id,
                                                user.dacLink,
                                            ),
                                        );
                                    }
                                }}
                            >
                                <ChevronLeftIcon
                                    style={{
                                        marginRight: '4px',
                                        color: '#fff',
                                    }}
                                />
                                <span className={style.text}>
                                    Back to my DAC {user.dac.name}
                                </span>
                            </Button>
                        )}
                </PageHeaderMain>
                <MetisNetworkSelection />
            </PageHeaderContainer>
            {dac && (
                <>
                    <DismissModal
                        isOpen={isOpenDismissModal}
                        onClose={() => setIsOpenDismissModal(false)}
                        dacId={dac.id}
                        dacName={dac.name}
                        dacCreator={dac.creator}
                        onSuccess={handleDismissSuccess}
                    />
                </>
            )}
        </>
    );
}
