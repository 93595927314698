import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HttpClient } from '@metis.io/middleware-client';
import { ENV_CHAIN_ID, ChainId } from '@/constants';
// import type { RootState } from '../index';

// Define a type for the slice state
interface State {
    client?: HttpClient;
    chainId: ChainId;
}

// Define the initial state using that type
const initialState: State = {
    client: undefined,
    chainId: ENV_CHAIN_ID,
};

export const metisMiddlewareClient = createSlice({
    name: 'metisMiddlewareClient',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setClient: (state, action: PayloadAction<HttpClient | undefined>) => {
            state.client = action.payload;
        },
        setChainId: (state, action: PayloadAction<number>) => {
            state.chainId = action.payload;
        },
    },
});

export const { setClient, setChainId } = metisMiddlewareClient.actions;

export default metisMiddlewareClient.reducer;
