import { gql } from '@apollo/client';
import client from './index';

interface GraphqlDac {
    id: string | number;
    membersCount: number;
    name: string;
}

interface GraphReturnData {
    dacs: GraphqlDac[];
    dacoverviews: {
        activeUserCount: number;
        activeDacCount: number;
    }[];
}

interface ReturnDacData extends GraphqlDac {
    bonus: number;
}

export default function getRankingList({
    page,
    pageSize,
}: {
    page: number;
    pageSize: number;
}): Promise<{
    dacCount: number;
    dacs: ReturnDacData[];
}> {
    return new Promise((resolve, reject) => {
        const skip = page * pageSize;
        client
            .query<GraphReturnData>({
                variables: {
                    first: pageSize,
                    skip: skip,
                },
                fetchPolicy: 'no-cache',

                query: gql`
                    query dacs($first: Int!, $skip: Int!) {
                        dacs(
                            first: $first
                            skip: $skip
                            orderBy: membersCount
                            orderDirection: desc
                            where: { status: 1 }
                        ) {
                            id
                            membersCount
                            name
                        }
                        dacoverviews {
                            activeUserCount
                            activeDacCount
                        }
                    }
                `,
            })
            .then((res) => {
                const { data } = res;
                let dacs: ReturnDacData[] = [];
                if (data.dacs.length && data.dacoverviews.length) {
                    const activeUserCount = Number(
                        data.dacoverviews[0].activeUserCount,
                    );
                    dacs = data.dacs.map((item) => {
                        return {
                            ...item,
                            bonus:
                                Math.floor(
                                    (item.membersCount / activeUserCount) *
                                        10000,
                                ) / 100,
                        };
                    });
                }
                resolve({
                    dacs,
                    dacCount: Number(data.dacoverviews[0].activeDacCount),
                });
            })
            .catch((e: any) => {
                console.error(e);
                resolve({
                    dacCount: 0,
                    dacs: [],
                });
            });
    });
}
