import React, { useCallback, useMemo, useState } from 'react';
import Modal from '@/components/Modal';
import Row from '@/components/Row';
import Text, { Title } from '@/components/Text';
import Button from '@/components/Button';

import { login } from '@/utils/auth';
import style from './index.module.scss';
import { useAppSelector } from '@/state/hooks';

export interface Props {
    isOpen: boolean;
    onClose(): void;
}

export default function ConnectAccountModal({ isOpen, onClose }: Props) {
    const [step, setStep] = useState('init');
    const { chainId } = useAppSelector((state) => state.metisMiddlewareClient);
    const isTransPage = useMemo(() => {
        return step === 'trans';
    }, [step]);

    const closeSettingsModal = useCallback(() => {
        setStep('init');
        onClose();
    }, [onClose]);

    const handleConnectAccount = () => {
        login(chainId);
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={closeSettingsModal}
                disableBackgroundClick={isTransPage}
            >
                <div className={style.wrapper}>
                    <Row align="center" customClass={style.modalTitle}>
                        <Title align="center" fontSize={20}>
                            Connect your account
                        </Title>
                    </Row>
                    <Row align="center" customClass={style.modalPromptText}>
                        <Text fontSize={14} fontWeight={400}>
                            You need to connect your account with polis
                        </Text>
                    </Row>
                    <Row align="center" customClass={style.modalActionBar}>
                        <Button
                            onClick={() => {
                                handleConnectAccount();
                                closeSettingsModal();
                            }}
                            size="normal"
                            className={style.connectBtn}
                        >
                            Polis
                        </Button>
                    </Row>
                </div>
            </Modal>
        </>
    );
}
