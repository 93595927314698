import React, { useCallback, useMemo, useState } from 'react'; // useEffect,
import Modal from '@/components/Modal';
import style from './index.module.scss';
import Row from '@/components/Row';
import Text, { SecondTitle, Warning, Error } from '@/components/Text'; // , ThirdTitle, Warning, Error,
import cx from 'classnames';
import Button from '@/components/Button';
import RefreshImg from '@/assets/image/refresh.png';
import BandedImg from '@/assets/image/disbanded.png';
import useDacHooks from '@/hooks/use-dac-hooks';
import ModalFunc from '@/utils/components/modal';

export interface Props {
    isOpen: boolean;
    onClose(): void;
    dacId: number;
    dacName: string;
    dacCreator: string;
    tvl?: number;
    onSuccess?(): void;
}

export default function DisBandDac({
    isOpen,
    onClose,
    dacId,
    dacName,
    dacCreator,
    onSuccess,
}: Props) {
    const [step, setStep] = useState('init');
    const [inputName, setInputName] = useState<string>();
    const [inputErrorMessage, setInputErrorMessage] = useState<string>('');
    const { dismissDAC } = useDacHooks();

    const isTransPage = useMemo(() => {
        return step === 'trans';
    }, [step]);

    const handleModalClose = useCallback(() => {
        setStep('init');
        setInputName(undefined);
        onClose();
    }, [onClose]);

    function handelNamePageNext() {
        if (inputName === dacName) {
            setInputErrorMessage('');
            setStep('detail');
        } else {
            setInputErrorMessage('Incorrect DAC name');
        }
    }

    async function handleDismiss() {
        const success = await dismissDAC(dacId);
        if (success) {
            if (onSuccess) {
                onSuccess();
                setStep('success');
            }
        } else {
            ModalFunc({
                iconType: 'wrong',
                text: 'Something went wrong, please try again later.',
            });
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleModalClose}
            hideClose={isTransPage}
            disableBackgroundClick={isTransPage}
        >
            <div className={style.wrapper}>
                {step === 'init' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                Dissolve DAC
                            </SecondTitle>
                        </Row>

                        <div className={style.content}>
                            <Row>
                                <Text fontSize={16} color="main">
                                    Please enter your DAC name to confirm you
                                    wish to dissolve your DAC. Please note this
                                    action can’t be reversed.
                                </Text>
                            </Row>
                            <div className={style.inputWrapper}>
                                <div className={cx(style.block, style.input)}>
                                    <input
                                        placeholder={'DAC Name'}
                                        value={inputName}
                                        autoFocus
                                        onChange={(e) => {
                                            setInputName(
                                                e.currentTarget.value.trim(),
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            {inputErrorMessage && (
                                <Row>{<Error>{inputErrorMessage}</Error>}</Row>
                            )}

                            <div className={style.footer}>
                                <Row align="center">
                                    <Button
                                        className={style.button}
                                        disabled={!inputName}
                                        onClick={handelNamePageNext}
                                    >
                                        Next
                                    </Button>
                                </Row>
                            </div>
                        </div>
                    </>
                )}
                {step === 'detail' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                Dissolve DAC
                            </SecondTitle>
                        </Row>
                        <div className={style.content}>
                            <Row>
                                <Text fontSize={16} color="main">
                                    <p>
                                        Please note that once you dissolve your
                                        DAC,{' '}
                                    </p>
                                    <p>
                                        1. You won’t be able to restore your
                                        DAC.
                                    </p>
                                    <p>
                                        2. The Total Locked Value (TVL) will be
                                        released.
                                    </p>
                                    <p>
                                        3. The rewards will be released to the
                                        vault and you need to enter the vault to
                                        withdraw the amount.
                                    </p>
                                </Text>
                            </Row>

                            <div className={cx(style.block, style.detail)}>
                                <Row align="between" lineHeight="none">
                                    <Text>Disband DAC：: </Text>
                                    <Text>{dacName}</Text>
                                </Row>
                            </div>
                            <div className={style.footer}>
                                <Row align="center">
                                    <Button
                                        className={style.button}
                                        hideLoading={false}
                                        onClick={handleDismiss}
                                    >
                                        Confirm
                                    </Button>
                                </Row>
                            </div>
                        </div>
                    </>
                )}
                {step === 'trans' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                Transaction
                            </SecondTitle>
                        </Row>
                        <Row align="center">
                            <img
                                src={RefreshImg}
                                className={style.transLoading}
                                alt=""
                            />
                        </Row>
                        <Row align="center">
                            <SecondTitle color="main">Transacting…</SecondTitle>
                        </Row>
                    </>
                )}
                {step === 'success' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                Succeed!
                            </SecondTitle>
                        </Row>
                        <Row align="center">
                            <img
                                src={BandedImg}
                                className={style.successIcon}
                                alt=""
                            />
                        </Row>

                        <Row align="center">
                            <Text color="#fff" fontSize={16}>
                                This DAC has been dissolved successfully.
                            </Text>
                        </Row>

                        <div className={style.footer}>
                            <Row align="center">
                                <Button
                                    className={style.button}
                                    type="transparent"
                                    onClick={handleModalClose}
                                >
                                    Close
                                </Button>
                            </Row>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
}
