import useMiddleareClient from '@/hooks/use-middleware-client';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { useCallback, useEffect } from 'react';
import {
    setDacLink,
    setDacUserRole,
    setLoadingDac,
    setMetisBalance,
    setShareLinkCode,
    setClearLoginInfo,
} from './index';
import { setUserDac, setIsOpenConnectModal } from '@/state/user';
import useDacClient from '@/hooks/use-dac-hooks';
import storage from '@/utils/storage';
import { AUTH_DAC, USER_INFO, AUTH_PARAMS } from '@/constants/storage';
import { setClient } from '../metisMiddlewareClient';
import { DAC_STATUS, ROLE } from '@/constants';
import { setDac, setDacRole } from '../dac';
import useUserAvatar from './use-user-avatar';
import getDacPlusInfo from '@/graphql/dac-plusinfo';

export function updateUserDacHooks() {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const { userToDAC, getDacInfoById } = useDacClient();

    async function updateUserDac() {
        dispatch(setLoadingDac(true));
        const res = await userToDAC();
        let returnDac;
        let dacLink;
        let shareCode;
        if (res) {
            const dac = await fetchDacById(res);
            const dacLinkRes = await getDacPlusInfo({
                id: res,
            });
            dacLink =
                dacLinkRes && dacLinkRes.linkPath ? dacLinkRes.linkPath : '';
            shareCode =
                dacLinkRes && dacLinkRes.invitationCode
                    ? dacLinkRes.invitationCode
                    : '';
            dispatch(setDacLink(dacLink));
            dispatch(setShareLinkCode(shareCode));
            if (dac && user.address) {
                let role = ROLE.MEMBER;

                if (user.address.toLowerCase() === dac.creator.toLowerCase()) {
                    role = ROLE.CREATOR;
                }

                dispatch(setDacUserRole(role));
                dispatch(setUserDac(dac));
                storage.set(AUTH_DAC, dac);
            }
        }
        dispatch(setLoadingDac(false));

        return {
            dac: returnDac,
            dacLink,
            shareCode,
        };
    }

    const fetchDacById = async (id: number) => {
        return await getDacInfoById(id);
    };

    return {
        updateUserDac,
    };
}

export function updateMetisBalance() {
    const dispatch = useAppDispatch();
    const { getMetisBalance, clientReady } = useMiddleareClient();

    async function update() {
        if (clientReady) {
            const res = await getMetisBalance();
            if (res) {
                dispatch(setMetisBalance(res));
            }
        }
    }

    return update;
}

export function clearUserLoginInfoHooks() {
    const dispatch = useAppDispatch();
    const { dac } = useAppSelector((state) => state.dac);

    const clear = useCallback(() => {
        dispatch(setClearLoginInfo());
        dispatch(setClient(undefined));

        if (dac) {
            // 退出登录后设置路由dacId 对应role的信息
            dispatch(setDacRole(ROLE.VIEWER));
        }
        storage.remove(USER_INFO);
        storage.remove(AUTH_DAC);
        storage.remove(AUTH_PARAMS);
    }, [dispatch, dac]);

    return clear;
}

export function setIsOpenConnectModalHooks() {
    const dispatch = useAppDispatch();

    const setIsOpen = useCallback(
        (isOpen: boolean = false) => {
            dispatch(setIsOpenConnectModal(isOpen));
        },
        [dispatch],
    );

    return setIsOpen;
}

export function userExitDacHooks() {
    const dispatch = useAppDispatch();
    const { dac: userDac } = useAppSelector((state) => state.user);
    const { dac: pageDac, userRole } = useAppSelector((state) => state.dac);

    function exitDac(dacId: number) {
        if (userDac && userDac.id === dacId) {
            dispatch(setUserDac(undefined));
            dispatch(setShareLinkCode(''));
            dispatch(setDacLink(''));
            dispatch(setDacUserRole(ROLE.VIEWER));
        }

        if (pageDac && pageDac.id === dacId) {
            dispatch(
                setDac({
                    ...pageDac,
                    status:
                        userRole === ROLE.CREATOR
                            ? DAC_STATUS.dismiss
                            : pageDac.status,
                    membersCount: pageDac.membersCount - 1,
                }),
            );
            dispatch(setDacRole(ROLE.VIEWER));
        }
    }

    return exitDac;
}

export default function UserUpdater() {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const { updateUserDac } = updateUserDacHooks();
    const { getMetisBalance, clientWithUserReady } = useMiddleareClient();
    const { getUserAvatar } = useUserAvatar();

    async function getMetisBalanceFunc() {
        const res = await getMetisBalance();

        if (res) {
            dispatch(setMetisBalance(res));
        }
    }

    useEffect(() => {
        let interval: any;
        if (clientWithUserReady) {
            getMetisBalanceFunc();
            interval = setInterval(() => {
                getMetisBalanceFunc();
            }, 30000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [clientWithUserReady]);

    useEffect(() => {
        if (clientWithUserReady && user.address) {
            updateUserDac();
            getUserAvatar();
        }
    }, [clientWithUserReady, user.address]);
    return null;
}
