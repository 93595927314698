import { gql } from '@apollo/client';
import { DacClient } from './index';

interface listData {
    startTime: string;
    endTime: string;
    dac: {
        name: string;
        membersCount: number;
    };
    dacMembersCount: number;
    bonus: number;
    tokenReward: number;
}

interface GraphReturnData {
    data: listData[];
    total: number;
}

export default function getLuckyDrawList({
    page,
    pageSize,
}: {
    page: number;
    pageSize: number;
}): Promise<GraphReturnData> {
    return new Promise((resolve, reject) => {
        const skip = page * pageSize;
        DacClient.query<{
            luckyDraws: GraphReturnData;
        }>({
            variables: {
                limit: pageSize,
                skip: skip,
            },
            fetchPolicy: 'no-cache',
            query: gql`
                query luckyDraws($limit: Int!, $skip: Int!) {
                    luckyDraws(
                        limit: $limit
                        skip: $skip
                        orderBy: endTime
                        orderDirection: asc
                    ) {
                        total
                        data {
                            startTime
                            endTime
                            dac {
                                name
                                membersCount
                            }
                            bonus
                            tokenReward
                            dacMembersCount
                        }
                    }
                }
            `,
        })
            .then((res) => {
                const { data } = res;
                resolve(data.luckyDraws);
            })
            .catch((e: any) => {
                console.error(e);
                resolve({
                    total: 0,
                    data: [],
                });
            });
    });
}
