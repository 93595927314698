import { DacClient } from './index';
import { gql } from '@apollo/client';
import { dac } from '@/state/dac';
import storage from '@/utils/storage';
import { AUTH_PARAMS } from '@/constants/storage';

interface DacPlusInfo {
    id: number;
    linkPath: string;
    invitationCode: string;
}

export default function getDacPlusInfo({
    id,
    link,
    code,
}: {
    id?: number | string;
    link?: string;
    code?: string;
}): Promise<DacPlusInfo | undefined> {
    const Auth = storage.get(AUTH_PARAMS);
    return new Promise((resolve) => {
        DacClient.query<{
            dacPlusInfo: DacPlusInfo;
        }>({
            query: gql`
                query dacPlusInfo(
                    $id: String
                    $linkPath: String
                    $code: String
                ) {
                    dacPlusInfo(id: $id, linkPath: $linkPath, code: $code) {
                        id
                        linkPath
                        invitationCode
                    }
                }
            `,
            context: {
                headers: {
                    'access-token': Auth ? Auth.accessToken : '',
                },
            },
            variables: {
                id: id ? `${id}` : '',
                linkPath: link,
                code,
            },
            fetchPolicy: 'no-cache',
        })
            .then((res) => {
                const { data } = res;

                if (data && data.dacPlusInfo) {
                    resolve({
                        id: Number(data.dacPlusInfo.id),
                        linkPath: data.dacPlusInfo.linkPath,
                        invitationCode: data.dacPlusInfo.invitationCode,
                    });
                    return;
                }

                resolve(undefined);
            })
            .catch((e: any) => {
                console.error(e);

                resolve(undefined);
            });
    });
}
