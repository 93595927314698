import React, { useEffect, useState } from 'react'; // useRef
import { useHistory } from 'react-router';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    Tabs,
    Tab,
    Tooltip,
    Typography,
    Link,
} from '@material-ui/core';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from 'formik'; // FormikHelpers Field,
import { TextField } from 'formik-material-ui'; // SimpleFileUpload

import Button from '@/components/Button';
import ConnectAccountModal from '@/components/ConnectAccountModal';
import DacCompany from './components/DacCompany';
import Progress from '@/components/Progress';
import Row from '@/components/Row';
import Text, { Title } from '@/components/Text';
import UploadImage from '@/components/UploadImage';
import CheckBox from '@/components/CheckBox';
import CheckBoxBorder from '@/components/CheckBoxBorder';
import Modal from '@/utils/components/modal';
import AlertFunc from '@/utils/components/alert';

import {
    BridgeUrl,
    DEPOSIT_MAX,
    DEFAULT_LOGO,
    DEPOSIT_MIN,
    DAC_MEMBERS_MIN,
} from '@/constants';
import RouterConfig from '@/constants/router';
import {
    RE_DAC_NAME,
    RE_DAC_METIS_NAMES,
    RE_DAC_DISABLE_NAMES,
} from '@/constants/regexp'; // RE_DAC_CATEGORY
import {
    NAME_TIPS,
    PHOTO_TIPS,
    NAME_METIS_TIP,
    NAME_ABUSIVE_TIP,
} from '@/constants/tips';
import useMiddleareClient from '@/hooks/use-middleware-client';
import useDacClient from '@/hooks/use-dac-hooks';
import { useAppSelector } from '@/state/hooks';
import { updateUserDacHooks } from '@/state/user/hooks';

import 'react-tagsinput/react-tagsinput.css';
import style from './index.module.scss';
import BigNumber from 'bignumber.js';
import ModalFunc from '@/utils/components/modal';
// import { getUpdateDacByIdHooks } from '@/state/dac/hooks';

interface ITabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface IDacInfo {
    name: string;
    creator: string;
    introduction: string;
    photo: string;
    amount: number;
}

function TabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            className="tabPanelItem"
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    }),
);

export default function CreateDac() {
    const user = useAppSelector((state) => state.user);
    const { createDac, estimateCreateDacGasFee } = useDacClient();
    const { metisBalance } = useAppSelector((state) => state.user);
    const {
        approveMetisTokenToMining,
        isNeedToApproveMining,
        clientWithUserReady,
    } = useMiddleareClient();
    const metisMiddlewareClient = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );
    const { client: { accessToken } = {} } = metisMiddlewareClient;
    const { updateUserDac: updateUserDacState } = updateUserDacHooks();
    // const { updateDacById } = getUpdateDacByIdHooks();
    const [isNeedToApprove, setIsNeedToApprove] = useState(true);

    const classes = useStyles();
    const history = useHistory();

    const [form, setForm] = useState<IDacInfo>({
        photo: '',
        creator: '',
        name: '',
        introduction: '',
        amount: 10,
    });

    const [tabIdx, setTabIdx] = useState<number>(0);
    const [isAgree, setIsAgree] = useState(true);
    const [isConnectAccount, setIsConnectAccount] = useState(false);

    const uploadDacPhoto = (file: any) => {
        handleInputChange('photo', file);
    };

    const setMetisToken = (value: number) => {
        handleInputChange('amount', value);
    };

    const handleInputChange = (label: string, value: any) => {
        setForm((prevProps) => ({
            ...prevProps,
            [label]: value,
        }));
    };

    const handleCloseModal = () => {
        setIsConnectAccount(false);
    };

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChangeAccordion = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean,
    ) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSubmit = async () => {
        try {
            if (!form.name) {
                AlertFunc({
                    type: 'error',
                    text: 'Please Claim a name',
                });
                setTabIdx(0);
                return;
            }
            if (!isAgree) {
                AlertFunc({
                    type: 'error',
                    text:
                        'Please render "Terms and Condition " & " Privacy Policy"and agreed.',
                });
                return;
            }

            if (!user.address) return;

            if (user.dac) {
                Modal({
                    iconType: 'warning',
                    text:
                        'Each account can only join or create one DAC at this stage. Please leave your current DAC if you wish to join this DAC. More functions will be available in following product releases to unleash the full power of DACs to the public.',
                });
                return;
            }

            const params = {
                name: form.name,
                introduction: form.introduction,
                photo: form.photo || DEFAULT_LOGO,
                amount: form.amount,
            };

            if (metisBalance.isLessThan(form.amount)) {
                Modal({
                    title: 'Sorry',
                    text: `The current Metis Token balance ${metisBalance.toFixed(
                        3,
                    )} on your account is not sufficient to support this execution. Please ensure your account has enough balance before proceeding`,
                    confirmButtonProps: {
                        text: 'Deposit Metis Token',
                        onClick() {
                            window.open(BridgeUrl);
                        },
                    },
                });
                return;
            }

            const fee = await estimateCreateDacGasFee(params);

            if (fee.plus(params.amount).isGreaterThan(metisBalance)) {
                ModalFunc({
                    iconType: 'warning',
                    text: `Please leave at least ${fee.toFixed()} Metis Token as the Layer2 gas cost.`,
                });
                return false;
            }

            const res = await createDac(params);
            if (res) {
                await updateUserDacState();
                // await updateDacById(res);
                history.push(RouterConfig.getDacManagement(res.toString()));
            }
        } catch (error) {
            // ignore
            console.error(error);
        }
    };

    const goHome = () => {
        history.push('/');
    };

    const goBack = (index: number) => {
        setTabIdx(index);
    };

    const handleChangeAgree = () => {
        setIsAgree(!isAgree);
    };

    async function handleApprove(nextFunc: () => void) {
        let approve = true;
        if (metisBalance.isLessThan(form.amount)) {
            Modal({
                title: 'Sorry',
                text: `The current Metis Token balance ${metisBalance.toFixed(
                    3,
                )} on your account is not sufficient to support this execution. Please ensure your account has enough balance before proceeding`,
                confirmButtonProps: {
                    text: 'Deposit Metis Token',
                    onClick() {
                        window.open(BridgeUrl);
                    },
                },
            });
            return;
        }
        if (isNeedToApprove) {
            approve = await approveMetisTokenToMining();
        }
        if (approve) {
            nextFunc();
        }
    }

    async function getStatusOfIsNeedToApprove(amount: number) {
        const res = await isNeedToApproveMining(new BigNumber(amount));
        setIsNeedToApprove(res);
    }

    const handleChangeTabs = (
        _event: React.ChangeEvent<{}>,
        newValue: number,
    ) => {
        setTabIdx(newValue);
    };

    useEffect(() => {
        if (clientWithUserReady) {
            getStatusOfIsNeedToApprove(DEPOSIT_MAX);
        }
    }, [clientWithUserReady]);

    return (
        <>
            <section className={style.container}>
                <div className={style.dacFormTabs}>
                    <Tabs
                        onChange={handleChangeTabs}
                        value={tabIdx}
                        aria-label="Create DAC"
                    >
                        <Tab label="Claim a name" />
                        <Tab label="Configure DAC" />
                        <Tab label="Review Information" />
                    </Tabs>
                    <TabPanel value={tabIdx} index={0}>
                        <Formik
                            initialValues={{
                                photo: form.photo,
                                name: form.name,
                                introduction: form.introduction,
                            }}
                            validate={(values) => {
                                const errors: Partial<IDacInfo> = {};

                                if (!values.name) {
                                    errors.name =
                                        'Please enter no more than 30 words.';
                                } else if (!RE_DAC_NAME.test(values.name)) {
                                    errors.name = NAME_TIPS;
                                } else if (
                                    RE_DAC_METIS_NAMES.test(values.name)
                                ) {
                                    errors.name = NAME_METIS_TIP;
                                } else if (
                                    RE_DAC_DISABLE_NAMES.test(values.name)
                                ) {
                                    errors.name = NAME_ABUSIVE_TIP;
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                handleInputChange('name', values.name);
                                handleInputChange(
                                    'introduction',
                                    values.introduction,
                                );
                                setTabIdx(1);
                                setSubmitting(false);
                            }}
                        >
                            {({ submitForm }) => (
                                <Form
                                    className={style.dacForm}
                                    onSubmit={handleSubmit}
                                >
                                    <div className={style.dacPhotoControl}>
                                        <UploadImage
                                            onChange={uploadDacPhoto}
                                            className={style.dacPhoto}
                                            file={form.photo}
                                            accessToken={accessToken}
                                        />
                                        <div className={style.dacUploadTip}>
                                            Upload DAC Photo
                                            <Tooltip
                                                title={PHOTO_TIPS}
                                                arrow
                                                placement="bottom"
                                            >
                                                <span
                                                    className={style.labelHelp}
                                                    aria-label="help"
                                                >
                                                    <img
                                                        className={
                                                            style.iconHelp
                                                        }
                                                        src="/static/images/ic_question.png"
                                                        alt="icon question"
                                                    />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <FormControl
                                        fullWidth
                                        className={style.formControlItem}
                                    >
                                        <InputLabel htmlFor="name" shrink>
                                            DAC Name
                                            <Tooltip
                                                title={NAME_TIPS}
                                                arrow
                                                placement="top"
                                            >
                                                <span
                                                    className={style.labelHelp}
                                                    aria-label="help"
                                                >
                                                    <img
                                                        className={
                                                            style.iconHelp
                                                        }
                                                        src="/static/images/ic_question.png"
                                                        alt="icon question"
                                                    />
                                                </span>
                                            </Tooltip>
                                        </InputLabel>
                                        <Field
                                            component={TextField}
                                            name="name"
                                            autoFocus
                                            placeholder="DAC Name"
                                        />
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        className={style.formControlItem}
                                    >
                                        <InputLabel
                                            htmlFor="introduction"
                                            shrink
                                        >
                                            DAC Introduction
                                        </InputLabel>
                                        <Field
                                            component={TextField}
                                            name="introduction"
                                            multiline
                                            rows={4}
                                            placeholder="Add text"
                                        />
                                        <FormHelperText id="introduction-text">
                                            Users will review the DAC
                                            introduction when searching existing
                                            DAC and determine if to join the
                                            DAC. Please type no more than 150
                                            words.
                                        </FormHelperText>
                                    </FormControl>
                                    <div className={style.formActionBar}>
                                        <Button
                                            onClick={goHome}
                                            type="transparent"
                                            size="normal"
                                        >
                                            &lt; Back
                                        </Button>
                                        <Button onClick={submitForm} disabled>
                                            {/* Next: Configure DAC */}
                                            Closed
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </TabPanel>
                    <TabPanel value={tabIdx} index={1}>
                        <Formik
                            initialValues={{
                                amount: form.amount,
                            }}
                            validate={(_) => {
                                const errors: Partial<IDacInfo> = {};
                                if (form.amount) {
                                    errors.name = `Please enter between ${DEPOSIT_MIN} and ${DEPOSIT_MAX} Metis.`;
                                }
                            }}
                            onSubmit={(_, { setSubmitting }) => {
                                setSubmitting(false);
                                setTabIdx(2);
                            }}
                        >
                            {({ submitForm }) => (
                                <Form className={style.dacForm}>
                                    <h3 className={style.ownershipStakeTitle}>
                                        Ownership Stake
                                    </h3>
                                    <div className={style.metisToken}>
                                        <img
                                            className={style.polisPhoto}
                                            src="/static/images/logo.png"
                                            alt=""
                                        />
                                        <span className={style.metisTokenTitle}>
                                            Metis Token
                                        </span>
                                    </div>
                                    <Progress
                                        onChange={setMetisToken}
                                        value={form.amount}
                                    />
                                    <Row
                                        align="center"
                                        customClass={style.metisTokenTips}
                                    >
                                        <Text
                                            fontWeight={400}
                                            fontSize={12}
                                            lineHeight={'17px'}
                                            color={'rgba(255, 255, 255, 0.8)'}
                                        >
                                            Please stake a minimum of{' '}
                                            {DEPOSIT_MIN} Metis Token to claim
                                            the ownership
                                        </Text>
                                    </Row>
                                    <div className={style.metisConfiguration}>
                                        <Row
                                            customClass={style.rowItem}
                                            align="between"
                                        >
                                            <Title
                                                fontSize={18}
                                                fontWeight={600}
                                                lineHeight={'25px'}
                                            >
                                                How to dissolve my DAC?
                                            </Title>
                                        </Row>
                                        <Row
                                            customClass={style.rowItem}
                                            align="between"
                                        >
                                            <Text
                                                fontSize={12}
                                                lineHeight={'17px'}
                                            >
                                                We understand sometimes things
                                                could happen and you may wish to
                                                dissolve your DAC at one point.
                                                <br />
                                                You may dissolve DAC directly if
                                                you have fewer than{' '}
                                                {DAC_MEMBERS_MIN} members. For a
                                                DAC with more than{' '}
                                                {DAC_MEMBERS_MIN} members, you
                                                are not permitted to dissolve it
                                                directly at this time.
                                                <br />
                                                This restriction will be lifted
                                                once the Vote App is ready and
                                                you will be able to dissolve
                                                your DAC through majority
                                                voting.
                                            </Text>
                                        </Row>
                                    </div>
                                    <div className={style.formActionBar}>
                                        <Button
                                            type="transparent"
                                            size="normal"
                                            onClick={() => {
                                                goBack(0);
                                            }}
                                        >
                                            &lt; Back
                                        </Button>
                                        <Button
                                            hideLoading={false}
                                            onClick={async () => {
                                                await handleApprove(submitForm);
                                            }}
                                        >
                                            {isNeedToApprove
                                                ? 'Approve'
                                                : 'Next: Review Information'}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </TabPanel>
                    <TabPanel value={tabIdx} index={2}>
                        <form className={style.dacForm} action="#">
                            <Accordion
                                className={style.dacFormAccordion}
                                expanded={expanded === 'panel1'}
                                onChange={handleChangeAccordion('panel1')}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            className={style.expandMoreIcon}
                                            src="/static/images/ic_drop-down_s.png"
                                            alt=""
                                        />
                                    }
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.heading}>
                                        DAC Name
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={style.dacInfo}>
                                        <img
                                            className={style.dacInfoPhoto}
                                            src={
                                                form.photo ||
                                                '/static/images/logo.png'
                                            }
                                            alt=""
                                        />
                                        <div className={style.dacInfoDetail}>
                                            <div
                                                className={
                                                    style.dacInfoDetailItem
                                                }
                                            >
                                                <span
                                                    className={style.itemTitle}
                                                >
                                                    DAC Name
                                                </span>
                                                <span
                                                    className={style.itemValue}
                                                >
                                                    {form.name}
                                                </span>
                                            </div>
                                            <div
                                                className={
                                                    style.dacInfoDetailItem
                                                }
                                            >
                                                <span
                                                    className={style.itemTitle}
                                                >
                                                    DAC Introduction
                                                </span>
                                                <span
                                                    className={style.itemValue}
                                                >
                                                    {form.introduction}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                className={style.dacFormAccordion}
                                expanded={expanded === 'panel2'}
                                onChange={handleChangeAccordion('panel2')}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            className={style.expandMoreIcon}
                                            src="/static/images/ic_drop-down_s.png"
                                            alt=""
                                        />
                                    }
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography className={classes.heading}>
                                        Stake
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={style.metisTokenInfo}>
                                        <div
                                            className={style.metisTokenInfoItem}
                                        >
                                            <span className={style.itemTitle}>
                                                Ownership Stake
                                            </span>
                                            <span className={style.itemValue}>
                                                {form.amount}
                                            </span>
                                        </div>
                                        <div
                                            className={style.metisTokenInfoItem}
                                        >
                                            <span className={style.itemTitle}>
                                                <img
                                                    className={
                                                        style.metisTokenInfoPhoto
                                                    }
                                                    src="/static/images/logo.png"
                                                    alt=""
                                                />
                                            </span>
                                            <span className={style.itemValue}>
                                                Metis Token
                                            </span>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Row align="between" customClass={style.dacInfoTip}>
                                <Text
                                    fontWeight={400}
                                    fontSize={12}
                                    lineHeight={'17px'}
                                    color={'rgba(255, 255, 255, 0.3)'}
                                >
                                    Please review your settings carefully and go
                                    back to change it before launching your DAC.
                                    You won't be able to modify the DAC settings
                                    once it's launched.
                                </Text>
                            </Row>
                            <Row align="between" customClass={style.dacInfoTip}>
                                <Text
                                    fontWeight={400}
                                    fontSize={12}
                                    lineHeight={'17px'}
                                    color="main"
                                >
                                    Please notify that the DAC staking program
                                    is closed at Sat, 22 Jan 2022 07:00 (UTC).
                                    However, you are still welcomed to create
                                    your DACs, more functions and ecosystem
                                    projects are coming soon!
                                </Text>
                            </Row>
                            <div className={style.privacyPolicyItem}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAgree}
                                            onChange={handleChangeAgree}
                                            name="privacyPolicy"
                                            icon={<CheckBoxBorder />}
                                            checkedIcon={<CheckBox />}
                                        />
                                    }
                                    label="I have read and agreed to"
                                />
                                <Link
                                    href="/terms-of-service.html"
                                    target="_blank"
                                >
                                    Terms of Service
                                </Link>
                                <span>&</span>
                                <Link
                                    href="/privacy-policy.html"
                                    target="_blank"
                                >
                                    Privacy policy
                                </Link>
                            </div>
                            <div className={style.formActionBar}>
                                <Button
                                    type="transparent"
                                    size="normal"
                                    onClick={() => {
                                        goBack(1);
                                    }}
                                >
                                    &lt; Back
                                </Button>
                                <Button
                                    nativeType="button"
                                    onClick={handleSubmit}
                                    hideLoading={false}
                                    className={style.createButton}
                                >
                                    Next: Launch my DAC
                                </Button>
                            </div>
                        </form>
                    </TabPanel>
                </div>
                <DacCompany />
            </section>

            <ConnectAccountModal
                isOpen={isConnectAccount}
                onClose={handleCloseModal}
            />
        </>
    );
}
