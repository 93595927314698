import { useEffect, useState } from 'react'; // , useMemo
import { CONTRACT_DOMAIN, DOMAIN_CONFIG } from '@/constants';
import { useAppSelector } from '@/state/hooks';
import { TxActStatus } from '@/constants';
import BigNumber from 'bignumber.js';

export default function useGetVaultCaculateInfo() {
    const { client, chainId } = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );
    const user = useAppSelector((state) => state.user);
    // value === -1  => loading state
    const [totalShares, setTotalShares] = useState(-1);
    const [vaultBalance, setVaultBalance] = useState(new BigNumber(0));

    async function getTotalSharesFromVault() {
        if (user.address && client) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.VALUT].domain,
                    chainId,
                    'totalShares',
                    [],
                    true,
                );

                if (res && res.result) {
                    return new BigNumber(res.result).shiftedBy(-18);
                }
            } catch (e) {
                console.error(e);
                console.error('getUserWeightFromRecorder error');
            }
        }

        return new BigNumber(0);
    }

    async function getMetisBalanceOfVault() {
        if (user.address && client) {
            try {
                const vaultDomain = await client.getDomain(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.VALUT].domain,
                    `${chainId}`,
                );
                if (vaultDomain && vaultDomain.contract_address) {
                    const res = await client.sendTxAsync(
                        DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.METIS_TOKEN]
                            .domain,
                        chainId,
                        'balanceOf',
                        [vaultDomain.contract_address],
                        true,
                    );

                    if (res && res.result) {
                        return new BigNumber(res.result).shiftedBy(-18);
                    }
                }
            } catch (e) {
                console.error(e);
                console.error('getUserWeightFromRecorder error');
            }
        }

        return new BigNumber(0);
    }

    async function getTotalShares() {
        if (client) {
            const res = await getTotalSharesFromVault();
            setTotalShares(res.toNumber());
        }
    }

    async function getMetisBalanceOfVaultFunc() {
        if (client) {
            const res = await getMetisBalanceOfVault();
            setVaultBalance(res);
        }
    }

    useEffect(() => {
        let interval: any;
        if (client) {
            getTotalShares();
            getMetisBalanceOfVaultFunc();
            interval = setInterval(() => {
                getTotalShares();
                getMetisBalanceOfVaultFunc();
            }, 60000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [client]);

    return {
        totalShares,
        vaultBalance,
    };
}
