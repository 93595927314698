import React from 'react';
import Modal, { Props } from './modal';
import Row from '@/components/Row';
import Button from '@/components/Button';
import Text, { Title } from '@/components/Text';
import warningImg from '@/assets/image/warning.png';
import giftImg from '@/assets/image/gift.png';
import disbandedImg from '@/assets/image/disbanded.png';
import wrongImg from '@/assets/image/wrong.png';
import unhappyImg from '@/assets/image/unhappy.png';
import style from './index.module.scss';

export interface CustomProps extends Props {
    iconType?: 'warning' | 'gift' | 'disbanded' | 'wrong' | 'unhappy';
    title?: string;
    text?: string;
    confirmButtonProps?: {
        onClick:
            | ((event?: React.SyntheticEvent) => void)
            | ((event?: React.SyntheticEvent) => Promise<any>);
        text: string;
    };
    cancelButtonProps?: {
        text: string;
    };
    textAlign?: 'center' | 'left' | 'right';
    hideClose?: boolean;
    disableBackgroundClick?: boolean;
}

const ModalImg = {
    warning: warningImg,
    gift: giftImg,
    disbanded: disbandedImg,
    wrong: wrongImg,
    unhappy: unhappyImg,
};

export default function CustomModal({
    isOpen,
    iconType = 'warning',
    onClose,
    title,
    text,
    textAlign = 'center',
    confirmButtonProps = {
        text: 'OK',
        onClick: () => {},
    },
    cancelButtonProps,
    hideClose,
    disableBackgroundClick,
}: CustomProps) {
    const bg = ModalImg[iconType];
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            hideClose={hideClose}
            disableBackgroundClick={disableBackgroundClick}
        >
            {title && (
                <Row align="center">
                    <Title align="center" color="main" fontSize={24}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: title,
                            }}
                        />
                    </Title>
                </Row>
            )}
            <div className={style.customWrapper}>
                <Row lineHeight="none" customClass={style.imgContainer}>
                    {bg && <img src={bg} className={style.image} alt="" />}
                </Row>

                {text && (
                    <Row align="center">
                        <Text align={textAlign}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: text,
                                }}
                            />
                        </Text>
                    </Row>
                )}
                <div className={style.footer}>
                    {confirmButtonProps && (
                        <Row align="center">
                            <Button
                                className={style.button}
                                hideLoading={false}
                                onClick={async () => {
                                    await confirmButtonProps.onClick();
                                    onClose && onClose();
                                }}
                                size="middle"
                            >
                                {confirmButtonProps.text}
                            </Button>
                        </Row>
                    )}
                    {cancelButtonProps && (
                        <Row align="center">
                            <Button
                                className={style.button}
                                type="transparent"
                                onClick={() => {
                                    onClose && onClose();
                                }}
                                size="middle"
                            >
                                {cancelButtonProps.text}
                            </Button>
                        </Row>
                    )}
                </div>
            </div>
        </Modal>
    );
}
