import { ROLE } from '@/constants';
import Modal from '@/utils/components/modal';
export function joinDacFirst(role?: ROLE, dacName?: string) {
    if (!role || role === ROLE.VIEWER) {
        Modal({
            title: '',
            text: `Please join ${dacName} in order to proceed.`,
        });

        return false;
    }
    return true;
}
