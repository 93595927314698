import React, { useEffect } from 'react';
import { useAppSelector } from '@/state/hooks';
import { setIsOpenConnectModalHooks } from '@/state/user/hooks';

interface Props {
    children: React.ReactNode;
}

export default function AuthWrapper({ children }: Props) {
    const { connected, loadingUserInfo } = useAppSelector(
        (state) => state.user,
    );
    const setIsOpenConnectModal = setIsOpenConnectModalHooks();
    useEffect(() => {
        if (!connected && !loadingUserInfo) {
            setIsOpenConnectModal(true);
        } else if (loadingUserInfo) {
            setIsOpenConnectModal(false);
        }
    }, [connected, loadingUserInfo]);
    return <>{children}</>;
}
