import React from 'react';
import cx from 'classnames';
import style from './index.module.scss';
import styled from 'styled-components';

interface Props {
    // justify?: string;
    align?: 'center' | 'between';
    justify?: 'start' | 'center' | 'end';
    children?: React.ReactNode;
    customClass?: string;
    lineHeight?: 'normal' | 'small' | 'md' | 'none';
    style?: React.CSSProperties;
}

export default function Row({
    align,
    children,
    customClass,
    justify,
    lineHeight = 'normal',
    style: cssStyle,
}: Props) {
    return (
        <div
            className={cx(
                style.row,
                align === 'center' && style.alignCenter,
                align === 'between' && style.between,
                lineHeight === 'small' && style.small,
                lineHeight === 'md' && style.md,
                lineHeight === 'none' && style.none,
                justify && style[justify],
                customClass,
            )}
            style={cssStyle}
        >
            {children}
        </div>
    );
}

export const AutoRow = styled(Row)<{ gap?: string; justify?: string }>`
    flex-wrap: wrap;
    margin: ${({ gap }) => gap && `-${gap}`};
    justify-content: ${({ justify }) => justify && justify};

    & > * {
        margin: ${({ gap }) => gap} !important;
    }
`;
