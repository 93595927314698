import React from 'react';
import { useHistory } from 'react-router';

import RankingList from '@/components/RankingList';
import { Title } from '@/components/Text';
import Button from '@/components/Button';
import style from './index.module.scss';

export default function DacRankingList() {
    const history = useHistory();
    const goBack = () => {
        history.goBack();
    };
    return (
        <div className={style.wrapper}>
            <div className={style.rankingTitle}>
                <Title fontSize={24} lineHeight="40px">
                    DAC Ranking
                </Title>
                <Button
                    className={style.back}
                    type="transparent"
                    onClick={goBack}
                >
                    &lt; Back
                </Button>
            </div>
            <RankingList hidePaging={true} />
        </div>
    );
}
