import React, { useState } from 'react';
import { useHistory } from 'react-router';
import copy from 'copy-to-clipboard';
import {
    setIsOpenConnectModalHooks,
    clearUserLoginInfoHooks,
} from '@/state/user/hooks';

import Button from '@material-ui/core/Button';
import CustomButton from '@/components/Button';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import DisConnectAccountModal from '@/components/DisConnectAccountModal';
import SettingsModal from '@/components/SettingsModal';
// import { ROLE } from '@/constants';
import { CHAIN_ENV, NETWORK_NAME } from '@/constants';

import { useAppSelector } from '@/state/hooks';

import style from './index.module.scss';
import RouterConfig from '@/constants/router';
import Text from '@/components/Text';

export default function Header() {
    const { chainId } = useAppSelector((state) => state.metisMiddlewareClient);
    const history = useHistory();
    const user = useAppSelector((state) => state.user);
    const clearUserLoginInfo = clearUserLoginInfoHooks();
    const setIsOpenConnectModal = setIsOpenConnectModalHooks();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [isDisConnectAuthVisible, setIsDisConnectAuthVisible] = useState(
        false,
    );
    const [isSettingVisible, setSettingVisible] = useState(false);
    const [isTipVisible, setIsTipVisible] = React.useState(false);

    const openPopover = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopover = () => {
        setIsTipVisible(false);
        setAnchorEl(null);
    };

    function openConnectAccountModal() {
        setIsOpenConnectModal(true);
    }

    function openDisConnectAccountModal() {
        setIsDisConnectAuthVisible(true);
        setAnchorEl(null);
    }

    function disConnect() {
        clearUserLoginInfo();
    }

    function cancelDisConnectAccountModal() {
        setIsDisConnectAuthVisible(false);
        setAnchorEl(null);
    }

    const copyAddress = () => {
        if (user.connected && user.address) {
            copy(user.address);
            setIsTipVisible(true);
        }
    };

    const closeTooltip = () => {
        setIsTipVisible(false);
    };

    const openSettingsModal = () => {
        setSettingVisible(true);
    };

    function closeSettingsModal() {
        setSettingVisible(false);
    }

    const showUserName = (username: any) => {
        let isUserNameByMM = username.substr(0, 2);
        if (isUserNameByMM === '0x' && username.length >= 20) {
            let firstUserName = username.substr(0, 5);
            let lastUserName = username.substr(-4, 4);
            return firstUserName + '...' + lastUserName;
        } else {
            return username;
        }
    };

    return (
        <header className={style.headerWrapper}>
            <div
                className={style.logo}
                onClick={() => {
                    history.push('/');
                }}
            >
                <img src="/static/images/logo-metis.png" alt="Portal" />
            </div>

            {!user.connected && (
                <span
                    className={style.connectBtn}
                    onClick={openConnectAccountModal}
                >
                    Connect Account
                </span>
            )}

            {user.connected && (
                <div className={style.userInfoContainer}>
                    <Tooltip
                        title="All mining rewards will be saved to Vault when you Claim. You may visit Vault to withdraw at any time."
                        arrow
                        placement="bottom"
                    >
                        <CustomButton
                            className={style.vault}
                            type="transparent"
                            onClick={() => {
                                history.push(RouterConfig.getVault());
                            }}
                        >
                            <img
                                className={style.icon}
                                src={require('@/assets/image/vault-white.png')}
                                alt="vault"
                            />
                            <Text
                                color="#fff"
                                fontSize={16}
                                style={{ marginLeft: '4px' }}
                            >
                                Vault
                            </Text>
                        </CustomButton>
                    </Tooltip>

                    <div
                        className={style.userInfo}
                        aria-describedby={id}
                        onClick={(event) => {
                            openPopover(event);
                        }}
                    >
                        <span className={style.userInfoItem}>
                            {showUserName(user.name)}
                        </span>
                    </div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={closePopover}
                        className={style.polisPopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className={style.polisPopoverInfo}>
                            <h3 className={style.polisTitle}>Polis</h3>
                            <div className={style.polisAddressBar}>
                                <span className={style.polisAddress}>
                                    {user.address}
                                </span>

                                <ClickAwayListener onClickAway={closeTooltip}>
                                    <Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        onClose={closeTooltip}
                                        arrow
                                        open={isTipVisible}
                                        placement="top"
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        interactive
                                        title="You have copied your address successfully"
                                    >
                                        <Button
                                            className={style.copyBtn}
                                            onClick={() => {
                                                copyAddress();
                                            }}
                                        >
                                            <img
                                                src="/static/images/ic_copy.png"
                                                alt="close"
                                            />
                                        </Button>
                                    </Tooltip>
                                </ClickAwayListener>
                            </div>
                            <div className={style.metisTestnetTips}>
                                YOU ARE CONNECTED TO{' '}
                                {NETWORK_NAME[CHAIN_ENV[chainId]]}
                            </div>
                            <Button
                                className={style.disconnectBtn}
                                onClick={() => {
                                    openDisConnectAccountModal();
                                }}
                            >
                                Disconnect from Polis
                            </Button>
                        </div>
                    </Popover>
                    <span
                        className={style.settingsBtn}
                        onClick={openSettingsModal}
                    >
                        <img
                            className={style.settingsIcon}
                            src="/static/images/ic_setup.png"
                            alt="setting"
                        />
                    </span>
                </div>
            )}

            <DisConnectAccountModal
                isOpen={isDisConnectAuthVisible}
                onClose={cancelDisConnectAccountModal}
                onConfirm={disConnect}
            />

            <SettingsModal
                isOpen={isSettingVisible}
                onClose={closeSettingsModal}
            />
        </header>
    );
}
