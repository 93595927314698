import { useEffect, useMemo, useState } from 'react';
import { CONTRACT_DOMAIN, DOMAIN_CONFIG } from '@/constants';
import { useAppSelector } from '@/state/hooks';
import { TxActStatus } from '@/constants';
import BigNumber from 'bignumber.js';
import getDacOverview from '@/graphql/get-dacoverview';

export default function useGetMiningCalculateInfo() {
    const { client, chainId } = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );
    // value === -1  => loading state
    const user = useAppSelector((state) => state.user);
    const [userShareWeight, setShareWeight] = useState<number>(-1);
    const [tvl, setTvl] = useState<number>(-1);
    const [metisPerSecond, setMetisPerSecond] = useState<BigNumber>(
        new BigNumber(0),
    );
    const [totalReward, setTotalReward] = useState(-1);
    const [totalWeight, setTotalWeight] = useState(new BigNumber(0));
    const [userPower, setUserPower] = useState(80);

    async function getUserWeightFromRecorder() {
        if (user.address && client) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC_RECORDER].domain,
                    chainId,
                    'userWeight',
                    [user.address],
                    true,
                );

                if (res && res.result) {
                    return new BigNumber(res.result).shiftedBy(-18);
                }
            } catch (e) {
                console.error(e);
                console.error('getUserWeightFromRecorder error');
            }
        }

        return new BigNumber(0);
    }

    async function getTotalWeightFromRecorder() {
        if (client) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC_RECORDER].domain,
                    chainId,
                    'totalWeight',
                    [],
                    true,
                );

                if (res && res.result) {
                    return new BigNumber(res.result).shiftedBy(-18);
                }
            } catch (e) {
                console.error(e);
                console.error('getTotalWeightFromRecorder error');
            }
        }

        return new BigNumber(0);
    }

    async function getUserAccPower() {
        if (client && user.address) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC_RECORDER].domain,
                    chainId,
                    'getUserAccPower',
                    [user.address],
                    true,
                );

                if (res && res.result) {
                    return Number(res.result);
                }
            } catch (e) {
                console.error(e);
                console.error('getTotalWeightFromRecorder error');
            }
        }

        return 0;
    }

    async function getStakedMetisFromRecorder() {
        if (client) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC_RECORDER].domain,
                    chainId,
                    'stakedMetis',
                    [],
                    true,
                );

                if (res && res.result) {
                    return new BigNumber(res.result).shiftedBy(-18);
                }
            } catch (e) {
                console.error(e);
                console.error('getStakedMetisFromRecorder error');
            }
        }

        return new BigNumber(0);
    }

    async function getMetisPerSecondFromMining() {
        if (client) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
                    chainId,
                    'MetisPerSecond',
                    [],
                    true,
                );

                if (res && res.result) {
                    return new BigNumber(res.result).shiftedBy(-18);
                }
            } catch (e) {
                console.error(e);
                console.error('getMetisPerSecondFromMining error');
            }
        }

        return new BigNumber(0);
    }

    async function getMetisBalanceForRecorder() {
        if (client) {
            try {
                const recorderDomain = await client.getDomain(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC_RECORDER].domain,
                    `${chainId}`,
                );

                if (recorderDomain && recorderDomain.contract_address) {
                    const res = await client.sendTxAsync(
                        DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.METIS_TOKEN]
                            .domain,
                        chainId,
                        'balanceOf',
                        [recorderDomain.contract_address],
                        true,
                    );

                    if (res && res.result) {
                        return new BigNumber(res.result).shiftedBy(-18);
                    }
                }
            } catch (e) {
                console.error(e);
                console.error('getMetisBalanceForRecorder error');
            }
        }

        return new BigNumber(0);
    }

    async function getWeight() {
        const userWeight = await getUserWeightFromRecorder();
        const totalWeight = await getTotalWeightFromRecorder();
        setTotalWeight(totalWeight);

        if (userWeight.toNumber() && totalWeight.toNumber()) {
            setShareWeight(
                userWeight.dividedBy(totalWeight).multipliedBy(100).toNumber(),
            );
        }
    }

    async function getPoolTVL() {
        const res = await getStakedMetisFromRecorder();
        setTvl(res.toNumber());
    }

    async function getPoolApy() {
        const res = await getMetisPerSecondFromMining();
        setMetisPerSecond(res);
    }

    async function getTotalReward() {
        const res = await getDacOverview();
        // const res = await getMetisBalanceForRecorder();
        setTotalReward(res.totalMint);
    }

    async function getUserPower() {
        const res = await getUserAccPower();
        setUserPower(res);
    }

    function getDataWhenClientReady() {
        if (client) {
            getPoolTVL();
            getPoolApy();
            getTotalReward();
        }
    }

    useEffect(() => {
        if (client && user.address) {
            getWeight();
            getUserPower();
        }
    }, [client, user.address]);

    useEffect(() => {
        let interval: any;
        if (client) {
            getDataWhenClientReady();
            interval = setInterval(() => {
                getDataWhenClientReady();
            }, 60000);
        }

        return () => {
            if (client) {
                clearInterval(interval);
            }
        };
    }, [client]);

    const apy = useMemo(() => {
        if (tvl > 0) {
            return new BigNumber(
                metisPerSecond
                    .multipliedBy(86400 * 365)
                    .dividedBy(tvl)
                    .multipliedBy(100)
                    .toFixed(3),
            ).toNumber();
        }

        return -1;
    }, [tvl, metisPerSecond]);

    const rewardPerDay = useMemo(() => {
        if (metisPerSecond.toNumber()) {
            return metisPerSecond.multipliedBy(86400).toNumber();
        }
        return -1;
    }, [metisPerSecond]);

    return {
        userShareWeight,
        tvl,
        apy,
        rewardPerDay,
        totalReward,
        updateWeight: getWeight,
        totalWeight,
        userPower,
    };
}
