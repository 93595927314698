import React, { ChangeEvent, useMemo, useState } from 'react';
import AuthWrapper from '@/components/AuthWrapper';
import style from './index.module.scss';
import PageHeader from '@/components/PageHeader';
import Row from '@/components/Row';
import Text, { ThirdTitle, Title, Error } from '@/components/Text';
import DacAside from '@/components/DacAside';
import Button from '@/components/Button';
import Copy from '@/components/Copy';
import { DAC_MEMBERS_MIN, DEPOSIT_MAX, DEPOSIT_MIN, ROLE } from '@/constants';
import { useAppSelector } from '@/state/hooks';
// import Modal from '@/utils/components/modal';
// import { useHistory } from 'react-router-dom';
import createDacShortLink from '@/graphql/create-dac-shortlink';
import Alert from '@/utils/components/alert';
import Modal from '@/utils/components/modal';
import Loading from '@/components/Loading';
import copy from 'copy-to-clipboard';
import { updateDacLinkByDacId } from '@/state/dac/hooks';

export default function DACManagement() {
    const {
        dac,
        dacLink,
        dacLinkLoading,
        shareLinkCode,
        loadingDac,
    } = useAppSelector((state) => state.dac);
    const { loadingDac: loadingUserDac } = useAppSelector(
        (state) => state.user,
    );
    const [inputLink, setInputLink] = useState('');
    // const history = useHistory();
    const updateStateLinkById = updateDacLinkByDacId();
    // const [hasShowAuth, setHasShowAuth] = useState(false);

    // useEffect(() => {
    //     console.log(userDac, dac);
    //     console.log(loadingDac, loadingUserDac);
    //     if (
    //         !hasShowAuth &&
    //         !loadingUserDac &&
    //         !loadingDac &&
    //         (!userDac || (userDac && dac && dac.id !== userDac.id))
    //     ) {
    //         Modal({
    //             iconType: 'unhappy',
    //             text: 'Only creator can view this manage page!',
    //             onClose() {
    //                 history.push('/');
    //                 setHasShowAuth(false);
    //             },
    //         });
    //         setHasShowAuth(true);
    //     }
    // }, [dac, loadingUserDac, userDac, loadingDac, hasShowAuth]);

    function handleInput(e: ChangeEvent<HTMLInputElement>) {
        setInputLink(e.currentTarget.value);
    }

    const errorMessage = useMemo(() => {
        if (inputLink) {
            const valid = /^[_0-9a-z]{1,30}$/.test(inputLink);
            if (!valid) {
                return 'URL can contain letters (a-z), numbers (0-9), dashes (-), underscores (_). Up to 30 characters';
            }
        }
        return '';
    }, [inputLink]);

    async function createLink() {
        try {
            if (dac && inputLink) {
                const res = await createDacShortLink({
                    id: dac.id,
                    link: inputLink,
                });
                if (res.success) {
                    await updateStateLinkById(dac.id);

                    Alert({
                        type: 'success',
                        text: 'Created successfully',
                    });
                } else {
                    Modal({
                        iconType: 'warning',
                        text:
                            res.msg ||
                            'Something wrong, please try again later.',
                    });
                }
            }
        } catch (e: any) {
            console.error(e);
        }
    }

    function hanedleCpoy() {
        if (shareLinkCode) {
            copy(`${window.location.origin}/invite/${shareLinkCode}`);
            Alert({
                type: 'success',
                text: 'Copied!',
            });
        }
    }

    function renderContent() {
        if (dacLinkLoading || loadingDac || loadingUserDac) {
            return (
                <div className={style.loading}>
                    <Loading />
                </div>
            );
        }

        return !dacLink ? (
            <>
                <Row>
                    <Title fontSize={24}>Subdirectory</Title>
                </Row>
                <div className={style.subdirectory}>
                    <Row lineHeight="none">
                        <ThirdTitle>
                            As a DAC Owner, you can determine your DAC unique
                            subdirectory. Portal will create your DAC url base
                            on the subdirectory you provide.
                        </ThirdTitle>
                    </Row>
                    <Row>
                        <ThirdTitle color="main">
                            Please note that you won’t be able to modify the
                            subdirectory after it’s set.
                        </ThirdTitle>
                    </Row>
                    <Row>
                        <span
                            className={style.prefix}
                        >{`${window.location.origin}/dac/`}</span>
                        <input
                            type="text"
                            className={style.linkInput}
                            autoFocus
                            onChange={handleInput}
                        />
                        <Button
                            disabled={!inputLink || !!errorMessage}
                            onClick={createLink}
                            hideLoading={false}
                        >
                            Confirm
                        </Button>
                    </Row>
                    {errorMessage && (
                        <Row>
                            <Error fontSize={16}>{errorMessage}</Error>
                        </Row>
                    )}
                </div>
            </>
        ) : (
            <>
                <Row>
                    <Title fontSize={24}>DAC Member Invitation</Title>
                </Row>
                <div className={style.invitation}>
                    <Row lineHeight="none">
                        <ThirdTitle fontSize={16}>
                            Invite people you know to participate in DAC mining
                            and increase the mining power.
                        </ThirdTitle>
                    </Row>
                    <Row>
                        <ThirdTitle color="main" fontSize={16}>
                            Share the link or invitation code to your friend so
                            they can join your DAC
                        </ThirdTitle>
                    </Row>
                    <div className={style.options}>
                        <div className={style.item}>
                            <Row lineHeight="none">
                                <ThirdTitle fontSize={16}>Option 1</ThirdTitle>
                            </Row>
                            <Row>
                                <ThirdTitle fontSize={16}>
                                    Direct link to join DAC
                                </ThirdTitle>
                            </Row>
                            <Button
                                className={style.copyLink}
                                onClick={hanedleCpoy}
                            >
                                Copy Link
                            </Button>
                        </div>
                        <div className={style.line} />
                        <div className={style.item}>
                            <Row lineHeight="none" align="center">
                                <ThirdTitle fontSize={16} align="center">
                                    Option 2
                                </ThirdTitle>
                            </Row>
                            <Row align="center">
                                <ThirdTitle fontSize={16} align="center">
                                    Search DAC and enter the code to join
                                </ThirdTitle>
                            </Row>
                            <Row align="center" customClass={style.inviteCode}>
                                <Copy
                                    label={'Inviter code：'}
                                    code={dac ? dac.invitationCode : '--'}
                                />
                            </Row>
                        </div>
                    </div>
                    <Row customClass={style.info}>
                        <Text fontSize={14}>
                            Your DAC mining power will increase by 5-10 for
                            every additional DAC member who stakes {DEPOSIT_MIN}
                            -{DEPOSIT_MAX} tokens.
                        </Text>
                    </Row>
                    <Row>
                        <Text fontSize={14}>
                            Please note you will need to keep the number of DAC
                            Members to be fewer than {DAC_MEMBERS_MIN} before
                            you are allowed to dissolve your DAC.
                        </Text>
                    </Row>
                </div>
            </>
        );
    }

    return (
        <AuthWrapper>
            <PageHeader pageTitle="Management" />
            <div className={style.wrapper}>{renderContent()}</div>
            <DacAside />
        </AuthWrapper>
    );
}
