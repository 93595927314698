import { Tooltip } from '@material-ui/core';
import React from 'react';
import style from './index.module.scss';
import cx from 'classnames';

interface Props {
    text?: string | React.ReactElement;
    style?: React.CSSProperties;
    className?: string;
    placement?: 'top' | 'bottom';
}

export default function TooltipInfo({
    text = '',
    style: cssStyle,
    className,
    placement = 'top',
}: Props) {
    return (
        <Tooltip title={text} arrow placement={placement}>
            <span
                className={cx(style.labelHelp, className)}
                aria-label="help"
                style={cssStyle}
            >
                <img
                    className={style.iconHelp}
                    src={'/static/images/ic_question.png'}
                    alt="icon question"
                />
            </span>
        </Tooltip>
    );
}
