import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '@/components/Modal';
import style from './index.module.scss';
import Row from '@/components/Row';
import Text, { SecondTitle, Warning, Error } from '@/components/Text'; // ThirdTitle,
import cx from 'classnames';
import Button from '@/components/Button';
import { Checkbox, FormControlLabel, Link } from '@material-ui/core';
import RefreshImg from '@/assets/image/refresh.png';
import SuccessImg from '@/assets/image/success.png';
// import UpToLimit from '@/assets/image/upToLimit.png';
import WarningImg from '@/assets/image/warning.png';
import BigNumber from 'bignumber.js';
import { useAppSelector } from '@/state/hooks';
import {
    BridgeUrl,
    DEPOSIT_MAX,
    DEPOSIT_MIN,
    REMAINING_GAS,
    ROLE,
} from '@/constants';
import useMiddlewareClient from '@/hooks/use-middleware-client';
import { updateMetisBalance } from '@/state/user/hooks';
import useDacContract from '@/hooks/use-dac-hooks';
import ModalFunc from '@/utils/components/modal';
import { useHistory } from 'react-router-dom';
import RouterConfig from '@/constants/router';
import CheckBox from '@/components/CheckBox';
import CheckBoxBorder from '@/components/CheckBoxBorder';

export interface Props {
    isOpen: boolean;
    onClose(): void;
    successContent?: string | React.ReactNode;
    successButtonProps?: {
        text: string;
        onClick(): void;
    };
    type?: 'create' | 'join' | 'increase';
    userType?: ROLE;
    defaultStakeAmount?: number;
    dacData?: {
        id: string | number;
        name: string;
        addon?: string | number;
        // apy: number;
        creator: string;
    };
    onSuccess?(): void;
    tvl?: BigNumber;
    defaultInvitationCode?: string;
    dacLink?: string;
}

export default function DepositAndWithdrawModal({
    isOpen,
    onClose,
    type = 'join',
    dacData = {
        id: '',
        name: 'Unknow',
        // apy: 0,
        creator: '',
    },
    userType = ROLE.VIEWER,
    onSuccess,
    defaultStakeAmount = DEPOSIT_MIN,
    tvl = new BigNumber(0),
    defaultInvitationCode,
    dacLink,
}: Props) {
    const history = useHistory();
    const {
        approveMetisTokenToMining,
        miningDeposit,
        clientWithUserReady,
        isNeedToApproveMining,
        estimateDepositGasFee,
    } = useMiddlewareClient();
    const { joinDAC, estimateJoinDacGasFee } = useDacContract();
    const { metisBalance } = useAppSelector((state) => state.user);
    const updateMetisBalanceFunc = updateMetisBalance();
    const [inviteCode, setInviteCode] = useState('');
    const [inviteCodeError, setInviteCodeError] = useState('');

    const [agree, setAgree] = useState(true);
    const [step, setStep] = useState('init');
    const [alertMessage, setAlertMessage] = useState('');
    const [inputAmount, setInputAmount] = useState(new BigNumber(0));
    const [isNeedToApprove, setIsNeedToApprove] = useState(true);

    const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgree(event.target.checked);
    };

    const isTransPage = useMemo(() => {
        return step === 'trans';
    }, [step]);

    const balanceInputEnable = useMemo(() => {
        return type !== 'create';
    }, [type]);

    useEffect(() => {
        if (defaultStakeAmount && type === 'join') {
            setInputAmount(new BigNumber(defaultStakeAmount));
        }
    }, [defaultStakeAmount, type]);

    const handleMax = useCallback(() => {
        if (metisBalance.isLessThanOrEqualTo(REMAINING_GAS)) {
            ModalFunc({
                title: 'Sorry',
                text: `The current Metis Token balance ${metisBalance.toFixed(
                    3,
                )} on your account is not sufficient to support next execution. Please leave at lease ${REMAINING_GAS} METIS to support next execution`,
                confirmButtonProps: {
                    text: 'Bridge Metis Token',
                    onClick() {
                        window.open(BridgeUrl);
                    },
                },
                cancelButtonProps: {
                    text: 'Cancel',
                },
            });
            return;
        }
        if (metisBalance.minus(REMAINING_GAS).plus(tvl).isGreaterThan(2000)) {
            setInputAmount(new BigNumber(DEPOSIT_MAX).minus(tvl));
        } else {
            setInputAmount(metisBalance.minus(REMAINING_GAS));
        }
    }, [metisBalance, setInputAmount, tvl]);

    useEffect(() => {
        setInviteCode(defaultInvitationCode || '');
    }, [defaultInvitationCode]);

    const inputErrorMessage = useMemo(() => {
        if (inputAmount.isGreaterThan(metisBalance)) {
            return 'Insufficient balance';
        }

        if (inputAmount.plus(tvl).isGreaterThan(DEPOSIT_MAX)) {
            return `The maximum of TVL allowed is ${DEPOSIT_MAX}, you may add ${new BigNumber(
                DEPOSIT_MAX,
            )
                .minus(tvl)
                .toNumber()} more Metis tokens before reaching the limitation`;
        }

        if (inputAmount.plus(tvl).isLessThan(DEPOSIT_MIN)) {
            return `The minimum Total value locked (TVL) required is  ${DEPOSIT_MIN}`;
        }

        return '';
    }, [metisBalance, tvl, inputAmount]);

    useEffect(() => {
        if (tvl.isEqualTo(DEPOSIT_MAX)) {
            setStep('alert');
            setAlertMessage(
                `Your Total Value Locked (TVL) has reached the maximum limitation of ${DEPOSIT_MAX}`,
            );
        }
    }, [tvl]);

    function needToLeaveGas(fee: BigNumber) {
        if (inputAmount.plus(fee).isGreaterThan(metisBalance)) {
            ModalFunc({
                iconType: 'warning',
                text: `Please leave at least ${fee.toFixed()} Metis Token as the Layer2 gas cost.`,
            });
            return true;
        }
        return false;
    }

    const handleConfirmClick = async () => {
        if (isNeedToApprove) {
            if (type !== 'increase' && !inviteCode) {
                setInviteCodeError('Invalid invitation code');
                return;
            }
            const success = await approveMetisTokenToMining(
                inputAmount.toNumber(),
            );
            if (success) {
                setIsNeedToApprove(false);
            }
        } else {
            let success = false;
            if (type !== 'increase') {
                if (!inviteCode) {
                    setInviteCodeError('Invalid invitation code');
                    return;
                }
                const res = await estimateJoinDacGasFee(
                    Number(dacData.id),
                    inputAmount,
                    inviteCode,
                );
                if (needToLeaveGas(res)) {
                    return;
                }
                success = await joinDAC(
                    Number(dacData.id),
                    inputAmount,
                    inviteCode,
                );
            } else {
                const res = await estimateDepositGasFee(
                    inputAmount,
                    userType,
                    dacData.id,
                    dacData.creator,
                );
                if (needToLeaveGas(res)) {
                    return;
                }
                success = await miningDeposit(
                    inputAmount,
                    userType,
                    dacData.id,
                    dacData.creator,
                );
            }

            if (success) {
                setStep('success');
                if (onSuccess) {
                    onSuccess();
                }
                updateMetisBalanceFunc();
            } else {
                ModalFunc({
                    iconType: 'warning',
                    text: 'Transaction failed, please try again later',
                });
            }
        }
    };
    const handleModalClose = useCallback(() => {
        setStep('init');
        setInputAmount(new BigNumber(0));
        setAlertMessage('');
        onClose();
    }, [onClose]);

    async function getStatusOfIsNeedToApprove(amount: number) {
        const res = await isNeedToApproveMining(new BigNumber(amount));
        setIsNeedToApprove(res);
    }

    useEffect(() => {
        if (clientWithUserReady) {
            getStatusOfIsNeedToApprove(DEPOSIT_MAX);
        }
    }, [clientWithUserReady]);

    if (userType === ROLE.VIEWER && type !== 'join') return null;

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleModalClose}
            hideClose={isTransPage}
            disableBackgroundClick={isTransPage}
        >
            <div className={style.wrapper}>
                {step === 'init' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                {type === 'join'
                                    ? 'Join DAC'
                                    : 'Metis DAC Mining'}
                            </SecondTitle>
                        </Row>
                        <div className={style.content}>
                            {!balanceInputEnable && (
                                <>
                                    <Row>
                                        <Text fontSize={16} color="main">
                                            My DAC
                                        </Text>
                                    </Row>
                                    <div className={style.block}>
                                        <Text>{dacData.name}</Text>
                                    </div>
                                </>
                            )}

                            <Row>
                                <Text fontSize={16} color="main">
                                    Stake Metis Token on Layer 2
                                </Text>
                            </Row>
                            <div className={style.inputWrapper}>
                                <div className={cx(style.block, style.input)}>
                                    <img
                                        src="/metis.svg"
                                        className={style.logo}
                                        alt="Metis"
                                    />
                                    <input
                                        placeholder={'please enter amount'}
                                        value={
                                            inputAmount.toNumber()
                                                ? inputAmount.toNumber()
                                                : undefined
                                        }
                                        type="number"
                                        autoFocus
                                        onChange={(e) => {
                                            setInputAmount(
                                                new BigNumber(
                                                    e.currentTarget.value || 0,
                                                ),
                                            );
                                        }}
                                        disabled={!balanceInputEnable}
                                    />
                                    {balanceInputEnable && (
                                        <div className={style.balance}>
                                            <Text color="#ccc">Balance: </Text>
                                            <Text>
                                                {metisBalance.toFixed(3)}
                                            </Text>
                                        </div>
                                    )}
                                </div>
                                {/* {balanceInputEnable && (
                                    <Button
                                        className={style.max}
                                        onClick={handleMax}
                                    >
                                        MAX
                                    </Button>
                                )} */}
                            </div>
                            {inputErrorMessage && (
                                <Row align="between">
                                    {<Error>{inputErrorMessage}</Error>}
                                    {/* <Warning>
                                        *The range of staked tokens
                                        paryicipating in mining is 100~2000.
                                    </Warning> */}
                                </Row>
                            )}
                            {balanceInputEnable && (
                                <Row align="between">
                                    <span></span>
                                    {
                                        <Text>
                                            <a href={BridgeUrl} target="_blank">
                                                Bridge Additional Metis Token
                                            </a>
                                        </Text>
                                    }
                                    {/* <Warning>
                                        *The range of staked tokens
                                        paryicipating in mining is 100~2000.
                                    </Warning> */}
                                </Row>
                            )}

                            {type === 'join' && (
                                <>
                                    <Row>
                                        <Text fontSize={16} color="main">
                                            Invitation code
                                        </Text>
                                    </Row>
                                    <div className={style.inputWrapper}>
                                        <div
                                            className={cx(
                                                style.block,
                                                style.input,
                                            )}
                                        >
                                            <input
                                                placeholder={
                                                    'Please enter invitation code'
                                                }
                                                value={inviteCode}
                                                onChange={(e) => {
                                                    const value =
                                                        e.currentTarget.value;
                                                    if (
                                                        value &&
                                                        inviteCodeError
                                                    ) {
                                                        setInviteCodeError('');
                                                    }
                                                    setInviteCode(value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {!inviteCode && inviteCodeError && (
                                        <Row align="between">
                                            {<Error>{inviteCodeError}</Error>}
                                        </Row>
                                    )}
                                </>
                            )}

                            <div className={cx(style.block, style.detail)}>
                                <Row align="between" lineHeight="none">
                                    <Text>DAC: </Text>
                                    <Text>{dacData.name}</Text>
                                </Row>
                                {/* <Row align="between">
                                    <Text>Basic Mining Power：</Text>
                                    <Text>{dacData.power}</Text>
                                </Row> */}
                                {type === 'create' && (
                                    <>
                                        {dacData.addon && (
                                            <Row align="between">
                                                <Text color="main">
                                                    Mining Power addon：
                                                </Text>
                                                <Text color="main">
                                                    +{dacData.addon}%
                                                </Text>
                                            </Row>
                                        )}

                                        {/* <Row align="between">
                                            <Text>Pool APY：</Text>
                                            <Text>{dacData.apy}%</Text>
                                        </Row> */}
                                    </>
                                )}
                            </div>
                            <div className={style.agreement}>
                                {type === 'join' && (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={agree}
                                                    onChange={handleAgreeChange}
                                                    name="privacyPolicy"
                                                    icon={<CheckBoxBorder />}
                                                    checkedIcon={<CheckBox />}
                                                />
                                            }
                                            label="I have read and agreed to"
                                        />
                                        <Link
                                            href="/terms-of-service.html"
                                            target="_blank"
                                        >
                                            Terms of Service
                                        </Link>
                                        <span>&</span>
                                        <Link
                                            href="/privacy-policy.html"
                                            target="_blank"
                                        >
                                            Privacy policy
                                        </Link>
                                    </>
                                )}
                                {type !== 'join' && (
                                    <Text color="#ccc" lineHeight="18px">
                                        The system will automatically deposit
                                        your reward to the Vault when you
                                        increase the TVL.
                                    </Text>
                                )}
                            </div>
                            <div className={style.footer}>
                                <Row align="center">
                                    <Button
                                        className={style.button}
                                        disabled={
                                            !inputAmount.toNumber() ||
                                            !!inputErrorMessage ||
                                            !agree
                                        }
                                        hideLoading={false}
                                        onClick={handleConfirmClick}
                                    >
                                        {isNeedToApprove
                                            ? 'Approve'
                                            : 'Confirm'}
                                    </Button>
                                </Row>
                            </div>
                        </div>
                    </>
                )}
                {step === 'trans' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                Transaction
                            </SecondTitle>
                        </Row>
                        <Row align="center">
                            <img
                                src={RefreshImg}
                                className={style.transLoading}
                                alt=""
                            />
                        </Row>
                        <Row align="center">
                            <SecondTitle color="main">Transacting…</SecondTitle>
                        </Row>
                    </>
                )}
                {step === 'success' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                Succeed!
                            </SecondTitle>
                        </Row>
                        <Row align="center">
                            <img
                                src={SuccessImg}
                                className={style.successIcon}
                                alt=""
                            />
                        </Row>
                        {type === 'create' && (
                            <>
                                <Row align="center">
                                    <Text color="#fff" fontSize={16}>
                                        You have participated in the DAC Mining
                                    </Text>
                                </Row>
                                <Row align="center">
                                    <Text
                                        color="#fff"
                                        fontSize={16}
                                        align="center"
                                    >
                                        Invite more friednds to participate in
                                        DAC mining to increase mining power？
                                    </Text>
                                </Row>
                                <Row align="center">
                                    <Warning fontSize={16} align="center">
                                        *Invte 1 friend to stake 100～2000 token
                                        will increase 10%～200% mining Power
                                    </Warning>
                                </Row>
                                <div className={style.footer}>
                                    <Row align="center">
                                        <Button className={style.button}>
                                            Copy Link
                                        </Button>
                                    </Row>
                                </div>
                            </>
                        )}
                        {(type === 'join' || type === 'increase') && (
                            <>
                                <Row align="center">
                                    <Text color="#fff" fontSize={16}>
                                        {type === 'join'
                                            ? `You have invested in the ${dacData.name}`
                                            : `The number of Metis tokens you staked has increased successfully. `}
                                    </Text>
                                </Row>
                                <div className={cx(style.block, style.detail)}>
                                    <Row align="between" lineHeight="none">
                                        <Text>DAC: </Text>
                                        <Text>{dacData.name}</Text>
                                    </Row>
                                    <Row align="between">
                                        <Text>TVL:</Text>
                                        <Text>
                                            {type === 'join'
                                                ? inputAmount.toFixed(2)
                                                : tvl.toFixed(2)}{' '}
                                            Metis Token
                                        </Text>
                                    </Row>
                                    {/* <Row align="between">
                                        <Text>Basic Mining Power：:</Text>
                                        <Text>{dacData.power}</Text>
                                    </Row> */}
                                </div>
                                <div className={style.footer}>
                                    {type === 'join' && dacLink && (
                                        <Row align="center">
                                            <Button
                                                className={style.button}
                                                type="primary"
                                                onClick={() => {
                                                    history.push(
                                                        RouterConfig.getDacPool(
                                                            dacData.id,
                                                            dacLink,
                                                        ),
                                                    );
                                                }}
                                            >
                                                Go to pool page
                                            </Button>
                                        </Row>
                                    )}
                                    <Row align="center">
                                        <Button
                                            className={style.button}
                                            type="transparent"
                                            onClick={handleModalClose}
                                        >
                                            Close
                                        </Button>
                                    </Row>
                                </div>
                            </>
                        )}
                    </>
                )}
                {step === 'alert' && (
                    <>
                        <Row align="center">
                            <SecondTitle
                                align="center"
                                color="main"
                                fontWeight={700}
                            >
                                Alert!
                            </SecondTitle>
                        </Row>
                        <Row align="center">
                            <img
                                src={WarningImg}
                                className={style.warningIcon}
                                alt=""
                            />
                        </Row>
                        <Row align="center">
                            <Text
                                color="#fff"
                                fontSize={16}
                                align="center"
                                lineHeight={1.4}
                            >
                                {alertMessage}
                            </Text>
                        </Row>
                        <div className={style.footer}>
                            {/* <Row align="center">
                                <Button
                                    className={style.button}
                                    onClick={() => {
                                        window.open(BridgeUrl);
                                    }}
                                >
                                    Close
                                </Button>
                            </Row> */}
                            <Row align="center">
                                <Button
                                    className={style.button}
                                    type="transparent"
                                    onClick={handleModalClose}
                                >
                                    Close
                                </Button>
                            </Row>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
}
