import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import UsePresetAuth from '@/hooks/use-preset-auth';
import userUpdater, { setIsOpenConnectModalHooks } from '@/state/user/hooks';
import Header from '@/components/Header/index';
import Footer from '@/components/Footer';
import Home from './pages/home';
import CreateDac from './pages/create-dac';
import Search from './pages/search/index';
import Vault from './pages/vault';
import NotFound from './pages/notfound';
import DacFullRankingList from '@/pages/dac-ranking';
import Invite from '@/pages/invite';
import RouterConfig from '@/constants/router';
import ConnectAccountModal from '@/components/ConnectAccountModal';
import { useAppSelector } from './state/hooks';
import TimeLoading from '@/components/Loading/ConnectingLoading';
import DacDetail from '@/pages/dac-detail';

const MainContainer = styled.div`
    margin: 0 auto;

    width: 1180px;
    min-height: calc(100vh - 128px - 74px);

    &::after {
        content: '';
        display: table;
        clear: both;
    }
`;

function App() {
    const user = useAppSelector((state) => state.user);
    const setIsOpenConnectModal = setIsOpenConnectModalHooks();
    userUpdater();

    return (
        <main className="app-wrapper">
            <Router>
                <UsePresetAuth />
                <Header />
                <MainContainer>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route exact path={RouterConfig.create}>
                            <CreateDac />
                        </Route>
                        <Route exact path={RouterConfig.search}>
                            <Search />
                        </Route>

                        <Route path={RouterConfig.vault}>
                            <Vault />
                        </Route>
                        <Route path={RouterConfig.dacHome}>
                            <DacDetail />
                        </Route>
                        <Route path={RouterConfig.dacShortLinkHome}>
                            <DacDetail />
                        </Route>
                        <Route path={RouterConfig.ranking}>
                            <DacFullRankingList />
                        </Route>
                        <Route path={RouterConfig.invite}>
                            <Invite />
                        </Route>
                        <Route>
                            <NotFound />
                        </Route>
                    </Switch>
                </MainContainer>
                <Footer />
            </Router>
            <ConnectAccountModal
                isOpen={user.isOpenConnectModal}
                onClose={() => {
                    setIsOpenConnectModal(false);
                }}
            />
            <TimeLoading loading={user.loadingUserInfo} />
        </main>
    );
}

export default App;
