import React from 'react';
import Row from '@/components/Row';
import { Title } from '@/components/Text';

import style from './index.module.scss';
import PageHeader from '@/components/PageHeader';
import DacAside from '@/components/DacAside';
import RankingList from '@/components/RankingList';
// import { Link } from 'react-router-dom';
// import RouterConfig from '@/constants/router';
import LuckyDrawList from '@/components/LuckyDraw';

export default function Pool() {
    const show = true;
    return (
        <>
            <PageHeader pageTitle="" />
            <div className={style.wrapper}>
                {show && (
                    <>
                        <Row>
                            <Title>Lucky Draw</Title>
                        </Row>
                        <LuckyDrawList />
                    </>
                )}
                <Row>
                    <Title>DAC Ranking</Title>
                    {/* <Link className={style.href} to={RouterConfig.ranking}>
                        View All Rank
                    </Link> */}
                </Row>
                <RankingList />
            </div>
            <DacAside />
        </>
    );
}
