import React, { useEffect } from 'react';
import DacHome from '@/pages/dac-home';
// import DacProfile from '@/pages/dac-profile';
import DacPool from '@/pages/dac-mining/Pool';
import DacRanking from '@/pages/dac-mining/Ranking';
import DACMiningGuide from '@/pages/dac-mining/MiningGuide';
import { Route, Switch } from 'react-router-dom';
import RouterConfig from '@/constants/router';
import DACManagement from '@/pages/dac-management';
import DacStateUpdater from '@/state/dac/hooks';
import { useAppDispatch } from '@/state/hooks';
import { reset } from '@/state/dac';

export default function DacDetail() {
    DacStateUpdater();

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    return (
        <div>
            <Switch>
                <Route path={RouterConfig.dacPool}>
                    <DacPool />
                </Route>

                <Route path={RouterConfig.dacRanking}>
                    <DacRanking />
                </Route>
                <Route path={RouterConfig.dacManagement}>
                    <DACManagement />
                </Route>
                <Route path={RouterConfig.dacMiningRules}>
                    <DACMiningGuide />
                </Route>

                <Route path={RouterConfig.dacShortLinkPool}>
                    <DacPool />
                </Route>
                <Route path={RouterConfig.dacShortLinkRanking}>
                    <DacRanking />
                </Route>
                <Route path={RouterConfig.dacShortLinkManagement}>
                    <DACManagement />
                </Route>
                <Route path={RouterConfig.dacShortLinkMiningRules}>
                    <DACMiningGuide />
                </Route>
                <Route component={DacHome} />
            </Switch>
        </div>
    );
}
