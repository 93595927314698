import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { useHistory } from 'react-router';

import { InputLabel, Input } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@/components/Button';

import styled from 'styled-components';

import DacAside from '@/components/DacAside';
import PageHeader from '@/components/PageHeader';
import DacMining from '@/components/DacMining';
import Modal from '@/utils/components/modal';
import Alert from '@/utils/components/alert';
import copy from 'copy-to-clipboard';

import { useAppSelector } from '@/state/hooks';
import { DAC_STATUS, ROLE } from '@/constants';
import RouterConfig from '@/constants/router';
import useMiddlewareClient from '@/hooks/use-middleware-client';
import useGetMiningCalculateInfo from '@/hooks/use-get-mining-calculate-info';

const DacHomeWrapper = styled.div`
    &::after {
        content: '';
        display: table;
        clear: both;
    }
`;

const DacHomeMain = styled.div`
    width: 900px;
    float: right;
`;

const DacLinkCopyContainer = styled.div`
    margin-bottom: 24px;
    display: flex;
    width: 761px;
    height: 64px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    padding: 14px 16px;
    justify-content: space-between;
`;
const DacLinkCopyIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`;

const StatisticsContainer = styled.div`
    margin-bottom: 40px;

    &::after {
        content: '';
        display: table;
        clear: both;
    }
`;
const StatisticsCard = styled.div`
    padding: 44px 0;
    width: 360px;
    height: 200px;
    border-radius: 4px;
    color: #fff;
    float: left;
    text-align: center;

    &:nth-of-type(1) {
        margin-right: 40px;
        background: #110c12 linear-gradient(90deg, #96237f 0%, #d23169 100%);
    }

    &:nth-of-type(2) {
        background: #110c12 linear-gradient(90deg, #530b7f 0%, #8b1ca6 100%);
    }
`;

const StatisticsValue = styled.div`
    font-size: 48px;
    font-weight: 600;
    color: #fff;
    line-height: 78px;
`;

const StatisticsTitle = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 33px;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dacName: {
            fontSize: 14,
            fontWeight: 500,
            color: '#ffffff',
            lineHeight: '36px',
            maxWidth: 230,
        },
        dacLink: {
            height: 36,
            color: '#ffffff',
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 4,
            paddingLeft: 16,
            fontSize: 12,
            cursor: 'default',
            flex: '1 1 auto',
            minWidth: 360,
            margin: '0 16px',
            '& input': {
                cursor: 'default',
            },
        },
        dacButton: {
            width: 110,
            height: 36,
            fontSize: 16,
            background:
                '#C85582 linear-gradient(90deg, #96237f 0%, #D23169 100%)',
            borderRadius: 4,
        },
    }),
);

export default function DacHome() {
    const { dac, userRole, dacLink } = useAppSelector((state) => state.dac);
    const { dac: userDac } = useAppSelector((state) => state.user);
    const userDacId = useMemo(() => {
        return userDac && userDac.id;
    }, [userDac]);
    const history = useHistory();
    const classes = useStyles();
    const [startTimeStamp, setStartTimeStamp] = useState(0);
    const { clientReady, getMiningStartTimeStamp } = useMiddlewareClient();
    async function getStartTime() {
        const res = await getMiningStartTimeStamp();
        setStartTimeStamp(res * 1000);
    }

    const { tvl } = useGetMiningCalculateInfo();

    useEffect(() => {
        if (clientReady) {
            getStartTime();
        }
    }, [clientReady]);

    async function dismissAlert() {
        try {
            await new Promise((resolve, reject) => {
                Modal({
                    iconType: 'disbanded',
                    text:
                        'Your DAC has been dissolved by the DAC Owner, and you are no longer participating in mining',
                    onClose() {
                        reject();
                    },
                    confirmButtonProps: {
                        text: 'Acknowledge',
                        onClick() {
                            resolve('');
                        },
                    },
                });
            });

            Modal({
                iconType: 'gift',
                text: 'You will need to withdraw your TVL in Pool.',
                confirmButtonProps: {
                    text: 'Enter Pool',
                    onClick() {
                        if (dac) {
                            history.push(
                                RouterConfig.getDacPool(dac.id, dacLink),
                            );
                        }
                    },
                },
            });
        } catch (e) {}
    }

    const showAlert = useMemo(() => {
        return (
            dac &&
            dac.id === userDacId &&
            dac.status === DAC_STATUS.dismiss &&
            userRole === ROLE.MEMBER
        );
    }, [dac, userRole, userDacId]);

    function hanedleCpoy() {
        copy(`${window.location.origin}/dac/${dacLink}`);
        Alert({
            type: 'success',
            text: 'Copied!',
        });
    }

    useEffect(() => {
        if (showAlert) {
            dismissAlert();
        }
    }, [showAlert]);

    const poolStartStatus = useMemo(() => {
        if (dac && dac.status === DAC_STATUS.dismiss) return 2;
        return new Date().getTime() >= startTimeStamp ? 1 : 0;
    }, [startTimeStamp, dac]);
    return (
        <>
            <PageHeader
                pageTitle={''}
                dacTitle={dac && dac.name}
                showBackDacButton
            />
            <DacHomeWrapper>
                <DacHomeMain>
                    {dac && dacLink && (
                        <DacLinkCopyContainer>
                            <InputLabel
                                className={classes.dacName}
                                htmlFor="dacname"
                            >
                                {dac.name}
                            </InputLabel>
                            <Input
                                id="dacname"
                                className={classes.dacLink}
                                readOnly
                                aria-describedby="dacname-text"
                                value={
                                    window.location.origin + '/dac/' + dacLink
                                }
                            />
                            <Button
                                onClick={hanedleCpoy}
                                className={classes.dacButton}
                            >
                                <DacLinkCopyIcon src="/static/images/ic_copy-light.png" />
                                Copy
                            </Button>
                        </DacLinkCopyContainer>
                    )}
                    <StatisticsContainer>
                        <StatisticsCard>
                            <StatisticsValue>
                                {dac ? dac.membersCount : '--'}
                            </StatisticsValue>
                            <StatisticsTitle>Participants</StatisticsTitle>
                        </StatisticsCard>
                        <StatisticsCard>
                            <StatisticsValue>
                                {(() => {
                                    let statusText = '--';
                                    if (dac) {
                                        statusText =
                                            dac.status === DAC_STATUS.active
                                                ? 'ACTIVE'
                                                : 'DISSOLVED';
                                    }
                                    return statusText;
                                })()}
                            </StatisticsValue>
                            <StatisticsTitle>STATUS</StatisticsTitle>
                        </StatisticsCard>
                    </StatisticsContainer>
                    <DacMining
                        status={poolStartStatus}
                        date={startTimeStamp}
                        tvl={tvl}
                        dacId={dac ? dac.id : 0}
                        dacLink={dacLink ? dacLink : ''}
                        role={userRole}
                    />
                </DacHomeMain>
                <DacAside />
            </DacHomeWrapper>
        </>
    );
}
