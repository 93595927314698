import React, { useEffect, useMemo, useState } from 'react'; // useEffect
import Row from '@/components/Row';
import Text, { Title } from '@/components/Text';
import style from './index.module.scss';
import useDacHooks from '@/hooks/use-dac-hooks';
import { Dac, DAC_STATUS, DEPOSIT_MAX, DEPOSIT_MIN, ROLE } from '@/constants';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '@/components/Loading';
import RouterConfig from '@/constants/router';
import Modal from '@/utils/components/modal';
import { useAppDispatch } from '@/state/hooks';
import { addMembersCount } from '@/state/dac';
import getDacPlusInfo from '@/graphql/dac-plusinfo';
import JoinDacButton from '@/components/JoinDacButton';

export default function Invite() {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { getDacInfoById } = useDacHooks();
    const [loading, setLoading] = useState(true);
    const [dac, setDac] = useState<Dac | undefined>(undefined);
    const { invitationCode: shareUrlCode } = useParams<{
        invitationCode: string;
    }>();
    const [dacLink, setDacLink] = useState('');

    async function getDac(code: string) {
        setLoading(true);

        if (code) {
            const res = await getDacPlusInfo({
                code,
            });

            if (res) {
                const dacRes = await getDacInfoById(res.id);
                if (dacRes) {
                    setDac(dacRes);
                }
                setDacLink(res.linkPath);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (shareUrlCode) {
            getDac(shareUrlCode);
        }
    }, [shareUrlCode]);

    useEffect(() => {
        if (!loading && (!dac || dac.status === DAC_STATUS.dismiss)) {
            Modal({
                iconType: 'unhappy',
                text: `Oops...<br/>Page not found!`,
                confirmButtonProps: {
                    text: 'Vist Homepage',
                    onClick() {
                        history.push('/');
                    },
                },
            });
        }
    }, [dac, loading]);

    async function handleJoinSuccess() {
        if (dac) {
            dispatch(addMembersCount(1));
            history.push(RouterConfig.getDacHome(dac.id, dacLink));
        }
    }

    return (
        <>
            <div className={style.wrapper}>
                {loading ? (
                    <div className={style.loading}>
                        <Loading size={36} />
                    </div>
                ) : !dac ? (
                    <div className={style.error}>
                        {shareUrlCode ? 'Page not found' : 'Invaild url'}
                    </div>
                ) : (
                    <>
                        <div className={style.authInfo}>
                            <span className={style.authPhoto}>
                                <img src={dac.photo} alt="avatar" />
                            </span>
                            <Text
                                customClass={style.authName}
                                fontSize={16}
                                lineHeight="40px"
                            >
                                {dac.name}
                            </Text>
                        </div>
                        <Row customClass={style.inviteTitle} align="center">
                            <Title
                                color={'#cd3270'}
                                fontSize={14}
                                lineHeight={'29px'}
                                align="center"
                            >
                                We would like to invite you to join “{dac.name}”
                                {/* and participate in the DAC Mining! */}
                            </Title>
                        </Row>
                        <Row align="center" customClass={style.dacInfo}>
                            <div className={style.dacPhoto}>
                                <img src={dac.photo} alt="DacPhoto" />
                            </div>
                            <div className={style.info}>
                                <Row lineHeight="none">
                                    <Title
                                        fontSize={14}
                                        style={{ wordBreak: 'break-word' }}
                                    >
                                        {dac.name}
                                    </Title>
                                </Row>
                                <Row>
                                    <Title
                                        fontSize={12}
                                        style={{ wordBreak: 'break-word' }}
                                    >
                                        {dac.introduction}
                                    </Title>
                                </Row>
                            </div>
                            <div className={style.members}>
                                <Row lineHeight="none" align="center">
                                    <Title fontSize={20}>
                                        {dac.membersCount}
                                    </Title>
                                </Row>
                                <Row align="center">
                                    <Text
                                        fontSize={12}
                                        align="center"
                                        color={'rgba(255,255,255,.3)'}
                                    >
                                        Participants
                                    </Text>
                                </Row>
                            </div>
                        </Row>
                        <Row customClass={style.metisTips}>
                            <Text
                                fontSize={12}
                                lineHeight={'17px'}
                                align="center"
                                color={'rgba(255,255,255,.3)'}
                            >
                                {/* *Bridge and stake {DEPOSIT_MIN}～{DEPOSIT_MAX}
                                Metis token now to receive bonus. */}
                                Please notify that the DAC staking program is
                                closed at Sat, 22 Jan 2022 07:00 (UTC). You are
                                welcomed to join the DAC, but no rewards or
                                mining power will be issued to your account.
                                Please stay tuned with us for more functions and
                                applications online.
                            </Text>
                        </Row>
                        <Row customClass={style.actionBar} align="center">
                            <JoinDacButton
                                buttonClassName={style.btnSubmit}
                                dacName={dac.name}
                                dacId={dac.id}
                                dacInvitationCode={dac.invitationCode}
                                dacCreator={dac.creator}
                                onSuccess={handleJoinSuccess}
                                dacLink={dacLink}
                            >
                                Participate
                            </JoinDacButton>
                        </Row>
                    </>
                )}
            </div>
        </>
    );
}
