export const NAME_TIPS =
    'DAC Name can contain letters (a-z), numbers (0-9), dashes (-), underscores (_). Up to 30 characters. DAC created with abusive and inappropriate names will be removed from the system';
export const NAME_METIS_TIP =
    'Metis and related brand names are reserved for Metis internal use only. Please provide another name.';
export const NAME_ABUSIVE_TIP =
    'Abusive and inappropriate names are not allowed. Please provide another name.';
export const CATEGORY_TIPS =
    "DAC Category helps the users who have the same passion to find your DAC faster. You may determine your default DAC category now that is related to your DAC's specialization. DAC Category can contain letters (a-z), numbers (0-9) and has to be less than 10 characters.";
export const CATEGORY_TIP =
    'DAC Category can contain letters (a-z), numbers (0-9) and has to be less than 10 characters.';
export const URL_TIPS =
    'URL can contain letters (a-z), numbers (0-9), dashes (-), underscores (_). Up to 30 characters';
export const PHOTO_TIPS =
    'file size: 360px * 360px or 720px * 720px \n file type: jpeg, jpg, png';

export const REPORT_BUG_FAIL_MSG =
    'Something went wrong and your report wasn’t sent successfully. Please double-check and try again. ';
export const REPORT_BUG_SUCCESS_MSG =
    'Thank you for submitting the bug report. We will review and fix it as soon as possible.';
