import React, { useEffect, useState, useMemo } from 'react';
import style from './index.module.scss';
import Row from '@/components/Row';
import Text, { SecondTitle, Title } from '@/components/Text';
// import ReactEChartsCore from 'echarts-for-react/lib/core';
// import * as echarts from 'echarts/core';
// import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import Button from '@/components/Button';
// import DacAside from '@/components/DacAside';
// import PageHeader from '@/components/PageHeader';
import useMiddlewareClient from '@/hooks/use-middleware-client';
import useGetVaultCaculateInfo from '@/hooks/use-get-vault-calculate-info';
import WithdrawModal from '@/components/WithdrawModal';
import BigNumber from 'bignumber.js';
import Value from '@/components/Value';
import { useAppSelector } from '@/state/hooks';
import AuthWrapper from '@/components/AuthWrapper';
import { useHistory } from 'react-router-dom';
import { SESSION_KEY } from '@/constants';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TooltipsInfo from '@/components/TooltipInfo';

// echarts.use([PieChart, CanvasRenderer]);

export default function Vault() {
    const history = useHistory();
    const { getVaultShares, clientWithUserReady } = useMiddlewareClient();
    const [userShares, setUserShares] = useState(new BigNumber(0));
    const [isOpenWithdraw, setIsOpenWithdraw] = useState(false);
    const { totalShares, vaultBalance } = useGetVaultCaculateInfo();
    const { dac } = useAppSelector((state) => state.user);
    const [hasBackPage, setHasBackPage] = useState(false);

    useEffect(() => {
        const backPage = window.sessionStorage.getItem(
            SESSION_KEY.vaultPrevPage,
        );

        if (backPage) {
            window.sessionStorage.setItem(SESSION_KEY.vaultPrevPage, '');
            setHasBackPage(true);
        }
    }, []);

    const dacData = useMemo(() => {
        if (dac) {
            return {
                id: dac.id,
                name: dac.name,
                status: dac.status,
            };
        }

        return undefined;
    }, [dac]);

    // const charsOptions = useMemo(() => {
    //     const left = userShares.toNumber();
    //     let right = totalShares > 0 ? totalShares - left : 0;
    //     right = left ? right : 100;
    //     return {
    //         color: ['#333', '#cd3270'],
    //         tooltip: {
    //             trigger: 'item',
    //         },
    //         series: [
    //             {
    //                 name: 'Shares',
    //                 type: 'pie',
    //                 radius: ['40%', '70%'],
    //                 avoidLabelOverlap: false,
    //                 label: {
    //                     show: false,
    //                     position: 'center',
    //                 },
    //                 emphasis: {
    //                     label: {
    //                         show: false,
    //                         fontSize: '40',
    //                         fontWeight: 'bold',
    //                     },
    //                 },
    //                 labelLine: {
    //                     show: false,
    //                 },
    //                 data: [
    //                     {
    //                         value: right,
    //                         name: 'Other shares',
    //                     },
    //                     {
    //                         value: left,
    //                         name: 'Your shares',
    //                     },
    //                 ],
    //             },
    //         ],
    //     };
    // }, [userShares, totalShares]);

    async function getVaultData() {
        const res = await getVaultShares();
        setUserShares(res);
    }

    useEffect(() => {
        getVaultData();
    }, [clientWithUserReady]);

    const sharePercent = useMemo(() => {
        if (totalShares > 0) {
            return userShares
                .dividedBy(totalShares)
                .multipliedBy(100)
                .toNumber();
        }

        return -1;
    }, [userShares, totalShares]);

    const userRealSharesWithMetisBalance = useMemo(() => {
        if (sharePercent === -1) {
            return new BigNumber(0);
        }

        return vaultBalance.multipliedBy(sharePercent).dividedBy(100);
    }, [sharePercent, vaultBalance]);

    const handleWithdrawSuccess = () => {
        getVaultData();
    };

    const disableWithdraw = useMemo(() => {
        return !userRealSharesWithMetisBalance.toNumber();
    }, [userRealSharesWithMetisBalance]);

    return (
        <AuthWrapper>
            {/* <PageHeader pageTitle="" /> */}
            <div className={style.wrapper}>
                <Row align="between">
                    <Title fontSize={24}>Vault Overview</Title>
                    <Button
                        className={style.btnBack}
                        onClick={() => {
                            if (hasBackPage) {
                                history.goBack();
                            } else {
                                history.push('/');
                            }
                        }}
                    >
                        <ChevronLeftIcon style={{ width: '16px' }} /> Back
                    </Button>
                </Row>
                <div className={style.blockWrapper}>
                    <div className={style.block}>
                        <Row lineHeight="none" align="center">
                            <Text fontSize={14}>TVL（Metis）</Text>
                        </Row>
                        <Row align="center">
                            <SecondTitle>
                                {!vaultBalance.toNumber() ? (
                                    '--'
                                ) : (
                                    <Value value={vaultBalance.toNumber()} />
                                )}
                            </SecondTitle>
                        </Row>
                    </div>
                    <div className={style.block}>
                        <Row align="center" lineHeight="none">
                            <Text fontSize={14}>Leave Deduction</Text>
                        </Row>
                        <Row align="center">
                            <SecondTitle>-10%</SecondTitle>
                        </Row>
                    </div>
                </div>
                <Row>
                    <Title fontSize={24}>My overview</Title>
                </Row>
                <div className={style.data}>
                    {/* {sharePercent > 0 && (
                        <div className={style.chartsWrapper}>
                            <ReactEChartsCore
                                style={{
                                    width: '128px',
                                    height: '128px',
                                    display: 'inline-block',
                                }}
                                echarts={echarts}
                                option={charsOptions}
                                notMerge={true}
                                lazyUpdate={true}
                            />
                        </div>
                    )} */}

                    <div>
                        <Row lineHeight="none" align="center">
                            <Text fontSize={14}>Percentage</Text>
                        </Row>
                        <Row>
                            <SecondTitle align="center">
                                {sharePercent === -1 ? (
                                    '--'
                                ) : (
                                    <>
                                        <Value value={sharePercent} />%
                                    </>
                                )}
                            </SecondTitle>
                        </Row>
                    </div>
                    <div>
                        <Row lineHeight="none" align="center">
                            <Text fontSize={14}>Value (Metis)</Text>
                            <TooltipsInfo
                                style={{ marginLeft: '4px' }}
                                text={
                                    <span>
                                        *The amount you receive when withdrawing
                                        could be slightly different from the
                                        estimated amount as the system will
                                        deduct 10% from the reward and add your
                                        share of the Leave Deduction reward.
                                        <br />
                                        <br />
                                        *Please note the longer you keep your
                                        reward in the Vault, the greater the
                                        accumulated rewards will be. (The Metis
                                        tokens deducted from the ones who
                                        withdraw before you will be distributed
                                        to the members who still has amounts in
                                        the Vault ).
                                    </span>
                                }
                            />
                        </Row>
                        <Row align="center">
                            <SecondTitle>
                                <Value
                                    value={userRealSharesWithMetisBalance.toNumber()}
                                />
                            </SecondTitle>
                        </Row>
                    </div>
                    <Button
                        className={style.withdraw}
                        disabled={disableWithdraw}
                        onClick={() => {
                            setIsOpenWithdraw(true);
                        }}
                    >
                        Withdraw
                    </Button>
                </div>
            </div>
            {/* <DacAside /> */}
            <WithdrawModal
                isOpen={isOpenWithdraw}
                onClose={() => {
                    setIsOpenWithdraw(false);
                }}
                totalAmount={userRealSharesWithMetisBalance}
                type="vault"
                dacData={dacData}
                onSuccess={handleWithdrawSuccess}
            />
        </AuthWrapper>
    );
}
