import React from 'react'; // useEffect

import MetisNetworkSelection from '@/components/MetisNetworkSelection';
import DacActions from './components/DacActions';

import style from './index.module.scss';

export default function Home() {
    return (
        <>
            <section className={style.header}>
                <h1 className={style.headerTitle}>PORTAL</h1>
                <p className={style.headerDescription}>
                    Create your own Decentralized Autonomous Company (DAC) now!
                </p>
            </section>
            <section className={style.metisContainer}>
                <MetisNetworkSelection />
            </section>
            <section className={style.pageContainer}>
                <DacActions></DacActions>
            </section>
        </>
    );
}
