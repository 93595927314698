import React, { useState, useCallback } from 'react';
import InputRange from 'react-input-range';
import debounce from 'lodash.debounce';

import { DEPOSIT_MIN, DEPOSIT_MAX } from '@/constants';
import 'react-input-range/lib/css/index.css';
import style from './index.module.scss';

interface Props {
    onChange: (value: number) => void;
    value?: number;
    min?: number;
    max?: number;
}

export default function Progress(props: Props) {
    const { onChange, value, min = DEPOSIT_MIN, max = DEPOSIT_MAX } = props;
    const [progressVal, setProgressVal] = useState<any>(value);

    const inputValue = (value: number) => {
        if (value <= max && value >= min) {
            onChange(value);
            setProgressVal(value);
        }
    };

    const changeVal = (value: number) => {
        onChange(value);
        setProgressVal(value);
    };

    const debouncedInputHandler = useCallback(debounce(inputValue, 800), []);

    return (
        <>
            <div className={style.progressBar}>
                <InputRange
                    maxValue={max}
                    minValue={min}
                    value={progressVal}
                    onChange={(value: any) => changeVal(parseInt(value))}
                />

                <input
                    max={max}
                    min={min}
                    required={true}
                    name="amount"
                    type="number"
                    key={progressVal}
                    defaultValue={progressVal}
                    onKeyUp={(event: any) =>
                        debouncedInputHandler(parseInt(event.target.value, 10))
                    }
                    onBlur={(event: any) =>
                        debouncedInputHandler(parseInt(event.target.value, 10))
                    }
                    className="MuiInputBase-input MuiInput-input"
                />
            </div>
        </>
    );
}
