import React from 'react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

import RouterConfig from '@/constants/router';
import Text, { Title } from '@/components/Text';
import style from './index.module.scss';
import { useAppSelector } from '@/state/hooks';
import { useMemo } from 'react';
import { ROLE } from '@/constants';

const APP_MENUS = [
    {
        label: 'Home',
        getLink: RouterConfig.getDacHome,
        openDefault: true,
    },
    {
        label: 'DAC Mining',
        getLink: RouterConfig.getDacPool,
        openDefault: false,
        children: [
            {
                label: 'Pool',
                getLink: RouterConfig.getDacPool,
                openDefault: false,
            },
            {
                label: 'Ranking',
                getLink: RouterConfig.getDacRanking,
                openDefault: false,
            },
            {
                label: 'Rules',
                getLink: RouterConfig.getMiningRules,
            },
        ],
    },
    // {
    //     label: 'Finance',
    //     getLink: RouterConfig.getDacFinance,
    // },
];

const OPERATIONS_MENUS = [
    {
        label: 'DAC Management',
        getLink: RouterConfig.getDacManagement,
        openDefault: false,
    },
];

export default function DacAside() {
    const history = useHistory();
    const location = useLocation();
    const { state } = useLocation<{ isopen: boolean }>() || {};
    const { id, link } = useParams<{
        id: string;
        link: string | undefined;
    }>();

    const [open, setOpen] = React.useState(
        state && state.isopen ? state.isopen : false,
    );

    const handleClick = () => {
        setOpen(!open);
    };

    const { userRole } = useAppSelector((state) => state.dac);

    const isCreator = useMemo(() => {
        return userRole === ROLE.CREATOR;
    }, [userRole]);

    return (
        <>
            <aside className={style.asideWrapper}>
                <Title
                    fontSize={20}
                    fontWeight={600}
                    lineHeight={'33px'}
                    customClass={style.asideTitle}
                >
                    MApps
                </Title>
                <ul className={style.asideMenus}>
                    {APP_MENUS.map((item) => {
                        if (!!item.children) {
                            return (
                                <li
                                    key={item.label}
                                    className={`${style.menuItem} ${
                                        style.hasChildren
                                    } ${open ? style.open : ''}`}
                                >
                                    <span
                                        className={style.asideSubTitle}
                                        onClick={(e) => {
                                            if (
                                                location.pathname ===
                                                item.getLink(id, link)
                                            ) {
                                                handleClick();
                                                return;
                                            }
                                            if (!open) {
                                                history.push(
                                                    item.getLink(id, link),
                                                    {
                                                        isopen: !open,
                                                    },
                                                );
                                            }
                                            handleClick();
                                        }}
                                    >
                                        {item.label}
                                        <img
                                            src="/static/images/ic_arrow-right.png"
                                            alt="icon arrow"
                                        />
                                    </span>
                                    <ol className={style.asideSubMenus}>
                                        {item.children.map((subItem) => {
                                            return (
                                                <li
                                                    key={subItem.label}
                                                    className={`${
                                                        style.menuItem
                                                    } ${
                                                        location.pathname ===
                                                        subItem.getLink(
                                                            id,
                                                            link,
                                                        )
                                                            ? style.active
                                                            : ''
                                                    }`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.nativeEvent.stopImmediatePropagation();
                                                        if (
                                                            location.pathname ===
                                                            subItem.getLink(
                                                                id,
                                                                link,
                                                            )
                                                        )
                                                            return;
                                                        history.push(
                                                            subItem.getLink(
                                                                id,
                                                                link,
                                                            ),
                                                            { isopen: open },
                                                        );
                                                    }}
                                                >
                                                    <Text
                                                        fontSize={14}
                                                        fontWeight={600}
                                                        lineHeight={'24px'}
                                                    >
                                                        {subItem.label}
                                                    </Text>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </li>
                            );
                        } else {
                            return (
                                <li
                                    key={item.label}
                                    className={`${style.menuItem} ${
                                        location.pathname ===
                                        item.getLink(id, link)
                                            ? style.active
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (
                                            location.pathname ===
                                            item.getLink(id, link)
                                        )
                                            return;
                                        history.push(item.getLink(id, link), {
                                            isopen: item.openDefault
                                                ? !item.openDefault
                                                : !open,
                                        });
                                    }}
                                >
                                    <Text
                                        fontSize={14}
                                        fontWeight={600}
                                        lineHeight={'24px'}
                                    >
                                        {item.label}
                                    </Text>
                                </li>
                            );
                        }
                    })}
                </ul>
                {isCreator && (
                    <>
                        <Title
                            fontSize={20}
                            fontWeight={600}
                            lineHeight={'33px'}
                            customClass={style.asideTitle}
                        >
                            DAC Operations
                        </Title>
                        <ul className={style.asideMenus}>
                            {OPERATIONS_MENUS.map((item) => {
                                return (
                                    <li
                                        key={item.label}
                                        className={`${style.menuItem} ${
                                            location.pathname ===
                                            item.getLink(id, link)
                                                ? style.active
                                                : ''
                                        }`}
                                        onClick={() => {
                                            if (
                                                location.pathname ===
                                                item.getLink(id, link)
                                            )
                                                return;
                                            history.push(
                                                item.getLink(id, link),
                                                { isopen: open },
                                            );
                                        }}
                                    >
                                        {item.label}
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}
            </aside>
        </>
    );
}
