import { useMemo } from 'react';
import { useAppSelector } from '@/state/hooks';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

import {
    CONTRACT_DOMAIN,
    DOMAIN_CONFIG,
    ADDRESS_ZERO,
    DEPOSIT_MAX,
    ROLE,
    TxActStatus,
    TxStatus,
    PID,
} from '@/constants';
import moment from 'moment';

export default function useMiddleareClient() {
    const user = useAppSelector((state) => state.user);
    const { client, chainId } = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );

    async function getMetisBalance() {
        if (client && user.address) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.METIS_TOKEN].domain,
                    chainId,
                    'balanceOf',
                    [user.address.toLowerCase()],
                    true,
                );
                if (res && res.result) {
                    return new BigNumber(res.result).shiftedBy(-18);
                }
            } catch (e) {
                console.error(e);
                console.error('getMetisBalance error');
            }
        }

        return new BigNumber(0);
    }

    async function getMetisAllowanceFor(address: string) {
        if (client && user.address) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.METIS_TOKEN].domain,
                    chainId,
                    'allowance',
                    [user.address, address],
                    true,
                );
                if (res && res.result) {
                    return new BigNumber(res.result).shiftedBy(-18);
                }
            } catch (e) {
                console.error(e);
                console.error('getMetisAllowanceFor error');
            }
        }

        return new BigNumber(0);
    }

    async function approveMetisTokenTo(spender: string, amount: string) {
        if (client) {
            try {
                const allowance = await getMetisAllowanceFor(spender);
                if (allowance.shiftedBy(-18).isGreaterThan(DEPOSIT_MAX)) {
                    return true;
                }
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.METIS_TOKEN].domain,
                    chainId,
                    'approve',
                    [spender, ethers.constants.MaxUint256.toString()],
                );

                if (res && res.status === TxStatus.SUCCESS) {
                    return true;
                }
                return false;
            } catch (e) {
                console.error(e);
                console.error('approveMetisTokenTo fail');
            }
        }
        return false;
    }

    async function approveMetisTokenToDAC(amount: number) {
        if (!client) return false;

        const res = await client.getDomain(
            DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
            `${chainId}`,
        );

        if (res && res.contract_address) {
            const spender = res.contract_address;

            return await approveMetisTokenTo(
                spender,
                new BigNumber(amount).shiftedBy(18).toFixed(),
            );
        }

        return false;
    }

    async function isNeedToApproveMining(amount: BigNumber) {
        if (client) {
            if (!client) return false;

            const res = await client.getDomain(
                DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
                `${chainId}`,
            );
            if (res && res.contract_address) {
                const spender = res.contract_address;
                const allowance = await getMetisAllowanceFor(spender);

                if (
                    allowance
                        .shiftedBy(-18)
                        .isGreaterThan(new BigNumber(amount))
                ) {
                    return false;
                }
            }
        }

        return true;
    }

    async function approveMetisTokenToMining(amount: number = 0) {
        if (!client) return false;

        const res = await client.getDomain(
            DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
            `${chainId}`,
        );

        if (res && res.contract_address) {
            const spender = res.contract_address;

            return await approveMetisTokenTo(
                spender,
                new BigNumber(amount).shiftedBy(18).toFixed(),
            );
        }

        return false;
    }

    async function estimateDepositGasFee(
        amount: BigNumber,
        role: ROLE,
        dacId: string | number,
        creatorAddress: string,
    ) {
        if (user.address && client) {
            try {
                const res = await client.estimateGasAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'increaseDeposit',
                    [
                        dacId,
                        role === ROLE.CREATOR ? ADDRESS_ZERO : creatorAddress,
                        amount.shiftedBy(18).toFixed(),
                    ],
                    true,
                );

                if (res) {
                    return new BigNumber(res.fee_num);
                }
            } catch (e) {
                console.error('estimateDepositGasFee error');
                console.error(e);
            }
        }

        return new BigNumber(0);
    }

    async function miningDeposit(
        amount: BigNumber,
        role: ROLE,
        dacId: string | number,
        creatorAddress: string,
        memberCount: number = 1,
        initPower: number = 80,
    ) {
        if (client && user.address && dacId) {
            try {
                // if (user.metisBalance.minus(REMAINING_GAS).isLessThan(amount)) {
                //     Modal({
                //         title: 'Sorry',
                //         text: `The current Metis Token balance ${user.metisBalance.toFixed(
                //             3,
                //         )} on your account is not sufficient to support this execution. Please leave at lease ${REMAINING_GAS} METIS to support this execution`,
                //         confirmButtonProps: {
                //             text: 'Bridge Metis Token',
                //             onClick() {
                //                 window.open(BridgeUrl);
                //             },
                //         },
                //         cancelButtonProps: {
                //             text: 'Cancel',
                //         },
                //     });
                //     return false;
                // }
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'increaseDeposit',
                    [
                        dacId,
                        role === ROLE.CREATOR ? ADDRESS_ZERO : creatorAddress,
                        amount.shiftedBy(18).toFixed(),
                    ],
                );
                if (res && res.status === TxStatus.SUCCESS) {
                    return true;
                }
            } catch (e) {
                console.error(e);
                console.error('miningDeposit error');
            }
        }

        return false;
    }

    async function miningWithdraw(
        userRole: ROLE,
        withdrawAmount: string,
        dacId: string | number,
        creatorAddress: string,
        emergencyWithdraw?: boolean,
    ) {
        if (user.address && client && dacId) {
            try {
                const creator =
                    userRole === ROLE.CREATOR ? ADDRESS_ZERO : creatorAddress;
                let res;
                if (!emergencyWithdraw) {
                    res = await client.sendTxAsync(
                        DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
                        chainId,
                        'withdraw',
                        [
                            creator,
                            PID,
                            new BigNumber(withdrawAmount)
                                .shiftedBy(18)
                                .toFixed(),
                        ],
                        true,
                    );
                } else {
                    res = await client.sendTxAsync(
                        DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
                        chainId,
                        'emergencyWithdraw',
                        [PID],
                        true,
                    );
                }

                if (res && res.status === TxStatus.SUCCESS) {
                    return true;
                }
            } catch (e) {
                console.error(e);
                console.error('miningWithdraw error');
            }
        }

        return false;
    }

    async function getVaultShares() {
        if (user.address && client) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.VALUT].domain,
                    chainId,
                    'shares',
                    [user.address],
                    true,
                );

                if (res && res.result) {
                    return new BigNumber(res.result).shiftedBy(-18);
                }
            } catch (e) {
                console.error(e);
                console.error('getVaultShares error');
            }
        }

        return new BigNumber(0);
    }

    async function withdrawValueShare() {
        if (client) {
            try {
                const shareRes = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.VALUT].domain,
                    chainId,
                    'shares',
                    [user.address],
                    true,
                );
                if (shareRes) {
                    const shareValue = new BigNumber(shareRes.result).toFixed();
                    const res = await client.sendTxAsync(
                        DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.VALUT].domain,
                        chainId,
                        'leave',
                        [shareValue],
                    );

                    if (res && res.status === TxStatus.SUCCESS) {
                        return true;
                    }
                }
            } catch (e) {
                console.error(e);
                console.error('withdrawValueShare error');
            }
        }

        return false;
    }

    async function getMiningPendingMetis(dacId: string | number) {
        if (user.address && client) {
            try {
                const currentTime = moment().unix();
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
                    chainId,
                    'pendingMetis',
                    [currentTime, PID, user.address],
                    true,
                );

                if (res && res.result) {
                    return new BigNumber(res.result)
                        .shiftedBy(-18)
                        .multipliedBy(0.5437991507022424);
                }
            } catch (e) {
                console.error(e);
                console.error('getMiningPendingMetis error');
            }
        }

        return new BigNumber(0);
    }

    async function getMiningTVL() {
        if (user.address && client) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
                    chainId,
                    'userInfo',
                    [PID, user.address],
                    true,
                );

                if (res && res.result) {
                    // console.log(data);
                    if (res.result && res.result.length) {
                        //res.result[0] => amount
                        return new BigNumber(res.result[0]).shiftedBy(-18);
                    }
                }
            } catch (e) {
                console.error(e);
                console.error('getMiningTVL error');
            }
        }

        return new BigNumber(0);
    }

    async function getMiningStartTimeStamp() {
        if (client) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
                    chainId,
                    'startTimestamp',
                    [],
                    true,
                );

                if (res && res.result) {
                    return res.result;
                }
            } catch (e) {
                console.error(e);
                console.error('getMiningTVL error');
            }
        }

        return 0;
    }

    const clientReady = useMemo(() => {
        return !!client;
    }, [client]);

    const clientWithUserReady = useMemo(() => {
        return !!client && !!user && !!user.connected;
    }, [client, user]);

    return {
        clientReady,
        clientWithUserReady,
        getMetisBalance,
        miningDeposit,
        miningWithdraw,
        approveMetisTokenToDAC,
        approveMetisTokenToMining,
        getMiningPendingMetis,
        getMiningTVL,
        getVaultShares,
        withdrawValueShare,
        isNeedToApproveMining,
        getMiningStartTimeStamp,
        estimateDepositGasFee,
    };
}
