import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import MaterialTable from 'material-table';
import cx from 'classnames';
import getDacRankingList from '@/graphql/get-ranking-list';
import { useAppSelector } from '@/state/hooks';
import { CONTRACT_DOMAIN, DOMAIN_CONFIG } from '@/constants';
import getDacOverview from '@/graphql/get-dacoverview';

interface Props {
    hidePaging?: boolean;
}

export default function RankingList({ hidePaging }: Props) {
    const { chainId } = useAppSelector((state) => state.metisMiddlewareClient);
    const [totalDacCount, setTotalDacCount] = useState(10);
    async function getTotalDac() {
        const res = await getDacOverview();
        setTotalDacCount(res.activeDacCount);
    }

    useEffect(() => {
        getTotalDac();
    }, []);

    return (
        <div className={cx(style.list, 'customTable')}>
            <MaterialTable
                options={{
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    sorting: false,
                    draggable: false,
                    paging: !hidePaging && totalDacCount > 10,
                    pageSize: totalDacCount < 10 ? totalDacCount : 10,
                }}
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Members', field: 'membersCount' },
                    {
                        title: 'Percentage',
                        field: 'bonus',
                        render(item) {
                            return `${item.bonus}%`;
                        },
                    },
                ]}
                data={async (query) => {
                    const data = await getDacRankingList({
                        page: query.page,
                        pageSize: query.pageSize,
                    });

                    const initCount = query.page * query.pageSize;

                    let formData = data.dacs.map((item, index) => {
                        return {
                            index: index + initCount + 1,
                            ...item,
                        };
                    });

                    return {
                        data: formData,
                        page: query.page,
                        totalCount: data.dacCount,
                    };
                }}
            />
        </div>
    );
}
