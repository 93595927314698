import { DacClient } from './index';
import { gql } from '@apollo/client';
import { AUTH_PARAMS } from '@/constants/storage';
import storage from '@/utils/storage';
import { rejects } from 'assert';

interface CreatDacLinkRes {
    id: number;
    linkPath: string;
}

export default function createDacShortLink({
    id,
    link,
}: {
    id: number;
    link: string;
}): Promise<{
    success: boolean;
    msg?: string | undefined;
}> {
    const Auth = storage.get(AUTH_PARAMS);
    if (!Auth)
        return Promise.reject(
            new Error('Auth failed, please connect your account'),
        );
    return new Promise((resolve, reject) => {
        DacClient.mutate<{
            createDacLink: CreatDacLinkRes;
        }>({
            variables: {
                id: `${id}`,
                linkPath: link,
            },
            context: {
                headers: {
                    'access-token': Auth.accessToken,
                },
            },
            mutation: gql`
                mutation createDacLink($id: String!, $linkPath: String!) {
                    createDacLink(id: $id, linkPath: $linkPath) {
                        id
                        linkPath
                    }
                }
            `,
        })
            .then((res) => {
                const { data } = res;

                if (data && data.createDacLink) {
                    resolve({
                        success: true,
                    });
                    return;
                } else {
                    let errorMessage =
                        'Something wrong, please try again later.';
                    if (
                        res.errors &&
                        res.errors.length &&
                        res.errors[0].message
                    ) {
                        errorMessage = res.errors[0].message;
                        console.log(errorMessage);
                    }
                    resolve({
                        success: false,
                        msg: errorMessage,
                    });
                }
            })
            .catch((e: any) => {
                let errorMessage = 'Something wrong, please try again later.';
                if (e.message) {
                    errorMessage = e.message;
                }
                resolve({
                    success: false,
                    msg: errorMessage,
                });
            });
    });
}
