import React from 'react';
import Modal from '@/components/Modal';
import style from './index.module.scss';
import Loading from './index';
import { SecondTitle } from '../Text';
import Row from '../Row';

interface Props {
    loading: boolean;
}

export default function TimeLoading({ loading }: Props) {
    if (!loading) return null;
    return (
        <Modal
            isOpen
            onClose={() => {}}
            hideClose
            disableBackgroundClick
            customContentClass={style.timeModalContent}
        >
            <div className={style.timeLoading}>
                <Loading size={60} />
                <Row>
                    <SecondTitle>Connecting...</SecondTitle>
                </Row>
            </div>
        </Modal>
    );
}
