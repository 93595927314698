import { gql } from '@apollo/client';
import client from './index';
interface Member {
    id: string;
}

export default function getMembers({
    dacId,
    page,
    pageSize,
}: {
    dacId: number;
    page: number;
    pageSize: number;
}): Promise<{
    membersCount: number;
    members: Member[];
}> {
    const skip = page * pageSize;
    return new Promise((resolve) => {
        client
            .query<{
                dac: {
                    members: Member[];
                    membersCount: number;
                };
            }>({
                variables: {
                    id: dacId,
                    skip,
                    first: pageSize,
                },
                fetchPolicy: 'no-cache',
                query: gql`
                    query dac($id: ID!, $skip: Int!, $first: Int!) {
                        dac(id: $id) {
                            members(skip: $skip, first: $first) {
                                id
                            }
                            membersCount
                        }
                    }
                `,
            })
            .then((res) => {
                const { data } = res;
                if (data && data.dac && data.dac.membersCount) {
                    resolve({
                        membersCount: data.dac.membersCount,
                        members: data.dac.members,
                    });
                } else {
                    resolve({
                        members: [],
                        membersCount: 0,
                    });
                }
            })
            .catch((e: any) => {
                console.error(e);
                resolve({
                    members: [],
                    membersCount: 0,
                });
            });
    });
}
