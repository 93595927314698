import React, { useEffect, useMemo, useState, useRef } from 'react';
import Button from '@/components/Button';
import Row from '@/components/Row';
import cx from 'classnames';
import Text, { Info, ThirdTitle, Title } from '@/components/Text';
import style from './index.module.scss';
import PageHeader from '@/components/PageHeader';
import DacAside from '@/components/DacAside';
import DepositModal from '@/components/DepositModal';
import WithdrawModal from '@/components/WithdrawModal';
// import InviteModal from '@/components/InviteModal';
import InviteMoreModal from '@/components/InviteMoreModal';
import { DAC_STATUS, ROLE } from '@/constants';
import useGetMiddlewareClient from '@/hooks/use-middleware-client';
import useGetMiningCalculateInfo from '@/hooks/use-get-mining-calculate-info';
import BigNumber from 'bignumber.js';
import Value from '@/components/Value';
import MaterialTable from 'material-table';
import getMembers from '@/graphql/members';
import { useAppSelector } from '@/state/hooks';
import { joinDacFirst } from '@/utils/mining';
import { userExitDacHooks } from '@/state/user/hooks';
import AuthWrapper from '@/components/AuthWrapper';
import Modal from '@/utils/components/modal';
import RouterConfig from '@/constants/router';
import { useHistory, useLocation } from 'react-router-dom';
import TooltipsInfo from '@/components/TooltipInfo';

export default function Pool() {
    const history = useHistory();
    interface locationStateType {
        iswithdraw: boolean;
    }
    const { state } = useLocation<locationStateType>();
    const [isOpenWithdraw, setIsOpenWithdraw] = useState(false);
    const [isOpenClaim, setIsOpenClaim] = useState(false);
    const [isOpenDeposit, setIsOpenDeposit] = useState(false);
    // const [isOpenInvite, setIsOpenInvite] = useState(false);
    const [isOpenInviteMore, setIsOpenInviteMore] = useState(false);
    const { dac, dacLink, shareLinkCode, userRole } = useAppSelector(
        (state) => state.dac,
    );
    const { dac: userDac } = useAppSelector((state) => state.user);
    const exitDac = userExitDacHooks();
    const inviteTableRef = useRef<any>();

    const {
        getMiningPendingMetis,
        getMiningTVL,
        clientWithUserReady,
    } = useGetMiddlewareClient();
    const {
        userShareWeight,
        tvl,
        apy,
        rewardPerDay,
        totalReward,
        updateWeight,
        totalWeight,
        userPower,
    } = useGetMiningCalculateInfo();
    const [userPendingMetis, setUserPendingMetis] = useState(new BigNumber(0));
    const [userTVL, setUserTVL] = useState(new BigNumber(0));
    const [isShowJoin, setIsShowJoin] = useState(false);

    const dacData = useMemo(() => {
        if (dac) {
            return {
                id: dac.id,
                name: dac.name,
                apy,
                creator: dac.creator,
                membersCount: dac.membersCount,
                status: dac.status,
            };
        }

        return undefined;
    }, [dac, apy]);

    async function getUserPendingMetis() {
        if (dacData) {
            const res = await getMiningPendingMetis(dacData.id);
            setUserPendingMetis(res);
        }
    }

    async function getUserTVL() {
        const res = await getMiningTVL();
        setUserTVL(res);
    }

    function getData() {
        getUserPendingMetis();
        getUserTVL();
    }

    useEffect(() => {
        let interval: any;
        if (clientWithUserReady && dac) {
            getData();
            interval = setInterval(() => {
                getData();
            }, 60000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [clientWithUserReady, dac]);

    const userTvlNumber = useMemo(() => {
        return userTVL.toNumber();
    }, [userTVL]);

    const userRewardNumber = useMemo(() => {
        return userTvlNumber ? userPendingMetis.toNumber() : 0;
    }, [userPendingMetis, userTvlNumber]);

    function handleDepositOrWithdrawSuccess() {
        updateWeight();
        getUserPendingMetis();
        getUserTVL();
    }

    function handleMiningWithdrawAll() {
        // 用户全部提取 = 退出dac
        if (dac) {
            exitDac(dac.id);
        }
    }

    const canShare = useMemo(() => {
        return (
            dac && dac.status === DAC_STATUS.active && userRole === ROLE.CREATOR
        );
    }, [dac, userRole]);

    const isViewer = useMemo(() => {
        if (dac && userRole !== ROLE.VIEWER) return false;
        return true;
    }, [dac, userRole]);

    useEffect(() => {
        if (
            dac &&
            userDac &&
            dac.status === DAC_STATUS.active &&
            Number(dac.id) !== Number(userDac.id) &&
            !isShowJoin
        ) {
            setIsShowJoin(true);
            joinDacFirst(userRole, dac.name);
        }
    }, [dac, userRole, userDac, isShowJoin]);

    useEffect(() => {
        if (userDac && state && state.iswithdraw) {
            setIsOpenWithdraw(true);
        }
    }, [state]);

    function handleInvite() {
        if (!dacLink && dac) {
            Modal({
                iconType: 'wrong',
                text:
                    'Please go to management page to create your DAC url first',
                confirmButtonProps: {
                    text: 'Go to management page',
                    onClick() {
                        history.push(RouterConfig.getDacManagement(dac.id));
                    },
                },
            });
            return;
        }
        setIsOpenInviteMore(true);
    }

    const totalWeightNumber = useMemo(() => {
        const num = totalWeight.toNumber();

        if (!num) return -1;
        return num;
    }, [totalWeight]);

    const userPowerNum = useMemo(() => {
        if (!dac || userRole === ROLE.VIEWER) return -1;
        return userRole === ROLE.CREATOR ? userPower : 80;
    }, [dac, userRole, userPower]);

    const dacMembersCount = useMemo(() => {
        if (dac) return dac.membersCount;
        return 0;
    }, [dac]);

    useEffect(() => {
        if (
            inviteTableRef.current &&
            dacMembersCount &&
            userRole === ROLE.CREATOR
        ) {
            inviteTableRef.current.onQueryChange();
        }
    }, [dacMembersCount, userRole]);

    return (
        <AuthWrapper>
            <PageHeader
                pageTitle=""
                joinSuccess={handleDepositOrWithdrawSuccess}
            />
            <div className={style.wrapper}>
                <div className={style.block}>
                    <Row align="center">
                        <img
                            src={require('../../../assets/image/warning.png')}
                            alt=""
                        />
                        <ThirdTitle color="main">
                            {userRole !== ROLE.MEMBER
                                ? `Please notify that the DAC staking program is closed
                            at Sat, 22 Jan 2022 07:00 (UTC). However, you are still welcomed to create
                            your DACs, more functions and ecosystem projects are
                            coming soon!`
                                : `
                                Please notify that the DAC staking program is closed at Sat, 22 Jan 2022 07:00 (UTC). You are welcomed to join the DAC, but no rewards or mining power will be issued to your account. Please stay tuned with us for more functions and applications online.
                            `}
                        </ThirdTitle>
                    </Row>
                </div>
                {/* {canShare && (
                    <div className={style.invite}>
                        <ThirdTitle>
                            Invite more friends to participate in DAC mining to
                            increase your mining power
                        </ThirdTitle>
                        <Button
                            onClick={handleInvite}
                            className={style.inviteButton}
                        >
                            Invite
                        </Button>
                    </div>
                )} */}

                <Row>
                    <Title fontSize={24}>Pool Overview</Title>
                </Row>
                <div className={style.list}>
                    {/* <Row align="center">
                        <Title>DAC Pool</Title>
                    </Row> */}
                    <div className={style.mainData}>
                        <div className={style.item}>
                            <Row align="center" lineHeight="none">
                                <Title color="main" fontSize={36}>
                                    {tvl === -1 ? (
                                        '--'
                                    ) : (
                                        <>
                                            <Value value={tvl} />
                                        </>
                                    )}{' '}
                                    Metis
                                </Title>
                            </Row>
                            <Row align="center">
                                <Info>TVL(Total Value Locked)</Info>
                            </Row>
                        </div>
                        <div className={style.item}>
                            <Row align="center" lineHeight="none">
                                <Title color="main" fontSize={36}>
                                    {apy === -1 ? (
                                        '--'
                                    ) : (
                                        <>
                                            <Value value={apy} />%
                                        </>
                                    )}
                                </Title>
                            </Row>
                            <Row align="center">
                                <Info>APY(For the Entire Pool)</Info>
                            </Row>
                        </div>
                    </div>
                    <div className={style.data}>
                        <div>
                            <Row align="center" lineHeight="none">
                                <Title>
                                    {totalWeightNumber === -1 ? (
                                        '--'
                                    ) : (
                                        <>
                                            <Value value={totalWeightNumber} />
                                        </>
                                    )}
                                </Title>
                            </Row>
                            <Row align="center">
                                <Info>Total Weighted Mining Power</Info>
                                <TooltipsInfo
                                    style={{
                                        marginLeft: '4px',
                                        marginTop: '2px',
                                    }}
                                    text="Total Weighted Mining Power = Σ(Staked Amount * Mining power)"
                                />
                            </Row>
                        </div>
                        <div>
                            <Row align="center" lineHeight="none">
                                <Title>
                                    {rewardPerDay === -1 ? (
                                        '--'
                                    ) : (
                                        <>
                                            <Value value={rewardPerDay} /> Metis
                                        </>
                                    )}
                                </Title>
                            </Row>
                            <Row align="center">
                                <Info>Daily Output</Info>
                            </Row>
                        </div>
                        <div>
                            <Row align="center" lineHeight="none">
                                <Title>
                                    {totalReward === -1 ? (
                                        '--'
                                    ) : (
                                        <>
                                            <Value value={totalReward} /> Metis
                                        </>
                                    )}
                                </Title>
                            </Row>
                            <Row align="center">
                                <Info>Total Rewarded</Info>
                            </Row>
                        </div>
                    </div>
                </div>

                <Row>
                    <Title fontSize={24}>My Overview</Title>
                </Row>
                <div className={style.rewards}>
                    <div className={style.item}>
                        <Row align="center">
                            <Text fontSize={14} color="#fff">
                                Estimated Rewards (Metis)
                            </Text>
                            {dac && userRole !== ROLE.VIEWER && (
                                <TooltipsInfo
                                    style={{ marginLeft: '4px' }}
                                    text={
                                        userRole === ROLE.CREATOR ? (
                                            <span>
                                                Your Rewards Per Second = Total
                                                Rewards Per Second *Your % of
                                                Total Weighted Mining Power
                                                <br />
                                                *Due to the delay in processing
                                                Layer 2 blocks will not be
                                                consistent with real time, the
                                                actual rewards when you withdraw
                                                could be slightly less than the
                                                estimated amount.
                                                <br />
                                                *If someone joins your DAC, your
                                                rewards will be claimed to the
                                                vault automatically.
                                            </span>
                                        ) : (
                                            <span>
                                                Your Rewards Per Second = Total
                                                Rewards Per Second *Your % of
                                                Total Weighted Mining Power
                                                <br />
                                                *Due to the delay in processing
                                                Layer 2 blocks will not be
                                                consistent with real time, the
                                                actual rewards when you withdraw
                                                could be slightly less than the
                                                estimated amount.
                                            </span>
                                        )
                                    }
                                />
                            )}
                        </Row>
                        <Row align="center">
                            <Text align="center" fontSize={34}>
                                {isViewer ? (
                                    '--'
                                ) : (
                                    <Value value={userRewardNumber} />
                                )}
                            </Text>
                        </Row>
                        {/* {dac &&
                        dac.status === DAC_STATUS.dismiss &&
                        userRole !== ROLE.VIEWER ? (
                            <Row align="center">
                                <Text align="center" fontSize={14}>
                                    Click “Withdraw All” to automatically claim
                                    unclaimed rewards to the Vault
                                </Text>
                            </Row>
                        ) : (
                            <Row>
                                <Button
                                    className={style.claim}
                                    disabled={!userRewardNumber || isViewer}
                                    onClick={() => {
                                        setIsOpenClaim(true);
                                    }}
                                >
                                    Claim
                                </Button>
                            </Row>
                        )} */}
                    </div>
                    <div className={style.item}>
                        <Row align="center">
                            <Text fontSize={14} color="#fff">
                                My Staking (Metis)
                            </Text>
                        </Row>
                        <Row align="center">
                            <Text align="center" fontSize={34}>
                                {isViewer ? (
                                    '--'
                                ) : (
                                    <Value value={userTvlNumber} />
                                )}
                            </Text>
                        </Row>
                        <Row>
                            <Button
                                type="transparent"
                                className={style.withdraw}
                                disabled={!userTvlNumber || isViewer}
                                onClick={() => {
                                    setIsOpenWithdraw(true);
                                }}
                            >
                                {dac &&
                                dac.status === DAC_STATUS.dismiss &&
                                userRole !== ROLE.VIEWER
                                    ? 'Withdraw All'
                                    : 'Withdraw'}
                            </Button>
                            {/* {!dac ||
                                (dac.status === DAC_STATUS.active && (
                                    <Button
                                        onClick={() => {
                                            const joined = joinDacFirst(
                                                userRole,
                                                dac.name,
                                            );
                                            if (joined) {
                                                setIsOpenDeposit(true);
                                            }
                                        }}
                                    >
                                        Increase
                                    </Button>
                                ))} */}
                        </Row>
                    </div>
                    <div className={style.item}>
                        <Row align="center">
                            <Text fontSize={14} color="#fff" align="center">
                                Mining Power
                            </Text>
                        </Row>
                        <Row align="center">
                            <Text align="center" fontSize={34}>
                                {userPowerNum === -1 ? (
                                    '--'
                                ) : (
                                    <>
                                        <Value
                                            value={userPowerNum}
                                            decimals={0}
                                        />
                                    </>
                                )}
                            </Text>
                        </Row>
                    </div>
                    <div className={style.item}>
                        <Row align="center">
                            <Text
                                fontSize={14}
                                color="#fff"
                                align="center"
                                style={{ width: '150px' }}
                            >
                                % of Total Weighted Mining Power
                            </Text>
                            <TooltipsInfo
                                style={{ marginLeft: '4px' }}
                                text="% of Total Weighted Mining Power is not calculated anymore"
                            />
                        </Row>
                        <Row align="center">
                            <Text align="center" fontSize={34}>
                                --
                                {/* {isViewer || userShareWeight === -1 ? (
                                    '-'
                                ) : (
                                    <>
                                        <Value value={userShareWeight} />%
                                    </>
                                )} */}
                            </Text>
                        </Row>
                        {/* <Row>
                            <Text align="center" fontSize={14}>
                                Share of total weight:{' '}
                                {isViewer || userShareWeight === -1 ? (
                                    '-'
                                ) : (
                                    <>
                                        <Value value={userShareWeight} />%
                                    </>
                                )}
                            </Text>
                        </Row> */}
                    </div>
                </div>
                {canShare && dac && (
                    <>
                        <Row align="between">
                            <Title fontSize={24}>
                                Invited ({dac.membersCount})
                            </Title>
                            <Button
                                type="transparent"
                                className={style.inviteMore}
                                onClick={handleInvite}
                            >
                                Invite more+
                            </Button>
                        </Row>
                        <div className={cx(style.list, 'customTable')}>
                            {dacData && (
                                <MaterialTable
                                    tableRef={inviteTableRef}
                                    options={{
                                        search: false,
                                        showTitle: false,
                                        toolbar: false,
                                        sorting: false,
                                        paging: dacData.membersCount > 10,
                                        draggable: false,
                                        pageSize:
                                            dacData.membersCount < 10
                                                ? dacData.membersCount
                                                : 10,
                                    }}
                                    columns={[
                                        {
                                            title: '-',
                                            field: 'index',
                                            width: '20%',
                                        },
                                        { title: 'Address', field: 'id' },
                                    ]}
                                    data={async (query) => {
                                        const data = await getMembers({
                                            dacId: dacData.id,
                                            page: query.page,
                                            pageSize: query.pageSize,
                                        });

                                        const initCount =
                                            query.page * query.pageSize;

                                        let formData = data.members.map(
                                            (item, index) => {
                                                return {
                                                    index:
                                                        index + initCount + 1,
                                                    ...item,
                                                };
                                            },
                                        );

                                        return {
                                            data: formData,
                                            page: query.page,
                                            totalCount: data.membersCount,
                                        };
                                    }}
                                />
                            )}
                        </div>
                    </>
                )}
                {/* <Row align="between">
                    <Title fontSize={24}>Guide</Title>
                </Row> */}
                {/* <div className={style.guide}>
                    <Row>
                        <Text color="#fff" fontSize={14}>
                            xxxxxxxxxxxxxxxxxxxxxxxxxxxxx xxxxxxxxxxx
                            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        </Text>
                    </Row>
                    <Row align="center">
                        <Button>Mining Guide</Button>
                    </Row>
                </div> */}
            </div>
            <DacAside />
            <WithdrawModal
                isOpen={isOpenWithdraw}
                type="mining"
                onClose={() => {
                    setIsOpenWithdraw(false);
                }}
                totalAmount={userTVL}
                onSuccess={handleDepositOrWithdrawSuccess}
                dacData={dacData}
                userType={userRole}
                onMiningWithdrawAllTvl={handleMiningWithdrawAll}
                // needClaimFirst={!!userRewardNumber}
            />
            <WithdrawModal
                isOpen={isOpenClaim}
                type="miningClaim"
                onClose={() => {
                    setIsOpenClaim(false);
                }}
                dacData={dacData}
                onSuccess={handleDepositOrWithdrawSuccess}
                userType={userRole}
            />
            <DepositModal
                isOpen={isOpenDeposit}
                userType={userRole}
                type="increase"
                onClose={() => {
                    setIsOpenDeposit(false);
                }}
                dacData={dacData}
                tvl={userTVL}
                onSuccess={handleDepositOrWithdrawSuccess}
            />

            {dac && (
                <>
                    {/* <InviteModal
                        isOpen={isOpenInvite}
                        dacId={dac.id}
                        invitationCode={dac.invitationCode}
                        onClose={() => {
                            setIsOpenInvite(false);
                        }}
                    /> */}
                    <InviteMoreModal
                        isOpen={isOpenInviteMore}
                        onClose={() => {
                            setIsOpenInviteMore(false);
                        }}
                        dacId={dac.id}
                        invitationCode={dac.invitationCode}
                        inviteUrlCode={shareLinkCode}
                        dacName={dac.name}
                        dacPhoto={dac.photo}
                    />
                </>
            )}
        </AuthWrapper>
    );
}
