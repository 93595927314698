import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const DacCompanyContainer = styled.div`
    padding: 24px 30px;
    width: 364px;
    float: left;
    height: 743px;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-flow: column wrap;
`;

const CompanyTopContainer = styled.div`
    background: url('/static/images/ic_line.png') no-repeat center 72px;
    position: relative;
    width: 304px;
    height: 250px;
`;

const CompanyTitle = styled.div`
    margin: 0 auto;
    font-size: 18px;
    font-weight: 600;
    color: #00d8c1;
    line-height: 100px;
    width: 100px;
    height: 100px;
    text-align: center;
    background-image: url('/static/images/ic_tricolor.png');
    background-color: rgba(0, 216, 193, 0.05);
    background-repeat: no-repeat;
    background-position: center 70px;
    border-radius: 50%;
`;

const DescriptionContainer = styled.div`
    width: 80px;
    min-height: 102px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    line-height: 17px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center top;
    padding-top: 68px;
    position: absolute;

    &.des-ownership {
        background-image: url('/static/images/ic_ownership.png');
        left: 0;
        top: 84px;
    }
    &.des-governance {
        background-image: url('/static/images/ic_governance.png');
        left: 50%;
        margin-left: -40px;
        top: 132px;
    }

    &.des-management {
        background-image: url('/static/images/ic_management.png');
        right: 0;
        top: 84px;
    }
`;

const ScopeOfApplication = styled.div`
    margin: 0 auto;
    max-width: 286px;
    display: flex;
    align-items: top;
    justify-content: space-between;
    flex-flow: row wrap;
`;
const InstalledApps = styled.h2`
    width: 240px;
    padding-top: 16px;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    margin: 0 auto 20px;
    text-align: center;
    font-weight: 400;
    border-top: 1px dashed #c32e66;
`;
const ScopeOfApplicationItem = styled.div`
    display: block;
    width: 82px;
    text-align: center;
    margin-bottom: 20px;
`;
const ScopeOfApplicationCard = styled.div`
    margin-bottom: 20px;
    display: flex;
    width: 82px;
    height: 82px;
    color: #fff;
    align-items: center;
    justify-content: center;
    cursor: default;

    &.theme-red {
        background: linear-gradient(90deg, #96237f 0%, #d23169 100%);
    }

    &.theme-purple {
        background: linear-gradient(90deg, #530b7f 0%, #8b1ca6 100%);
    }

    &.theme-cyan {
        background: linear-gradient(270deg, #57b7bb 0%, #1d7ea5 100%);
    }

    .card-body {
        h3 {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: 400;
        }

        small {
            letter-spacing: -1px;
            font-size: 12px;
        }

        p {
            margin: 0;
            padding: 0;
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            line-height: 20px;
        }
    }
`;
const ScopeOfApplicationState = styled.span`
    margin: 0 auto;
    display: block;
    width: 76px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.21);
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    line-height: 30px;

    &.is-tick {
        border: 1px solid #9a237d;
    }

    img {
        width: 22px;
        height: 22px;
        vertical-align: -6px;
    }

    small {
        letter-spacing: -1px;
        line-height: 26px;
        vertical-align: top;
    }
    em {
        transform: scale(0.8);
        font-style: normal;
    }
`;

const MoreToComeTip = styled.p`
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: flex-end;
    height: 17px;
    font-size: 16px;
    font-weight: 400;
    color: #63d8cd;
    line-height: 22px;
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        customWidth: {
            maxWidth: 262,
            backgroundColor: '#222222',
            lineHeight: '17px',
        },
    }),
);
const cadCompanySideNoteLists = [
    {
        text: 'Allow you to issue tokens, raise fund and manage your assets',
        iSamll: false,
        isImg: true,
        className: 'theme-red',
        title: 'Finance',
        subTitle: '',
    },
    {
        text: 'Allow members to vote on topics to reach consensus',
        iSamll: false,
        isImg: false,
        className: 'theme-purple',
        title: 'Vote',
        subTitle: '',
    },
    {
        text: 'Manage community collaborations',
        iSamll: false,
        isImg: false,
        className: 'theme-cyan',
        title: 'Task',
        subTitle: 'Management',
    },
    {
        text: 'Share community wisdom',
        iSamll: false,
        isImg: false,
        className: 'theme-purple',
        title: 'Knowledge',
        subTitle: 'Management',
    },
    {
        text: 'Ways to coordinate community collaborations',
        iSamll: true,
        isImg: false,
        className: 'theme-cyan',
        title: 'Communication',
        subTitle: '',
    },
    {
        text: 'Allow members to get paid based on contribution',
        iSamll: false,
        isImg: false,
        className: 'theme-red',
        title: 'Payroll',
        subTitle: '',
    },
];

export default function DacCompany() {
    return (
        <DacCompanyContainer className="dac-company-container">
            <CompanyTopContainer>
                <CompanyTitle>Company</CompanyTitle>
                <DescriptionContainer className="des-ownership">
                    Decentralized Ownership
                </DescriptionContainer>
                <DescriptionContainer className="des-governance">
                    Decentralized Governance
                </DescriptionContainer>
                <DescriptionContainer className="des-management">
                    Decentralized Management
                </DescriptionContainer>
            </CompanyTopContainer>
            <ScopeOfApplication>
                <InstalledApps>Installed Apps</InstalledApps>
                {cadCompanySideNoteLists.map((item) => (
                    <ScopeConItem {...item} />
                ))}
            </ScopeOfApplication>
            <MoreToComeTip>More to come</MoreToComeTip>
        </DacCompanyContainer>
    );
}

interface Props {
    text?: string | React.ReactElement;
    className?: string;
    iSamll?: boolean;
    isImg?: boolean;
    placement?: 'top' | 'bottom';
    title?: string;
    subTitle?: string;
}

function ScopeConItem({
    text = '',
    className,
    iSamll,
    isImg,
    placement = 'top',
    title = '',
    subTitle = '',
}: Props) {
    const classes = useStyles();
    return (
        <ScopeOfApplicationItem>
            <Tooltip
                title={text}
                placement={placement}
                interactive
                classes={{ tooltip: classes.customWidth }}
            >
                <ScopeOfApplicationCard className={className}>
                    <div className="card-body">
                        {iSamll ? (
                            <h3>
                                <small>{title}</small>
                            </h3>
                        ) : (
                            <h3>{title}</h3>
                        )}
                        <p>{subTitle}</p>
                    </div>
                </ScopeOfApplicationCard>
            </Tooltip>

            {isImg ? (
                <ScopeOfApplicationState className="is-tick">
                    <img src="/static/images/ic_tick.png" alt="is tick" />
                </ScopeOfApplicationState>
            ) : (
                <ScopeOfApplicationState>
                    <small>Coming Soon</small>
                </ScopeOfApplicationState>
            )}
        </ScopeOfApplicationItem>
    );
}
