import React, { useCallback, useMemo, useState } from 'react';

import Modal from '@/components/Modal';
import Row from '@/components/Row';
import Text, { Title } from '@/components/Text';
import Button from '@/components/Button';

import style from './index.module.scss';

export interface Props {
    isOpen: boolean;
    onClose(): void;
    onConfirm(): void;
}

export default function DisConnectAccountModal({
    isOpen,
    onClose,
    onConfirm,
}: Props) {
    const handleConfirm = useCallback(() => {
        onConfirm();
        onClose();
    }, [onClose, onConfirm]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <div className={style.wrapper}>
                    <Row align="center" customClass={style.modalTitle}>
                        <Title align="center">Disconnect from Polis</Title>
                    </Row>
                    <Row align="center" customClass={style.modalPromptText}>
                        <Text fontSize={14} fontWeight={400}>
                            You can't experience our product without connecting
                            your Polis.
                        </Text>
                    </Row>
                    <Row align="center" customClass={style.modalActionBar}>
                        <Button
                            onClick={onClose}
                            size="middle"
                            type="transparent"
                            className={style.connectBtn}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleConfirm();
                            }}
                            size="middle"
                            className={style.connectBtn}
                        >
                            Confirm
                        </Button>
                    </Row>
                </div>
            </Modal>
        </>
    );
}
