import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dac, DEFAULT_METIS_LOGO, ROLE } from '@/constants';
// import type { RootState } from '../index';

// Define a type for the slice state
interface State {
    dac?: Dac;
    loadingDac: boolean;
    dacLink: string;
    dacLinkLoading: boolean;
    shareLinkCode: string;
    userRole: ROLE;
}

// Define the initial state using that type
const initialState: State = {
    dac: undefined,
    loadingDac: false,
    dacLink: '',
    dacLinkLoading: false,
    shareLinkCode: '',
    userRole: ROLE.VIEWER,
};

export const dac = createSlice({
    name: 'dac',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setDac: (state, action: PayloadAction<Dac | undefined>) => {
            state.dac = action.payload;
            if (state.dac && !state.dac.photo) {
                state.dac.photo = DEFAULT_METIS_LOGO;
            }
        },
        setDacRole: (state, action: PayloadAction<ROLE>) => {
            if (state.dac) {
                state.userRole = action.payload;
            }
        },
        addMembersCount: (state, action: PayloadAction<number>) => {
            if (state.dac) {
                state.dac.membersCount += action.payload;
            }
        },
        setDacLinkLoading: (state, action: PayloadAction<boolean>) => {
            state.dacLinkLoading = action.payload;
        },
        setDacLink: (state, action: PayloadAction<string>) => {
            state.dacLink = action.payload;
        },
        setShareLinkCode: (state, action: PayloadAction<string>) => {
            state.shareLinkCode = action.payload;
        },
        setDacLoading: (state, action: PayloadAction<boolean>) => {
            state.loadingDac = action.payload;
        },
        reset: (state) => {
            if (state.dac) {
                state.dac = undefined;
                state.dacLink = '';
                state.shareLinkCode = '';
                state.dacLinkLoading = false;
                state.userRole = ROLE.VIEWER;
            }
        },
        setAllDacData: (
            state,
            action: PayloadAction<{
                dac: Dac | undefined;
                dacLink: string | undefined;
                shareCode: string | undefined;
            }>,
        ) => {
            if (action.payload.dac) {
                state.dac = action.payload.dac;
                state.dacLink = action.payload.dacLink || '';
                state.shareLinkCode = action.payload.shareCode || '';
            }
        },
    },
});

export const {
    setDac,
    setDacRole,
    addMembersCount,
    reset,
    setDacLink,
    setDacLinkLoading,
    setShareLinkCode,
    setDacLoading,
    setAllDacData,
} = dac.actions;

export default dac.reducer;
