import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import MaterialTable from 'material-table';
import cx from 'classnames';
import getLuckyDrawList from '@/graphql/get-luckydraw-list';
import moment from 'moment';
import BigNumber from 'bignumber.js';

interface Props {
    hidePaging?: boolean;
}

export default function LuckyDraw({ hidePaging }: Props) {
    const [totalDAC, setTotal] = useState(10);
    async function getTotalDac() {
        const res = await getLuckyDrawList({
            page: 1,
            pageSize: 1,
        });
        setTotal(res.total);
    }

    useEffect(() => {
        getTotalDac();
    }, []);

    return (
        <div className={cx(style.list, 'customTable')}>
            <MaterialTable
                localization={{
                    body: {
                        emptyDataSourceMessage: <div>No records</div>,
                    },
                }}
                options={{
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    sorting: false,
                    paging: !hidePaging && totalDAC > 10,
                    pageSize: totalDAC < 10 ? totalDAC : 10,
                }}
                columns={[
                    {
                        title: 'Time',
                        field: 'index',
                        render(item) {
                            return `Week ${item.index}`;
                        },
                    },
                    {
                        title: 'Lucky DAC',
                        field: 'dac',
                        render(item) {
                            return item.dac.name;
                        },
                    },

                    {
                        title: 'Percentage',
                        field: 'bonus',
                        render(item) {
                            return `${item.bonus}%`;
                        },
                    },
                    {
                        title: 'Token Reward',
                        field: 'tokenReward',
                        render(item) {
                            return `${item.tokenReward} METIS`;
                        },
                    },
                    {
                        title: 'Members',
                        field: 'dacMembersCount',
                        render(item) {
                            return `${item.dacMembersCount}`;
                        },
                    },
                    {
                        title: 'Average',
                        field: 'dac',
                        render(item) {
                            return `${new BigNumber(
                                item.tokenReward / item.dacMembersCount,
                            ).toFixed(3)} METIS`;
                        },
                    },
                ]}
                data={async (query) => {
                    const res = await getLuckyDrawList({
                        page: query.page,
                        pageSize: query.pageSize,
                    });
                    const initCount = query.page * query.pageSize;
                    let formData = res.data.map((item, index) => {
                        return {
                            index: index + initCount + 1,
                            ...item,
                        };
                    });

                    return {
                        data: formData,
                        page: query.page,
                        totalCount: res.total,
                    };
                }}
            />
        </div>
    );
}
