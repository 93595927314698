import React, { useEffect, useMemo, useState } from 'react';

import Countdown, { zeroPad } from 'react-countdown';

import Row from '@/components/Row';
import Text, { Title } from '@/components/Text';

import style from './index.module.scss';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import RouterConfig from '@/constants/router';
import { ROLE } from '@/constants';
import Value from '@/components/Value';

interface Props {
    date?: string | number;
    tvl: number;
    status: number; // 0: 未开始 1: 已开始 2: 已解散
    dacId: number;
    dacLink: string;
    role: ROLE;
}

export default function DacMining(props: Props) {
    const history = useHistory();
    const [countDownOver, setCountDownOver] = useState(false);
    const { date = '--', status = 0, tvl, dacId, dacLink, role } = props;

    const dateString = useMemo(() => {
        return moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
    }, [date]);

    const isDismiss = useMemo(() => {
        return status === 2;
    }, [status]);

    useEffect(() => {
        if (status !== 0) {
            setCountDownOver(true);
        } else {
            setCountDownOver(false);
        }
    }, [status]);

    return (
        <>
            <div className={style.dacMiningWrapper}>
                <div className={style.poolMenu}>
                    <img src="/static/images/ic_Pool.png" alt="menu pool" />
                </div>
                <div className={style.dacMiningContent}>
                    <div className={style.innerContainer}>
                        <Row align="between" customClass={style.dacMiningTitle}>
                            <Title
                                fontSize={38}
                                lineHeight={'53px'}
                                fontWeight={600}
                            >
                                DAC Mining
                            </Title>
                        </Row>
                        {status === 0 && !countDownOver && (
                            <div className="poolOpenDate">
                                <Row customClass={style.poolOpenDateTips}>
                                    <Text fontSize={16} lineHeight={'22px'}>
                                        *Pool will open In {dateString} (UTC)
                                    </Text>
                                </Row>
                                <div className={style.cutdown}>
                                    <Countdown
                                        date={
                                            date ? new Date(date) : new Date()
                                        }
                                        intervalDelay={0}
                                        precision={3}
                                        onComplete={() => {
                                            setCountDownOver(true);
                                        }}
                                        renderer={(props) => (
                                            <ul className={style.items}>
                                                <li>
                                                    <span
                                                        className={style.number}
                                                    >
                                                        <em>
                                                            {zeroPad(
                                                                props.days,
                                                            ) || '00'}
                                                        </em>
                                                    </span>
                                                    <span
                                                        className={style.label}
                                                    >
                                                        DAYS
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className={style.div}>
                                                        :
                                                    </span>
                                                </li>
                                                <li>
                                                    <span
                                                        className={style.number}
                                                    >
                                                        <em>
                                                            {zeroPad(
                                                                props.hours,
                                                            ) || '00'}
                                                        </em>
                                                    </span>
                                                    <span
                                                        className={style.label}
                                                    >
                                                        HRS
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className={style.div}>
                                                        :
                                                    </span>
                                                </li>
                                                <li>
                                                    <span
                                                        className={style.number}
                                                    >
                                                        <em>
                                                            {zeroPad(
                                                                props.minutes,
                                                            ) || '00'}
                                                        </em>
                                                    </span>
                                                    <span
                                                        className={style.label}
                                                    >
                                                        MIN
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className={style.div}>
                                                        :
                                                    </span>
                                                </li>
                                                <li>
                                                    <span
                                                        className={style.number}
                                                    >
                                                        <em>
                                                            {zeroPad(
                                                                props.seconds,
                                                            ) || '00'}
                                                        </em>
                                                    </span>
                                                    <span
                                                        className={style.label}
                                                    >
                                                        SEC
                                                    </span>
                                                </li>
                                                {props.total}
                                            </ul>
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                        {status === 1 && countDownOver && (
                            <Row align="between" customClass={style.apyInfo}>
                                <Text
                                    fontSize={16}
                                    align="left"
                                    lineHeight={'22px'}
                                >
                                    TVL(Total Value Locked):{' '}
                                    {tvl === -1 ? (
                                        '--'
                                    ) : (
                                        <>
                                            <Value value={tvl} />
                                        </>
                                    )}{' '}
                                    Metis
                                </Text>
                            </Row>
                        )}
                        {isDismiss && (
                            <Row
                                align="center"
                                customClass={style.dissolutionInfo}
                            >
                                <img
                                    src="/static/images/ic_remind.png"
                                    alt="icon remind"
                                    className={style.iconRemind}
                                />
                                <Text fontSize={14} lineHeight={'20px'}>
                                    {role === ROLE.VIEWER
                                        ? 'This DAC has been dissolved and can no longer participate in mining to receive rewards.'
                                        : 'Your DAC has been dissolved by the DAC Owner, and you are no longer participating in mining'}
                                </Text>
                            </Row>
                        )}
                    </div>
                </div>
                {!!dacId && (
                    <div
                        className={style.nextMenu}
                        onClick={() => {
                            history.push(
                                RouterConfig.getDacPool(dacId, dacLink),
                                {
                                    isopen: true,
                                },
                            );
                        }}
                    >
                        <img src="/static/images/ic_next.png" alt="menu pool" />
                    </div>
                )}
            </div>
        </>
    );
}
