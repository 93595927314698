import { useAppDispatch, useAppSelector } from '@/state/hooks';
import axios from 'axios';
import { setAvatar } from '@/state/user';

const host = process.env.REACT_APP_APP_SERVER_PREFIX;

export default function useUserAvatar() {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const { client } = useAppSelector((state) => state.metisMiddlewareClient);
    const accessToken = client ? client.accessToken : undefined;
    async function getUserAvatar() {
        if (user.address && accessToken) {
            try {
                const res = await axios({
                    method: 'GET',
                    url: `${host}/api/store/get_avatar`,
                    headers: {
                        'access-token': accessToken,
                    },
                    params: {
                        eth_address: user.address.toLowerCase(),
                    },
                });
                if (res.data && res.data.code === 200 && res.data.data) {
                    dispatch(setAvatar(res.data.data.avatar));
                }
            } catch (e) {
                console.error('getUserAvatar error');
                console.error(e);
            }
        }

        return undefined;
    }

    async function updateUserAvatar(photo: string) {
        if (user.address && accessToken) {
            try {
                const res = await axios({
                    method: 'POST',
                    url: `${host}/api/store/store_avatar`,
                    headers: {
                        'access-token': accessToken,
                    },
                    data: {
                        eth_address: user.address.toLowerCase(),
                        avatar: photo,
                    },
                });
                if (res.data && res.data.code === 200 && res.data.data) {
                    dispatch(setAvatar(photo));

                    return true;
                }
            } catch (e) {
                console.error('updateUserAvatar error');
                console.error(e);
            }
        }

        return false;
    }

    return {
        getUserAvatar,
        updateUserAvatar,
    };
}
