// import { SESSION_KEY } from '@/constants';
import { AUTH_PARAMS } from '@/constants/storage';
import { Oauth2Client } from '@metis.io/middleware-client';
import storage from './storage';
import { ChainId } from '@/constants';
import { getPolisClient } from '.';

export const login = async (chainId: ChainId) => {
    const { REACT_APP_POLIS_APP_ID } = process.env;
    const polisClient = await getPolisClient(chainId);
    let search = '';
    if (window.location.search) {
        let params = new URLSearchParams(window.location.search);
        if (params.get('code')) {
            params.delete('code');
        }
        const searchParamsString = params.toString();
        search = searchParamsString ? `?${searchParamsString}` : '';
    }

    polisClient.startOauth2({
        appId: REACT_APP_POLIS_APP_ID as string,
        returnUrl: `https://portal.metis.io${window.location.pathname}${search}`,
        switchAccount: true,
    });
    // window.sessionStorage.setItem(
    //     SESSION_KEY.loginFrom,
    //     `${window.location.pathname}${window.location.search}`,
    // );
};

export const logout = async () => {
    const { REACT_APP_POLIS_APP_ID } = process.env;

    const Auth = storage.get(AUTH_PARAMS);
    if (!Auth) {
        console.error('Auth failed, please connect your account');
        return;
    }
    const oauth2Client = new Oauth2Client();
    try {
        await oauth2Client.logout(
            REACT_APP_POLIS_APP_ID as string,
            Auth.accessToken,
            Auth.refreshToken,
        );
    } catch (e) {
        console.error('oauthclient logout failed');
        console.error(e);
    }
};
