import { useMemo } from 'react';
import { useAppSelector } from '@/state/hooks';
import BigNumber from 'bignumber.js';
import {
    CONTRACT_DOMAIN,
    DOMAIN_CONFIG,
    TxActStatus,
    TxStatus,
    ROLE,
    Dac,
    DAC_STATUS,
    ADDRESS_ZERO,
    REMAINING_GAS,
    BridgeUrl,
    PID,
} from '@/constants';
import getDacByIdFromGraphql from '@/graphql/get-dac-by-id';
import Modal from '@/utils/components/modal';

import Web3 from 'web3';
import { userInfo } from 'os';
interface ICreateDacParams {
    name: string;
    introduction: string;
    photo: string;
    amount: number;
}

export default function useDacClient() {
    const user = useAppSelector((state) => state.user);
    const { client, chainId } = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );

    async function userToDAC(): Promise<number | undefined> {
        if (client && user.address) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'userToDAC',
                    [user.address],
                    true,
                );

                if (res) {
                    const data = Number(res.result);
                    if (typeof data === 'number') {
                        return data;
                    }
                }
            } catch (error) {
                console.error('userToDAC error');
                console.error(error);
            }
        }

        return undefined;
    }

    async function getMemberCountById(id: number) {
        if (client && user.address) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'getDACMemberCount',
                    [id],
                    true,
                );

                if (res && res.result) {
                    return Number(res.result);
                }
            } catch (error) {
                console.error('getMemberCountById error');
                console.error(error);
            }
        }
        return 0;
    }

    async function getInviteCode(id: number) {
        if (client && user.address) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'DACToInvitationCode',
                    [id],
                    true,
                );

                if (res && res.result) {
                    if (res.result && res.result.length) {
                        return Web3.utils.bytesToHex(res.result);
                    }
                }
            } catch (error) {
                console.error('getInviteCode error');
                console.error(error);
            }
        }
        return '';
    }

    async function getUserRole(id: number, creator: string) {
        if (client && user.address) {
            const userDac = await userToDAC();
            if (user.address && userDac && userDac === id) {
                if (user.address.toLowerCase() === creator.toLowerCase()) {
                    return ROLE.CREATOR;
                } else {
                    return ROLE.MEMBER;
                }
            }
        }

        return ROLE.VIEWER;
    }

    async function getDacInfoById(id: number): Promise<Dac | undefined> {
        if (client) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'pool',
                    [id],
                    true,
                );

                if (res && res.result) {
                    const dacData = res.result;
                    const creator = dacData[1];
                    const membersCount = await getMemberCountById(id);
                    const inviteCode = await getInviteCode(id);
                    return {
                        id: Number(dacData[0]),
                        creator: creator,
                        name: String(dacData[2]),
                        introduction: String(dacData[3]),
                        category: String(dacData[4]),
                        photo: String(dacData[5]),
                        createTime: Number(dacData[7]),
                        status:
                            Number(dacData[8]) === 1
                                ? DAC_STATUS.active
                                : DAC_STATUS.dismiss,
                        membersCount,
                        invitationCode: inviteCode,
                    };
                }
            } catch (error) {
                console.error('getDacInfoById error');
                console.error(error);
            }
        }

        return undefined;
    }

    async function getDacInfoByIdFromContractFirst(
        id: number,
    ): Promise<Dac | undefined> {
        let res = await getDacInfoById(id);
        if (!res) {
            // 先拿合约中的实时数据，如果用户没登录，再拿graph
            res = await getDacByIdFromGraphql(id);
        }
        return res;
    }

    async function estimateCreateDacGasFee(data: ICreateDacParams) {
        const { name, introduction, photo, amount } = data;
        if (user.address && client) {
            try {
                const res = await client.estimateGasAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'createDAC',
                    [
                        name,
                        introduction,
                        '',
                        photo,
                        new BigNumber(amount).shiftedBy(18).toFixed(),
                    ],
                    true,
                );

                if (res) {
                    return new BigNumber(res.fee_num);
                }
            } catch (e) {
                console.error('estimateCreateDacGasFee error');
                console.error(e);
            }
        }

        return new BigNumber(0);
    }

    async function createDac(data: ICreateDacParams) {
        const { name, introduction, photo, amount } = data;

        if (client && user.address) {
            try {
                // if (user.metisBalance.isLessThan(data.amount + REMAINING_GAS)) {
                //     Modal({
                //         title: 'Sorry',
                //         text: `The current Metis Token balance ${user.metisBalance.toFixed(
                //             3,
                //         )} on your account is not sufficient to support this execution. Please leave at lease ${REMAINING_GAS} METIS to support this execution`,
                //         confirmButtonProps: {
                //             text: 'Bridge Metis Token',
                //             onClick() {
                //                 window.open(BridgeUrl);
                //             },
                //         },
                //         cancelButtonProps: {
                //             text: 'Cancel',
                //         },
                //     });
                //     return;
                // }
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'createDAC',
                    [
                        name,
                        introduction,
                        '',
                        photo,
                        new BigNumber(amount).shiftedBy(18).toFixed(),
                    ],
                    true,
                );

                if (res && res.status === TxStatus.SUCCESS) {
                    const res = await userToDAC();
                    return res;
                }
            } catch (error) {
                console.error('createDac error');
                console.error(error);
            }
        }
        return undefined;
    }

    async function dismissDAC(id: number) {
        if (client && user.address) {
            try {
                const stakedMetisRes = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
                    chainId,
                    'userInfo',
                    [PID, user.address],
                    true,
                );

                if (stakedMetisRes && stakedMetisRes.result) {
                    const data = stakedMetisRes.result;
                    const stakedMetis = data[0];
                    // const withdrawRes = await client.sendTxAsync(
                    //     DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
                    //     chainId,
                    //     'withdraw',
                    //     [
                    //         ADDRESS_ZERO,
                    //         PID,
                    //         new BigNumber(stakedMetis).toFixed(),
                    //     ],
                    //     true,
                    // );
                    const withdrawRes = await client.sendTxAsync(
                        DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.MINING].domain,
                        chainId,
                        'emergencyWithdraw',
                        [PID],
                        true,
                    );

                    if (
                        withdrawRes &&
                        withdrawRes.status === TxStatus.SUCCESS
                    ) {
                        return true;
                    }
                }
            } catch (error) {
                console.error('dismissDac error');
                console.error(error);
            }
        }

        return false;
    }

    async function estimateJoinDacGasFee(
        dacId: number,
        amount: BigNumber,
        inviteCode: string,
    ) {
        if (user.address && client) {
            try {
                const res = await client.estimateGasAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'joinDAC',
                    [dacId, amount.shiftedBy(18).toFixed(), inviteCode],
                    true,
                );

                if (res) {
                    return new BigNumber(res.fee_num);
                }
            } catch (e) {
                console.error('estimateJoinDacGasFee error');
                console.error(e);
            }
        }

        return new BigNumber(0);
    }

    async function joinDAC(
        dacId: number,
        amount: BigNumber,
        inviteCode: string,
    ) {
        if (client && user.address) {
            try {
                // if (user.metisBalance.minus(REMAINING_GAS).isLessThan(amount)) {
                //     Modal({
                //         title: 'Sorry',
                //         text: `The current Metis Token balance ${user.metisBalance.toFixed(
                //             3,
                //         )} on your account is not sufficient to support this execution. Please leave at lease ${REMAINING_GAS} METIS to support this execution`,
                //         confirmButtonProps: {
                //             text: 'Bridge Metis Token',
                //             onClick() {
                //                 window.open(BridgeUrl);
                //             },
                //         },
                //         cancelButtonProps: {
                //             text: 'Cancel',
                //         },
                //     });
                //     return false;
                // }
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'joinDAC',
                    [dacId, amount.shiftedBy(18).toFixed(), inviteCode],
                    true,
                );

                if (res && res.status === TxStatus.SUCCESS) {
                    return true;
                }
            } catch (error) {
                console.error('joinDac error');
                console.error(error);
            }
        }

        return false;
    }

    async function getPoolLength() {
        if (client) {
            try {
                const res = await client.sendTxAsync(
                    DOMAIN_CONFIG[chainId][CONTRACT_DOMAIN.DAC].domain,
                    chainId,
                    'getPoolLength',
                    [],
                    true,
                );

                if (res && res.result) {
                    return Number(res.result);
                }
            } catch (e) {
                console.error(e);
                console.error('getPoolLength error');
            }
        }

        return 0;
    }

    const clientWithUserReady = useMemo(() => {
        return !!client && !!user && !!user.connected;
    }, [client, user]);

    const clientReady = useMemo(() => {
        return !!client;
    }, [client]);

    return {
        createDac,
        dismissDAC,
        userToDAC,
        joinDAC,
        getDacInfoById: getDacInfoByIdFromContractFirst,
        getUserRole,
        getPoolLength,
        clientWithUserReady,
        clientReady,
        estimateJoinDacGasFee,
        estimateCreateDacGasFee,
    };
}
