import { gql } from '@apollo/client';
import client from './index';

interface DacOverview {
    userCount: number;
    activeDacCount: number;
    totalMint: number;
}

export default function getDacOverview(): Promise<DacOverview> {
    return new Promise((resolve, reject) => {
        client
            .query<{
                dacoverviews: DacOverview[];
            }>({
                fetchPolicy: 'no-cache',
                query: gql`
                    query dacoverviews {
                        dacoverviews {
                            userCount
                            activeDacCount
                            totalMint
                        }
                    }
                `,
            })
            .then((res) => {
                const { data } = res;
                if (data.dacoverviews && data.dacoverviews.length) {
                    const dacoverview = data.dacoverviews[0];
                    resolve({
                        userCount: Number(dacoverview.userCount),
                        activeDacCount: Number(dacoverview.activeDacCount),
                        totalMint: Number(dacoverview.totalMint),
                    });
                }

                resolve({
                    userCount: 0,
                    activeDacCount: 0,
                    totalMint: 0,
                });
            })
            .catch((e: any) => {
                console.error(e);
                resolve({
                    userCount: 0,
                    activeDacCount: 0,
                    totalMint: 0,
                });
            });
    });
}
