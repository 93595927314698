import { USER_INFO, AUTH_DAC } from '@/constants/storage';
import storage from '@/utils/storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { ROLE, Dac, DEFAULT_METIS_LOGO } from '@/constants';
// import type { RootState } from '../index';

// Define a type for the slice state

interface UserState {
    name?: string;
    email?: string;
    address?: string;
    avatar?: string;
    connectError: string;
    connected: boolean;
    metisBalance: BigNumber;
    dac?: Dac;
    dacUserRole: ROLE;
    dacLink: string;
    shareLinkCode: string;
    loadingDac?: boolean;
    isOpenConnectModal: boolean;
    loadingUserInfo: boolean;
}

const userInfo = storage.get(USER_INFO);

// Define the initial state using that type
const initialState: UserState = {
    name: userInfo && userInfo.display_name ? userInfo.display_name : '',
    email: userInfo && userInfo.email ? userInfo.email : '',
    address: userInfo && userInfo.address ? userInfo.address : '',
    avatar: '',
    connectError: '',
    connected: !!userInfo,
    metisBalance: new BigNumber(0),
    // role: userInfo && userInfo.role ? userInfo.role : ROLE.GENERAL_USER,
    dac: undefined,
    dacUserRole: ROLE.VIEWER,
    loadingDac: !!userInfo,
    isOpenConnectModal: false,
    loadingUserInfo: false,
    dacLink: '',
    shareLinkCode: '',
};

export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setAvatar: (state, action: PayloadAction<string>) => {
            state.avatar = action.payload;
        },
        setConnectError: (state, action: PayloadAction<string>) => {
            state.connectError = action.payload;
        },
        setConnected: (state, action: PayloadAction<boolean>) => {
            state.connected = action.payload;
        },
        setMetisBalance: (state, action: PayloadAction<BigNumber>) => {
            state.metisBalance = action.payload;
        },
        setUserDac: (state, action: PayloadAction<Dac | undefined>) => {
            state.dac = action.payload;
            if (state.dac && !state.dac.photo) {
                state.dac.photo = DEFAULT_METIS_LOGO;
            }
        },
        setIsOpenConnectModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenConnectModal = action.payload;
        },
        setLoadingDac: (state, action: PayloadAction<boolean>) => {
            state.loadingDac = action.payload;
        },
        setLoadingUserInfo: (state, action: PayloadAction<boolean>) => {
            state.loadingUserInfo = action.payload;
        },
        setDacLink: (state, action: PayloadAction<string>) => {
            state.dacLink = action.payload;
        },
        setShareLinkCode: (state, action: PayloadAction<string>) => {
            state.shareLinkCode = action.payload;
        },
        setDacUserRole: (state, action: PayloadAction<ROLE>) => {
            state.dacUserRole = action.payload;
        },
        setClearLoginInfo: (state) => {
            state.connected = false;
            state.name = '';
            state.address = '';
            state.email = '';
            state.dac = undefined;
            state.dacUserRole = ROLE.VIEWER;
            state.dacLink = '';
            state.shareLinkCode = '';
            state.loadingDac = false;
        },
    },
});

export const {
    setAddress,
    setConnectError,
    setConnected,
    setEmail,
    setMetisBalance,
    setName,
    setUserDac,
    setIsOpenConnectModal,
    setAvatar,
    setLoadingDac,
    setLoadingUserInfo,
    setDacLink,
    setShareLinkCode,
    setDacUserRole,
    setClearLoginInfo,
} = userSlice.actions;

export default userSlice.reducer;
