export const DATA_DOES_NOT_EXIST = -1;
export const REMAINING_GAS = 0.1;
export const DEPOSIT_MIN = 10;
export const DEPOSIT_MAX = 2000;
export const DEFAULT_LOGO =
    'https://metis-dac.s3.amazonaws.com/user_avatars/4d89df36-2f0d-43c5-997d-1daeb33a2559.png';
export const STATIC_LOGO = '/static/images/logo.png';

export const STATE_FREE_IDENTIFY = 0;
export const MAX_TAGS = 1;
export const LIMIT = 5;

export enum CONTRACT_DOMAIN {
    METIS_TOKEN = 'METIS_TOKEN',
    MINING = 'MINING',
    DAC_RECORDER = 'DAC_RECORDER',
    VALUT = 'VALUT_DOMAIN',
    DAC = 'DAC',
}

export enum ROLE {
    CREATOR = 'creator',
    MEMBER = 'member',
    VIEWER = 'viewer',
}

export enum ChainId {
    testnet = 588,
    mainnet = 1088,
}

export enum ENV {
    testnet = 'testnet',
    mainnet = 'mainnet',
}

export const NETWORK_NAME = {
    testnet: 'Metis Stardust',
    mainnet: 'Metis Andromeda',
};

export const CHAIN_ENV = {
    [ChainId.testnet]: ENV.testnet,
    [ChainId.mainnet]: ENV.mainnet,
};

export const DOMAIN_CONFIG = {
    [ChainId.testnet]: {
        [CONTRACT_DOMAIN.DAC]: {
            domain: 'dac',
        },
        [CONTRACT_DOMAIN.METIS_TOKEN]: {
            domain: 'metis',
        },
        [CONTRACT_DOMAIN.MINING]: {
            domain: 'dac-mining',
        },
        [CONTRACT_DOMAIN.DAC_RECORDER]: {
            domain: 'dac-recorder',
        },
        [CONTRACT_DOMAIN.VALUT]: {
            domain: 'dac-valut',
        },
    },
    [ChainId.mainnet]: {
        [CONTRACT_DOMAIN.DAC]: {
            domain: 'dac',
        },
        [CONTRACT_DOMAIN.METIS_TOKEN]: {
            domain: 'metis',
        },
        [CONTRACT_DOMAIN.MINING]: {
            domain: 'dac-mining',
        },
        [CONTRACT_DOMAIN.DAC_RECORDER]: {
            domain: 'dac-recorder',
        },
        [CONTRACT_DOMAIN.VALUT]: {
            domain: 'dac-valut',
        },
    },
};

export const ENV_CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID) as ChainId;

export const PID = ENV_CHAIN_ID === ChainId.mainnet ? 1 : 0;

export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

export enum TxActStatus {
    SUCCESS = 'SUCCESS',
}

export enum TxStatus {
    SUCCESS = 'SUCCESS',
}

export const WalletLink = 'https://polis.metis.io/#/dashboard';
export const REACT_APP_EMAIL = 'bugreport@metis.io';

export enum DAC_STATUS {
    dismiss = 0,
    active = 1,
}
export interface Dac {
    id: number;
    creator: string;
    name: string;
    introduction: string;
    category: string;
    // url?: string;
    photo: string;
    createTime: number;
    status: DAC_STATUS;
    membersCount: number;
    invitationCode: string;
}

export const BridgeUrl = process.env.REACT_APP_BRIDGE_URL;

export const SESSION_KEY = {
    vaultPrevPage: 'vaultPrevPage',
};

export const DAC_MEMBERS_MIN = 20;

export const DAC_URL = 'dac.metis.io';

export const DEFAULT_METIS_LOGO = '/metis.svg';

export const POLIS_HOST = 'https://polis.metis.io';
