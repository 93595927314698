import { useEffect } from 'react';

import { HttpClient } from '@metis.io/middleware-client';
import axios from 'axios';

import { useAppDispatch, useAppSelector } from '@/state/hooks';
import {
    setAddress,
    setConnected,
    setEmail,
    setLoadingUserInfo,
    setName,
} from '@/state/user';
import { setClient } from '@/state/metisMiddlewareClient';

import { AUTH_PARAMS, USER_INFO, AUTH_DAC } from '@/constants/storage';
import { REQUEST_STATUS_SUCCESS } from '@/constants/request';
import storage from '@/utils/storage';
import { getPolisClient, getUrlVars } from '@/utils';
import { clearUserLoginInfoHooks } from '@/state/user/hooks';
import AlertFunc from '@/utils/components/alert';

const authParams = storage.get(AUTH_PARAMS);
const userInfo = storage.get(USER_INFO);
interface HttpParams {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    expireAt: number;
}

export default function PresetAuth() {
    const dispatch = useAppDispatch();
    const clearLoginInfo = clearUserLoginInfoHooks();
    const { chainId } = useAppSelector((state) => state.metisMiddlewareClient);

    function clearAuthInfoTimeout(authParams: HttpParams) {
        if (!authParams) return;
        const now = new Date().getTime();
        const expireAt = authParams.expireAt;
        const leftTime = expireAt - now;
        console.warn(`Auth status will expire after ${leftTime / 1000}s`);
        setTimeout(() => {
            clearLoginInfo();
        }, leftTime);
    }

    function InitWithClientInfoAndUserInfo(
        clientInfo?: HttpParams,
        userInfo?: any,
    ) {
        if (!clientInfo || !userInfo) return;
        try {
            dispatch(setLoadingUserInfo(true));
            const { accessToken, refreshToken, expiresIn } = clientInfo;
            dispatch(
                setClient(
                    new HttpClient(
                        process.env.REACT_APP_POLIS_APP_ID as string,
                        accessToken,
                        refreshToken,
                        expiresIn,
                        'https://me.nuvosphere.io',
                    ),
                ),
            );
            if (userInfo.display_name) {
                dispatch(setName(userInfo.display_name));
            }
            if (userInfo.eth_address) {
                dispatch(setAddress(userInfo.eth_address));
            }
            if (userInfo.email) {
                dispatch(setEmail(userInfo.email));
            }
            dispatch(setConnected(true));
            dispatch(setLoadingUserInfo(false));
            clearAuthInfoTimeout(clientInfo);
        } catch (e) {
            console.error(e);
            console.error('InitWithClientInfoAndUserInfo failed');
            dispatch(setLoadingUserInfo(false));
        }
    }

    async function Init(httpParams?: HttpParams) {
        dispatch(setLoadingUserInfo(true));
        try {
            if (httpParams) {
                const { accessToken, refreshToken, expiresIn } = httpParams;
                const polisClient = await getPolisClient(chainId, true);
                const userInfo = await polisClient.getUserInfoAsync();
                if (userInfo && userInfo.eth_address) {
                    dispatch(
                        setClient(
                            new HttpClient(
                                process.env.REACT_APP_POLIS_APP_ID as string,
                                accessToken,
                                refreshToken,
                                expiresIn,
                                'https://me.nuvosphere.io',
                            ),
                        ),
                    );

                    if (userInfo) {
                        storage.set(USER_INFO, userInfo);

                        if (userInfo.display_name) {
                            dispatch(setName(userInfo.display_name));
                        }
                        if (userInfo.eth_address) {
                            dispatch(setAddress(userInfo.eth_address));
                        }
                        if (userInfo.email) {
                            dispatch(setEmail(userInfo.email));
                        }

                        dispatch(setConnected(true));
                        clearAuthInfoTimeout(httpParams);
                    }
                    dispatch(setLoadingUserInfo(false));
                } else {
                    console.error('auth fail, please login again');
                    storage.remove(USER_INFO);
                    storage.remove(AUTH_PARAMS);
                }
            }
        } catch (e) {
            console.error(e);
            console.error('Init auth error');
        }
        dispatch(setLoadingUserInfo(false));
    }

    const fetchAccessInfo = async () => {
        const { REACT_APP_POLIS_PROXY, REACT_APP_POLIS_APP_ID } = process.env;
        let res = undefined;
        try {
            const auth = getUrlVars()['code'];
            if (!auth) {
                console.log('error code');
                return res;
            }

            const { data } = await axios.get(
                `${REACT_APP_POLIS_PROXY}?code=${auth}&appid=${REACT_APP_POLIS_APP_ID}`,
            );

            if (data && parseInt(data.code) === REQUEST_STATUS_SUCCESS) {
                res = data.data;
            } else {
                const authParams = storage.get(AUTH_PARAMS);
                if (!authParams) {
                    AlertFunc({
                        type: 'error',
                        text:
                            data && data.msg
                                ? data.msg
                                : 'Something wrong, please try again later',
                    });
                }
            }
            let search = '';
            if (window.location.search) {
                let params = new URLSearchParams(window.location.search);
                if (params.get('code')) {
                    params.delete('code');
                }
                const searchParamsString = params.toString();
                search = searchParamsString ? `?${searchParamsString}` : '';
            }

            window.history.pushState(
                {},
                document.title,
                `${window.location.pathname}${search}`,
            );
        } catch (error) {
            console.error(error);
        }
        return res;
    };

    useEffect(() => {
        const auth = getUrlVars()['code'];
        let accessToken;
        let refreshToken;
        let expiresIn;

        if (!auth || authParams) {
            return;
        }

        const fetchData = async () => {
            dispatch(setLoadingUserInfo(true));

            try {
                const res = await fetchAccessInfo();
                if (res) {
                    accessToken = res.access_token;
                    refreshToken = res.refresh_token;
                    expiresIn = res.expires_in;
                    const expireAt = new Date().getTime() + expiresIn * 1000;

                    storage.set(AUTH_PARAMS, {
                        accessToken,
                        refreshToken,
                        expiresIn,
                        expireAt,
                    });

                    await Init({
                        accessToken,
                        refreshToken,
                        expiresIn,
                        expireAt,
                    });
                    window.history.replaceState(
                        {},
                        document.title,
                        window.location.pathname,
                    );
                }
            } catch (error) {
                console.error(error);
            }
            dispatch(setLoadingUserInfo(false));
        };

        fetchData();
    }, []);

    useEffect(() => {
        setInterval(() => {
            const tempNow = new Date().getTime();
            const auth = storage.get(AUTH_PARAMS);
            if (auth && tempNow > auth.expireAt) {
                storage.remove(USER_INFO);
                storage.remove(AUTH_DAC);
                storage.remove(AUTH_PARAMS);
                clearLoginInfo();
            }
        }, 20000);
        if (authParams) {
            const now = new Date().getTime();
            if (now > authParams.expireAt) {
                storage.remove(USER_INFO);
                storage.remove(AUTH_DAC);
                storage.remove(AUTH_PARAMS);
                clearLoginInfo();
            } else {
                // not expired
                if (userInfo && authParams) {
                    InitWithClientInfoAndUserInfo(authParams, userInfo);
                } else {
                    // reload user info
                    Init(authParams);
                }
            }
        }
    }, []);
    return null;
}
