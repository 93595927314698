import React, { useMemo } from 'react';
import Modal from '@/components/Modal';
import Row from '@/components/Row';
import Text from '@/components/Text';
import Button from '@/components/Button';
import Copy from '@/components/Copy';
// import { login } from '@/utils/auth';
import style from './index.module.scss';
import RouterConfig from '@/constants/router';
import copy from 'copy-to-clipboard';
import Alert from '@/utils/components/alert';
import { DEPOSIT_MIN, DEPOSIT_MAX } from '@/constants';
export interface Props {
    isOpen: boolean;
    onClose(): void;
    dacId: number;
    dacName: string;
    dacPhoto: string;
    invitationCode: string;
    inviteUrlCode: string;
}

export default function InviteMoreModal({
    isOpen,
    onClose,
    dacId,
    dacName,
    dacPhoto,
    invitationCode,
    inviteUrlCode,
}: Props) {
    const shareLink = useMemo(() => {
        return `${window.location.origin}${RouterConfig.getInvite(
            inviteUrlCode,
        )}`;
    }, [dacId, inviteUrlCode]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <div className={style.wrapper}>
                    <Row align="center" customClass={style.modalTitle}>
                        <Text
                            align="center"
                            fontSize={16}
                            color="#cd3270"
                            lineHeight={'19px'}
                        >
                            {/* Invite more friends to participate in DAC mining to
                            increase your mining power */}
                            Invite more friends to participate in DAC
                        </Text>
                    </Row>
                    <Row align="center" customClass={style.dacInfo}>
                        <div className={style.dacPhoto}>
                            <img src={dacPhoto} alt="icon remind" />
                        </div>
                        <Text
                            fontSize={20}
                            fontWeight={500}
                            lineHeight={'24px'}
                            color="#fff"
                        >
                            {dacName}
                        </Text>
                    </Row>
                    <Row align="between" customClass={style.inviterCodeCopy}>
                        <Copy label={'Inviter code：'} code={invitationCode} />
                    </Row>
                    <Row align="between" customClass={style.modalPromptText}>
                        <Text
                            fontSize={12}
                            fontWeight={400}
                            lineHeight={'17px'}
                            color={'#FFB200'}
                        >
                            {/* Note: for every one friend who stakes {DEPOSIT_MIN}-
                            {DEPOSIT_MAX} tokens, you will receive{' '}
                            <Text color={'#CF306A'}> 5 ～ 10 </Text> mining
                            power increase. */}
                            Please notify that the DAC staking program is closed
                            at Sat, 22 Jan 2022 07:00 (UTC). However, you are
                            still welcomed to invite your friends, more
                            functions and ecosystem projects are coming soon!
                        </Text>
                    </Row>
                    <Row align="center" customClass={style.modalActionBar}>
                        <Button
                            onClick={() => {
                                copy(shareLink);
                                Alert({
                                    type: 'success',
                                    text: 'Copied',
                                });
                            }}
                            size="middle"
                            className={style.connectBtn}
                        >
                            <img
                                src="/static/images/ic_link.png"
                                alt="icon link"
                            />
                            Copy Link
                        </Button>
                    </Row>
                </div>
            </Modal>
        </>
    );
}
