import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';
import style from './index.module.scss';

interface Props {
    type?: Color;
    text: string | React.ReactNode;
    open: boolean;
    onClose(): void;
    duration?: number;
    anchorOrigin?: {
        vertical: 'top' | 'bottom';
        horizontal: 'center' | 'left' | 'right';
    };
}

export default function CustomAlert({
    type = 'success',
    text,
    onClose,
    duration = 300000,
    open,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'center',
    },
}: Props) {
    return (
        <Snackbar
            open={open}
            autoHideDuration={duration}
            key="global-alert"
            onClose={onClose}
            anchorOrigin={anchorOrigin}
            className={style.wrapper}
        >
            <Alert
                elevation={6}
                variant="filled"
                onClose={onClose}
                severity={type}
            >
                {text}
            </Alert>
        </Snackbar>
    );
}
