import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import React from 'react';
import style from './index.module.scss';

interface Props {
    size?: number;
}

export default function Loading({ size = 24 }: Props) {
    return <CircularProgress size={size} className={style.loading} />;
}
