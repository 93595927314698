import React, { ReactChild, ReactDOM, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Button from '@/components/Button';
import DepositModal from '@/components/DepositModal';
import { useAppSelector } from '@/state/hooks';
import { ROLE } from '@/constants';
import RouterConfig from '@/constants/router';
import ModalFunc from '@/utils/components/modal';
import {
    setIsOpenConnectModalHooks,
    updateUserDacHooks,
} from '@/state/user/hooks';
import useDacClient from '@/hooks/use-dac-hooks';
export interface Props {
    dacId: number;
    dacName: string;
    dacInvitationCode?: string;
    dacCreator: string;
    onSuccess?(): void;
    children?: string | ReactDOM | ReactChild;
    buttonClassName?: string;
    buttonType?: 'primary' | 'transparent';
    disabled?: boolean;
    dacLink?: string;
}

export default function JoinDacButton({
    dacId,
    dacName,
    dacCreator,
    dacInvitationCode,
    onSuccess,
    children,
    buttonClassName,
    buttonType = 'primary',
    disabled,
    dacLink,
}: Props) {
    const user = useAppSelector((state) => state.user);
    const history = useHistory();
    const [isOpenDepositModal, setIsOpenDepositModal] = useState(false);
    const setIsOpenConnectModal = setIsOpenConnectModalHooks();
    const { updateUserDac } = updateUserDacHooks();
    const { userToDAC } = useDacClient();

    async function handleJoin() {
        let userDacId = user && user.dac ? user.dac.id : undefined;

        if (!userDacId) {
            userDacId = await userToDAC();
        }
        if (userDacId) {
            // Alert({
            //     type: 'error',
            //     text:
            //         userDacId === dacId
            //             ? 'You have joined this DAC'
            //             : 'You have joined another DAC',
            // });

            ModalFunc({
                iconType: 'warning',
                text:
                    'Each account can only join or create one DAC at this stage. Please leave your current DAC if you wish to join this DAC. More functions will be available in following product releases to unleash the full power of DACs to the public.',
            });
            return;
        }

        if (!user.connected) {
            setIsOpenConnectModal(true);
            return;
        }

        setIsOpenDepositModal(true);
    }

    const dacData = useMemo(() => {
        return {
            name: dacName,
            id: dacId,
            creator: dacCreator,
        };
    }, [dacName, dacId, dacCreator]);

    return (
        <>
            <Button
                onClick={handleJoin}
                hideLoading={false}
                className={buttonClassName}
                type={buttonType}
                disabled={disabled}
            >
                {children}
            </Button>
            <DepositModal
                isOpen={isOpenDepositModal}
                onClose={() => {
                    setIsOpenDepositModal(false);
                }}
                type="join"
                dacData={dacData}
                userType={ROLE.VIEWER}
                dacLink={dacLink}
                defaultInvitationCode={dacInvitationCode}
                onSuccess={async () => {
                    await updateUserDac();

                    if (onSuccess) {
                        onSuccess();
                    }
                }}
            />
        </>
    );
}
