import { SESSION_KEY } from '.';

const RouterConfig = {
    dacHome: `/d/:id`,
    getDacHome: (id: string | number, link: string = '') => {
        if (link) {
            return `/dac/${link}`;
        } else {
            return `/d/${id}`;
        }
    },
    dacPool: `/d/:id/pool`,
    getDacPool: (id: string | number, link: string = '') => {
        if (link) {
            return `/dac/${link}/pool`;
        } else {
            return `/d/${id}/pool`;
        }
    },
    vault: `/vault`,
    getVault: () => {
        window.sessionStorage.setItem(SESSION_KEY.vaultPrevPage, '1');
        return `/vault`;
    },
    dacMiningRules: `/d/:id/mining-rules`,
    getMiningRules: (id: string | number, link: string = '') => {
        if (link) {
            return `/dac/${link}/mining-rules`;
        } else {
            return `/d/${id}/mining-rules`;
        }
    },
    dacRanking: `/d/:id/ranking`,
    getDacRanking: (id: string | number, link: string = '') => {
        if (link) {
            return `/dac/${link}/ranking`;
        } else {
            return `/d/${id}/ranking`;
        }
    },
    getDacFinance: (id: string | number) => `/d/${id}/finance`,
    getDacProfile: (id: string | number) => `/d/${id}/profile`,
    create: '/create',
    search: '/search',
    ranking: '/ranking',
    invite: '/invite/:invitationCode',
    getInvite: (invitationCode: string) => `/invite/${invitationCode}`,
    dacManagement: '/d/:id/management',
    getDacManagement: (id: string | number, link: string = '') => {
        if (link) {
            return `/dac/${link}/management`;
        } else {
            return `/d/${id}/management`;
        }
    },
    dacShortLinkHome: `/dac/:link`,
    dacShortLinkPool: `/dac/:link/pool`,
    dacShortLinkRanking: `/dac/:link/ranking`,
    dacShortLinkManagement: `/dac/:link/management`,
    dacShortLinkMiningRules: `/dac/:link/mining-rules`,
};

export default RouterConfig;
