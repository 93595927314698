import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_URI,
    }),
});

export const DacClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
        uri: process.env.REACT_APP_DAC_GRAPHQL_URL,
    }),
});

export const Temp = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
        uri: 'http://47.241.12.101:8081/api/dac/graphql',
    }),
});

export default client;
