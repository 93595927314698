import React, { useState, useEffect } from 'react';
import axios from 'axios';

import style from './index.module.scss';

import { REQUEST_STATUS_SUCCESS } from '@/constants/request';

interface IProps {
    onChange: (value: any) => void;
    className?: string;
    title?: string;
    accept?: string;
    file?: string;
    accessToken: string | undefined;
}

interface IResponse {
    data: {
        code: number;
        data: {
            url: string;
        };
    };
}

const { REACT_APP_UPLOAD_URL } = process.env;

export default function UploadScreenshots(props: IProps) {
    const {
        onChange,
        className,
        title = 'Icon plus',
        accept = 'image/png, image/jpeg',
        file,
        accessToken,
    } = props;

    const [image, setImage] = useState<string>();

    const postFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
        try {
            const file = Array.from(event.target.files || [])[0];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('filename', file.name);

            if (!accessToken) {
                console.log('Failed to obtain accessToken, please log in.');
                return;
            }

            axios
                .post(REACT_APP_UPLOAD_URL as string, formData, {
                    headers: {
                        'access-token': accessToken,
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response: IResponse) => {
                    const {
                        data: { code, data: { url } = {} } = {},
                    } = response;
                    if (code === REQUEST_STATUS_SUCCESS) {
                        const imageUrl = url || '';
                        setImage(imageUrl);
                        onChange(imageUrl);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const classes = [style.uploadScreenshots, className];

    useEffect(() => {
        if (file) {
            setImage(file);
        }
    }, [file]);

    return (
        <>
            <label htmlFor="uploadScreenshots" className={classes.join(' ')}>
                <input
                    style={{ display: 'none' }}
                    id="uploadScreenshots"
                    name="upload-screenshots"
                    type="file"
                    accept={accept}
                    onChange={postFile}
                />
                <input
                    type="url"
                    key="image"
                    readOnly
                    defaultValue={image}
                    className={style.filename}
                />
                <img
                    className={style.uploadMenu}
                    src="/static/images/ic_upload.png"
                    alt={title}
                />
            </label>
        </>
    );
}
