import { ROLE } from '@/constants';
import useDacContract from '@/hooks/use-dac-hooks';
import {
    setDac,
    setDacRole,
    setDacLink,
    setDacLinkLoading,
    setShareLinkCode,
    reset,
    setDacLoading,
} from '@/state/dac/index';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Modal from '@/utils/components/modal';
import getDacPlusInfo from '@/graphql/dac-plusinfo';

export function updateDacLinkByDacId() {
    const dispatch = useAppDispatch();
    async function getIdFromShortLink(
        id: number | string,
        hideLoading?: boolean,
    ) {
        if (!hideLoading) {
            dispatch(setDacLinkLoading(true));
        }
        const res = await getDacPlusInfo({
            id: id,
        });

        if (res && res.id) {
            dispatch(setDacLink(res.linkPath));
            dispatch(setShareLinkCode(res.invitationCode));
        } else {
            dispatch(setDacLink(''));
            dispatch(setShareLinkCode(''));
        }

        if (!hideLoading) dispatch(setDacLinkLoading(false));
    }

    return getIdFromShortLink;
}

export function getUpdateDacByIdHooks() {
    const dispatch = useAppDispatch();
    const { getDacInfoById } = useDacContract();
    const getIdFromShortLink = updateDacLinkByDacId();
    async function updateDacById(id: number, hideLoading?: boolean) {
        if (id) {
            if (!hideLoading) {
                dispatch(setDacLoading(true));
            }
            const res = await getDacInfoById(id);
            dispatch(setDac(res));
            await getIdFromShortLink(id, hideLoading);
            if (!hideLoading) {
                dispatch(setDacLoading(false));
            }
        }
    }

    return {
        updateDacById,
    };
}

export function clearDac() {
    const dispatch = useAppDispatch();

    function clear() {
        dispatch(reset());
    }

    return clear;
}

export default function Updater() {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { dac, dacLink } = useAppSelector((state) => state.dac);
    const { dac: userDac, address: userAddress } = useAppSelector(
        (state) => state.user,
    );
    const { updateDacById } = getUpdateDacByIdHooks();
    const { clientReady, getPoolLength } = useDacContract();
    const [shortLinkToId, setShortLinkToId] = useState<number | undefined>();
    const { id, link } = useParams<{
        id: string | undefined;
        link: string | undefined;
    }>();
    const location = useLocation();
    const [showNotExist, setShowNotExist] = useState(false);

    const isDacIdRoute = useMemo(() => {
        return location.pathname.startsWith('/d/');
    }, [location]);

    const isDacShortLinkRoute = useMemo(() => {
        return location.pathname.startsWith('/dac/');
    }, [location]);

    const convertId = useMemo(() => {
        if (isDacShortLinkRoute) return shortLinkToId;
        return Number(id);
    }, [id, isDacIdRoute, isDacShortLinkRoute, shortLinkToId]);

    async function isParamsIdInPoolLength() {
        const length = await getPoolLength();
        if (
            length &&
            convertId &&
            Number(convertId) > length - 1 &&
            !showNotExist
        ) {
            setShowNotExist(true);
            Modal({
                iconType: 'unhappy',
                text: 'The DAC does not exist',
                onClose() {
                    history.push('/');
                },
            });
        }
    }

    const stateDacId = useMemo(() => {
        if (dac) {
            return dac.id;
        }

        return undefined;
    }, [dac]);

    const stateDacCreatorAddress = useMemo(() => {
        if (dac) {
            return dac.creator;
        }

        return undefined;
    }, [dac]);

    async function getIdFromShortLink(paramdId?: string, paramsLink?: string) {
        if (paramsLink || paramdId) {
            dispatch(setDacLinkLoading(true));
            const res = await getDacPlusInfo({
                link: paramsLink,
                id: paramdId,
            });

            if (res && res.id) {
                setShortLinkToId(res.id);
                dispatch(setDacLink(res.linkPath));
                dispatch(setShareLinkCode(res.invitationCode));
            } else {
                dispatch(setDacLink(''));
                dispatch(setShareLinkCode(''));
            }
            dispatch(setDacLinkLoading(false));
        }
    }

    // shortLink id compatible logic
    useEffect(() => {
        if (isDacIdRoute || isDacShortLinkRoute) {
            getIdFromShortLink(id, link);
        }
    }, [id, link, isDacShortLinkRoute, isDacIdRoute]);

    useEffect(() => {
        if (convertId && (isDacIdRoute || isDacShortLinkRoute)) {
            isParamsIdInPoolLength();
        }
    }, [
        clientReady,
        convertId,
        isDacIdRoute,
        isDacShortLinkRoute,
        showNotExist,
    ]);

    useEffect(() => {
        let interval: any;
        if (convertId && (isDacIdRoute || isDacShortLinkRoute)) {
            const paramId = Number(convertId);
            if (stateDacId != paramId) {
                if (isDacIdRoute || (isDacShortLinkRoute && dacLink !== link)) {
                    updateDacById(paramId);
                }
            } else {
                interval = setInterval(() => {
                    updateDacById(stateDacId, true);
                }, 60000);
            }
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [
        clientReady,
        stateDacId,
        convertId,
        isDacShortLinkRoute,
        isDacIdRoute,
        dacLink,
        link,
    ]);

    const userDacId = userDac ? userDac.id : undefined;

    useEffect(() => {
        // 设置路由dac 中user角色
        if (
            userAddress &&
            stateDacCreatorAddress &&
            userDacId &&
            stateDacId &&
            dispatch
        ) {
            if (Number(userDacId) === Number(stateDacId)) {
                let role = ROLE.MEMBER;
                if (
                    stateDacCreatorAddress.toLowerCase() ===
                    userAddress.toLowerCase()
                ) {
                    role = ROLE.CREATOR;
                }

                dispatch(setDacRole(role));
            } else {
                dispatch(setDacRole(ROLE.VIEWER));
            }
        }
    }, [stateDacId, userDacId, stateDacCreatorAddress, userAddress, dispatch]);

    // exit dac detail page

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);
    return null;
}

export function updateDacByUserDac() {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

    function update() {
        if (user.dac) {
            dispatch(setDac(user.dac));
            dispatch(setDacLink(user.dacLink));
            dispatch(setShareLinkCode(user.shareLinkCode));
            dispatch(setDacRole(user.dacUserRole));
        }
    }

    return update;
}
