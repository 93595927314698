import React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { saveAs } from 'file-saver';

import styled from 'styled-components';

const FooterContainer = styled.footer`
    padding-top: 20px;
    padding-bottom: 32px;
`;

const FooterMenu = styled.div`
    text-align: center;
`;

const FooterMenuItem = styled.a`
    margin: 0 24px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    line-height: 22px;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: rgba(255, 255, 255, 1);
    }
`;

const FooterMenuButton = styled.button`
    margin: 0 24px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    line-height: 22px;
    background: transparent;
    border: 0;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        color: rgba(255, 255, 255, 1);
    }
`;

interface DownloadItems {
    label: string;
    link: string;
    download: string;
}

const MENUS = [
    {
        label: 'Terms of Service',
        link: '/terms-of-service.html',
    },
    {
        label: 'Privacy Policy',
        link: '/privacy-policy.html',
    },
    {
        label: 'User Guide',
        link: 'https://docs.metis.io/portal/',
    },
];

const DOWNLOAD_MENUS: DownloadItems[] = [
    {
        label: 'Hacken Audit Report',
        link: '/static/files/hacken-audit-report.pdf',
        download: 'hacken-audit-report.pdf',
    },
    {
        label: 'Armors Audit Report',
        link: '/static/files/armors-audit-report.pdf',
        download: 'armors-audit-report.pdf',
    },
];

export default function Footer() {
    const history = useHistory();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownload = (item: DownloadItems) => {
        saveAs(item.link, item.download);
        handleClose();
    };

    return (
        <FooterContainer>
            <FooterMenu>
                {MENUS.map((item) => {
                    return (
                        <FooterMenuItem
                            key={item.label}
                            target="_blank"
                            href={item.link}
                        >
                            {item.label}
                        </FooterMenuItem>
                    );
                })}
                <FooterMenuButton onClick={handleClick}>
                    Download Audit Report
                </FooterMenuButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {DOWNLOAD_MENUS.map((item) => {
                        return (
                            <MenuItem
                                key={item.label}
                                onClick={() => {
                                    handleDownload(item);
                                }}
                            >
                                {item.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </FooterMenu>
        </FooterContainer>
    );
}
