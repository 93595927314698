import { gql } from '@apollo/client';
import client from './index';
import { Dac, DAC_STATUS, ROLE } from '@/constants';

interface ReturnData {
    id: number;
    creator: {
        id: string;
    };
    membersCount: number;
    name: string;
    introduction: string;
    category: string;
    url: string;
    photo: string;
    invitationCode: string;
    status: number;
    createTimestamp: number;
}

export default function getDacById(id: number): Promise<Dac | undefined> {
    return new Promise((resolve) => {
        client
            .query<{
                dac: ReturnData;
            }>({
                variables: {
                    id,
                },
                fetchPolicy: 'no-cache',
                query: gql`
                    query dac($id: ID!) {
                        dac(id: $id) {
                            id
                            creator {
                                id
                            }
                            membersCount
                            name
                            introduction
                            category
                            photo
                            invitationCode
                            status
                            createTimestamp
                        }
                    }
                `,
            })
            .then((res) => {
                const { data } = res;
                if (data && data.dac) {
                    const { dac } = data;
                    resolve({
                        id: Number(dac.id),
                        creator: dac.creator.id,
                        name: dac.name,
                        introduction: dac.introduction,
                        category: dac.category,
                        // url: dac.url,
                        photo: dac.photo,
                        createTime: dac.createTimestamp,
                        status:
                            dac.status === 1
                                ? DAC_STATUS.active
                                : DAC_STATUS.dismiss,
                        membersCount: Number(dac.membersCount),
                        invitationCode: dac.invitationCode,
                    });
                }
                resolve(undefined);
            })
            .catch((e: any) => {
                console.error(e);
                resolve(undefined);
            });
    });
}
